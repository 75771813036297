import {takeEvery, put, call} from 'redux-saga/effects';
import {ACCOUNT_TAB} from "../../../types/account/accountTab/accountTab";
import {makeQuery} from "../../../apis";
import {setAccountTabData,setAccountTabLoading,setAccountTabError} from "../../../slices/account/accountTab/accountTab";
import {setChartQuery} from "../../../slices/chart/chart";


export function* sendAccountTabSaga({dataAccTab}) {
    try {
        yield put(setAccountTabLoading(true));
        const result = yield call(makeQuery, dataAccTab);
        yield put(setAccountTabData(result));
        yield put(setChartQuery(dataAccTab));
        yield put(setAccountTabLoading(false));
        yield put(setAccountTabError({}));
    } catch (e) {
        yield put(setAccountTabError(e.response.data.error));
        yield put(setAccountTabLoading(false));
    }
}


export function* watchAccountTab() {
    yield takeEvery(ACCOUNT_TAB, sendAccountTabSaga);
}