import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: null,
    totalItems: 0,
    selectedRow: null
};
const didoxDocumentsByEmpId = createSlice({
    name: 'didoxDocumentsByEmpId',
    initialState,
    reducers: {
        setDidoxDocumentsByEmpIdTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setDidoxDocumentsByEmpIdTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setDidoxDocumentsByEmpIdTabError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setDidoxDocumentsByEmpIdTabData, setDidoxDocumentsByEmpIdTabLoading, setDidoxDocumentsByEmpIdTabError} = didoxDocumentsByEmpId.actions;
export default didoxDocumentsByEmpId.reducer;
