import React, { useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { generateColor } from '../index';

const CustomAreaChart = ({ comparing_by, comparing_columns, data }) => {
    const convertToNumber = (value) => {
        if (typeof value === 'string') {
            const numberValue = parseFloat(value.replace(/,/g, ''));
            return isNaN(numberValue) ? value : numberValue;
        }
        return value;
    };

    const convertedData = useMemo(() => {
        return data.map((item) => {
            const convertedItem = { ...item };
            comparing_columns.forEach((column) => {
                if (convertedItem.hasOwnProperty(column)) {
                    convertedItem[column] = convertToNumber(convertedItem[column]);
                }
            });
            return convertedItem;
        });
    }, [data, comparing_columns]);

    const newFormat = useMemo(() => {
        return convertedData.map((item) => {
            let main = '';
            for (let itemKey in item) {
                if (comparing_by.includes(itemKey)) {
                    if (main === '') {
                        main += itemKey + ': ' + item[itemKey];
                    } else {
                        main += ' , ' + itemKey + ': ' + item[itemKey];
                    }
                }
            }
            return { main, ...item };
        });
    }, [convertedData, comparing_by]);

    const areaChartWidth = newFormat.length * 100; // Adjust as needed

    return (
        <div className="chart-line-container">
            <AreaChart
                width={areaChartWidth}
                height={window.innerHeight / 100 * 85}
                data={newFormat}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 100,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={'main'} interval={0} tick={{ angle: -45, textAnchor: 'end' }} height={100} />
                <YAxis />
                <Tooltip />
                {comparing_columns.map((value) => {
                    let color = generateColor();
                    return (
                        <Area
                            type="monotone"
                            key={value}
                            dataKey={`${value}`}
                            stackId="1"
                            stroke={color}
                            fill={color}
                        />
                    )
                })}
            </AreaChart>
        </div>
    );
};

export default CustomAreaChart;
