import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {setStatsDescData, setStatsDescError, setStatsDescLoading} from "../../../slices/references/statsDesc/statsDesc";
import {STATS_DESC} from "../../../types/references/statsDesc/statsDesc";


export function* sendStatsDescSaga({mainStatsDescTabData}) {
    try {
        yield put(setStatsDescLoading(true));
        const result = yield call(makeQuery, mainStatsDescTabData);
        yield put(setStatsDescData(result));
        yield put(setStatsDescLoading(false));
        yield put(setStatsDescError({}));
    } catch (e) {
        yield put(setStatsDescError(e.response.data.error));
        yield put(setStatsDescLoading(false));
    }
}


export function* watchStatsDescType() {
    yield takeEvery(STATS_DESC, sendStatsDescSaga);
}