import {Badge, Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Radio, Select, Space} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from "react";
import { useFilter } from '../context/FilterContext';
import getFilials from "../api/refs/filials";
import getDocState from "../api/refs/states";
import getDirection from "../api/refs/direction";
import getPaydocType from "../api/refs/paydocType";
import dayjs from "dayjs";
import PriceRangeInput from "./PriceRangeInput";
import DigitRangeInput from "./DigitRangeInput";
import getExistsAcc from "../api/refs/existsAcc";
import getExistSmeta from "../api/refs/existSmeta";
import getExpenses from "../api/refs/expense";
import getStructure from "../api/refs/structure";
import getStaff from "../api/refs/staff";
import getDidoxOwnerType from "../api/refs/didoxOwnerType";
import getDidoxDocTypeRef from "../api/refs/didox_doc_type_ref";
import getDidoxStatusRef from "../api/refs/didoxStatusRef";
import getRoles from "../api/refs/roles";

const {Option} = Select
const { RangePicker } = DatePicker;

const FilterModal = (props) => {


    let filterData = isEmpty(props.filterTab)?[]:props.filterTab;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [showDot, setShowDot] = useState(false);
    const [refFilial, setRefFilial] = useState([]);
    const [refRoles, setRefRoles] = useState([]);
    const [docTypeDidox, setDocTypeDidox] = useState([]);
    const [diddoxStatusRef, setDiddoxStatusRef] = useState([]);
    const [refExpense, setRefExpense] = useState([]);
    const [refDirection, setRefDirection] = useState([]);
    const [refPaydocType, setRefPaydocType] = useState([]);
    const [refExistsAcc, setRefExistsAcc] = useState([]);
    const [didoxOwnerType, setDidoxOwnerType] = useState([]);
    const [refExistSmeta, setRefExistSmeta] = useState([]);
    const [refStructure, setRefStructure] = useState([]);
    const [refStaff, setRefStaff] = useState([]);
    const [refPaydocSource, setRefPaydocSource] = useState([]);
    const [refDocState, setRefDocState] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});
    const [modalForm] = Form.useForm()
    const [buttonType, setButtonType] = useState('default');
    const { filterValues, updateFilterValues } = useFilter();

    const hasSMTypes = filterData.some((e) => e.type === "S" || e.type === "M");

    const fetchDataIfNeeded = () => {
        if (hasSMTypes) {
            async function fetchData() {
                try {
                    const filteredArray = filterData.filter(function(item) {
                        // Check if the prop type is S or M
                        if (item.type === "S" || item.type === "M" || item.type === "W") {
                            return item;
                        }
                    });

                    for (const item of filteredArray) {
                        if ( item.typeRef === "FILIAL" ) {
                            const dataFilial = await getFilials();
                            setRefFilial(dataFilial)
                        }
                        if (item.typeRef === "ROLE") {
                            const dataRole = await getRoles();
                            setRefRoles(dataRole)
                        }
                        if ( item.typeRef === "DOC_TYPE_DIDOX" ) {
                            const dataDocTypeDidox = await getDidoxDocTypeRef();
                            setDocTypeDidox(dataDocTypeDidox);
                        }
                        if ( item.typeRef === "DIDOX_STATUS_REF" ) {
                            const dataDidoxStatusRef = await getDidoxStatusRef();
                            setDiddoxStatusRef(dataDidoxStatusRef);
                        }
                        if ( item.typeRef === "EXPENSE" ) {
                            const dataExpense = await getExpenses();
                            setRefExpense(dataExpense)
                        }
                        if ( item.typeRef === "DIRECTION" ) {
                            const dataDirection = await getDirection();
                            setRefDirection(dataDirection)
                        }
                        if ( item.typeRef === "PAYDOCTYPE" ) {
                            const dataPayType = await getPaydocType();
                            setRefPaydocType(dataPayType)
                        }
                        if ( item.typeRef === "EXISTS_ACC" ) {
                            const dataExistsAcc = await getExistsAcc();
                            setRefExistsAcc(dataExistsAcc)
                        }
                        if ( item.typeRef === "DIDOX_OWNER_TYPE" ) {
                            const dataDidoxOwnerType = getDidoxOwnerType();
                            setDidoxOwnerType(dataDidoxOwnerType);
                        }
                        if ( item.typeRef === "EXIST_SMETA" ) {
                            const dataExistSmeta = await getExistSmeta();
                            setRefExistSmeta(dataExistSmeta)
                        }
                        if ( item.typeRef === "STRUCTURE" ) {
                            const dataStructure = await getStructure();
                            setRefStructure(dataStructure)
                        }
                        if ( item.typeRef === "STAFF" ) {
                            const dataStaff = await getStaff();
                            setRefStaff(dataStaff)
                        }
                        if ( item.typeRef === "PAYDOCSOURCE" ) {
                            const dataPaydocSource = [
                                {'code': 'bank', 'name': 'Банк'},
                                {'code': 'manual', 'name': 'Қўлда'},
                            ]
                            setRefPaydocSource(dataPaydocSource)
                        }
                        if ( item.typeRef === "DOCSTATE" ) {
                            const doctype = item.paramRef
                            const dataDocState = await getDocState(doctype);
                            setRefDocState(dataDocState)
                        }

                    }
                    /*const dataFilial = await getFilials();
                    const dataDocState = await getDocState('PAYDOC');
                    console.log(dataFilial,'dataFilial')
                    console.log(dataDocState,'dataDocState')

                    setRefDocState(dataDocState)*/
                } catch (error) {

                }
            }

            fetchData()
        }
    };


    // Function to initialize form values with context values
    const initializeFormValues = () => {
        const initialValues = filterValues[props.filterKey];
        console.log(initialValues, 'initialValues');
        if ( initialValues && initialValues.length !== 0 ) {
            const formValues = {};

            initialValues.forEach((filter) => {
                const { column, dataType, value, value2, operator } = filter;

                // Convert the value based on the data type
                let convertedValue = value;
                if (dataType === 'number' && operator !== "in") {
                    convertedValue = parseFloat(value); // or use parseInt if it's an integer
                }

                if (dataType === 'date' && ['range', 'range_new'].includes(operator)) {

                    convertedValue = [
                        dayjs(value  , 'DD.MM.YYYY'),
                        dayjs(value2 , 'DD.MM.YYYY')
                    ]
                }

                if (dataType === 'number' && ['range'].includes(operator)) {

                    convertedValue = [
                        value,
                        value2
                    ]
                }

                // Set the field value in the form
                formValues[column] = convertedValue;

            });
            console.log(formValues,'formValues')
            modalForm.setFieldsValue(formValues);
            setShowDot(true)
        }
    };

    useEffect(() => {

        if (props.filterKey) {
            const initialValues = filterValues[props.filterKey];
            console.log(initialValues,'filter initialValues')
            console.log(filterValues,'filter filterValues')
            if (initialValues && initialValues.length !== 0) {
                setShowDot(true)
            }
        if (isModalOpen) {
            initializeFormValues();
        }
        }

        fetchDataIfNeeded()


    }, [isModalOpen]);


    const handleInputNumberChange = (name, value) => {
        modalForm.setFieldsValue({
            [name]: value || [ 0, 0],
        });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const onReset = async () => {
        await setSelectedValues({});
        modalForm.resetFields();

        // Remove the filter values from the context for the specific filterKey
        /*const newFilterValues = { ...filterValues };
        delete newFilterValues[props.filterKey];
        setFilterValues(newFilterValues);*/

        updateFilterValues({ [props.filterKey]: [] });

    };

    const handleOk = () => {

        let mfilter = []

        setConfirmLoading(true);

        let filteredFilter = []
        const existColumn = []
        let existOne = false
        filterData.forEach((e) => {
            const { column, datatype, operator, type } = e;
            const inputValue = modalForm.getFieldValue(column);

            if (
                (inputValue && typeof inputValue === "string" && inputValue.trim().length > 0) ||
                (inputValue && typeof inputValue === "number" && !isNaN(inputValue)) ||
                (inputValue && Array.isArray(inputValue) && inputValue.length > 0)
            ) {
                existOne = true
                existColumn.push(column)

                console.log(existColumn,'existColumn.push(column)')
                console.log(filterValues[props.filterKey],'filterValues[props.filterKey]')
            }

            if (inputValue !== undefined && inputValue !== "" && type === "W" ) {
                const { table, tableId, tableIdColumn} = e

                //let formatValue = ""
                let formatValue = inputValue

                /*if (datatype === "text") {
                    formatValue = inputValue;
                } else {
                    formatValue = Number(inputValue);
                }*/

                if (formatValue.length !== 0)
                    mfilter.push({
                        column,
                        operator: operator,
                        value: formatValue,
                        dataType: datatype,
                        query: {
                            id: table,
                            source: table,
                            fields: [{
                                column: tableIdColumn
                            }],
                            filters: [
                                {
                                    column,
                                    operator: "in",
                                    value: formatValue,
                                    dataType: datatype
                                },
                                {
                                    column: tableIdColumn,
                                    operator: "=",
                                    value: tableId,
                                    dataType: "fixed"
                                }
                            ]
                        }
                    });
                console.log(mfilter, 'mfilter')
            }

            if (inputValue !== undefined && inputValue !== "" && type === undefined ) {
                /*                console.log(column, type ,'sadas')
                                console.log(inputValue, 'inputValue')*/
                const itemAttr = modalForm.getFieldInstance(column).input.dataset;

                let inpValue = "";

                if (itemAttr.type === "text" || itemAttr.type === "date") {
                    inpValue = inputValue;
                } else {
                    inpValue = Number(inputValue);
                }

                console.log(modalForm.getFieldValue(e.column+'ne'),'asdasdasqeqw')
                console.log(modalForm.getFieldValue(e.column+'ne') === true ? true : undefined,'lololo')
                mfilter.push({
                    column,
                    operator: itemAttr.operator || operator,
                    value: inpValue,
                    dataType: itemAttr.type || datatype,
                    ne: modalForm.getFieldValue(e.column+'ne') === true ? true : undefined,
                });
            }

            if (inputValue !== undefined && inputValue !== "" && ( type === "S" || type === "M" || type === "R") ) {
                let formatValue = inputValue

                if (type === "R" && datatype === "date") {
                    mfilter.push({
                        column,
                        operator: operator,
                        value: dayjs(inputValue[0]).format('DD.MM.YYYY'),
                        value2: dayjs(inputValue[1]).format('DD.MM.YYYY'),
                        dataType: datatype,
                    });
                } else if (type === "R" && datatype === "number") {
                    //formatValue = inputValue.map(elem => dayjs(elem).format('DD.MM.YYYY') )
                    if (inputValue[0] !== null || inputValue[1] !== null)
                        mfilter.push({
                            column,
                            operator: operator,
                            value: inputValue[0],
                            value2: inputValue[1],
                            dataType: datatype,

                        });
                } else {

                    //if ( typeof inputValue === "number" && datatype === "text" ) formatValue = inputValue.toString()

                    if (formatValue.length !== 0) {
                        mfilter.push({
                            column,
                            operator: operator,
                            value: formatValue,
                            dataType: datatype,
                });
            }

                }
            }

            if (inputValue !== undefined && inputValue !== "" && type === "E") {
                const {table, tableId, tableIdColumn} = e
                let arr = []
                let formatValue = "";
                const itemAttr = modalForm.getFieldInstance(column).input.dataset;

                if (itemAttr.type === "text" || itemAttr.type === "date") {
                    formatValue = inputValue;
                } else {
                    formatValue = Number(inputValue);
                }

                arr.push(Number(formatValue))

                if (arr.length !== 0)
                    mfilter.push({
                        column,
                        operator: operator,
                        value: arr,
                        dataType: datatype,
                        query: {
                            id: table,
                            source: table,
                            fields: [{
                                column: tableIdColumn
                            }],
                            filters: [
                                {
                                    column,
                                    operator: "in",
                                    value: arr,
                                    dataType: datatype
                                },
                                {
                                    column: tableIdColumn,
                                    operator: "=",
                                    value: tableId,
                                    dataType: "fixed"
                                }
                            ]
                        }
                    });
            }

            if (inputValue !== undefined && inputValue !== "" && type === "E"  ) {
                let formatValue = inputValue

                    if (formatValue.length !== 0) {
                        mfilter.push({
                            column,
                            operator: operator,
                            value: formatValue,
                            dataType: datatype,
                        });
                    }

                }

        });

        if (!existOne) {

            clearFilters()
        } else {

            if (filterValues[props.filterKey]){
                filteredFilter = filterValues[props.filterKey].filter(item => existColumn.includes(item.column));
            }

        if ( mfilter.length !== 0 ){
            props.onSubmit(mfilter).then(r => {
                setIsModalOpen(false);
                setConfirmLoading(false);

                    //if (props.filterKey) setFilterValues({ ...filteredFilter, [props.filterKey]: mfilter });
                    if (props.filterKey) {
                        updateFilterValues({ [props.filterKey]: [...filteredFilter, ...mfilter] });
                    }
            });
                setShowDot(true)
        } else {
            props.onSubmit(mfilter).then(r => {
                setIsModalOpen(false);
                setConfirmLoading(false);
            });
                setShowDot(false)
            }

        }

        // setTimeout(() => {
        //
        // }, 2000);

    };

    const clearFilters = () => {
        modalForm.resetFields();

        // Remove the filter values from the context for the specific filterKey
        /*const newFilterValues = { ...filterValues };
        delete newFilterValues[props.filterKey];
        setFilterValues(newFilterValues);*/
        setShowDot(false)
        updateFilterValues({ [props.filterKey]: [] });

    }

    useEffect(() => {
        refreshTabData();
    }, [filterValues]);


    const refreshTabData = () => {
        props.onSubmit([]).then(r => {

            if (filterValues && filterValues[props.filterKey] && filterValues[props.filterKey].length !== 0) setShowDot(true)
            else setShowDot(false)
            setIsModalOpen(false);
            setConfirmLoading(false);
        });
        setShowDot(false)
    }

    const selectChangeHandler = (value, columnName) => {
        setSelectedValues({
            ...selectedValues,
            [columnName]: value,
        });

        // Update the hidden Input value
        modalForm.setFieldsValue({
            [columnName]: value,
        });
    };

    const NotEqual = ({ column, buttonType, setButtonType }) => {
        return (
            <Button
                type={buttonType}
                onClick={() => {
                    modalForm.setFieldsValue({
                        [column]: !modalForm.getFieldValue(column+'ne'),
                    });
                    // Update button type based on the condition
                    setButtonType(modalForm.getFieldValue(column+'ne') ? 'primary' : 'default');
                }}
            >
                x
            </Button>
        );
    };

    return (
        <>
            <Badge dot={showDot}>
            <Button type="primary" onClick={showModal} shape="circle">
                <SearchOutlined />
            </Button>
            </Badge>

            <Modal
                title="Фильтр"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                footer={[
                    <Button key="1" danger onClick={onReset}>Тозалаш</Button>,
                    <Button key="2" onClick={handleCancel}>Бекор қилиш</Button>,
                    <Button key="3" type="primary"
                            loading={confirmLoading} onClick={() => handleOk()}>
                        Қўллаш
                    </Button>
                ]}
            >

                <Form name="filterForm" form={modalForm} scrollToFirstError
                      labelCol={{
                          span: 6,
                      }}
                      wrapperCol={{
                          span: 18,
                      }}
                      style={{
                          maxWidth: 450,
                      }}
                >

                    {
                        filterData && filterData.map((e)=>{

                            if (e.type === "E") {
                        return (
                            <Form.Item key={e.column} name={e.column} label={e.label}
                                               width={100}
                                       onChange={(event) => {
                                           modalForm.setFieldsValue({
                                               [e.column]: event.target.value,
                                           });
                                       }}
                            >

                                        <Input data-operator={e.operator} data-type={e.datatype} allowClear />

                                    </Form.Item>
                                )
                            }
                            if (e.type === "S" || e.type === "M" || e.type === "W") {

                                const optionsExp = [];
                                if (e.type === "W") {

                                    refExpense.forEach(el => {
                                        optionsExp.push({
                                            label: el.code,
                                            value: el.code,
                                        });
                                    })

                                }

                                return (

                                    <Form.Item key={e.column}
                                               name={e.column}
                                               label={e.label}
                                               initialValue={selectedValues[e.column]}


                                    >
                                        <Select data-operator={e.operator}
                                                data-type={e.datatype}
                                                mode={e.type === "M" || e.type === "W"? 'multiple' : 'default'}
                                                value={modalForm.getFieldValue(e.column)}
                                                placeholder={'Каторни танланг'}
                                                key={e.column}
                                                onChange={(value) => selectChangeHandler(value, e.column)}
                                                //options={optionsExp}
                                        >
                                            {e.typeRef === "FILIAL" &&
                                                refFilial &&
                                                refFilial.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.code + ' - ' + option.name}
                                                    </Option>
                                            ))
                                            }
                                            {e.typeRef === "ROLE" &&
                                            refRoles &&
                                            refRoles.map((option) => (
                                                <Option key={option.id} value={option.id}>
                                                    {option.id + ' - ' + option.name}
                                                </Option>
                                            ))
                                            }
                                            {e.typeRef === "DOC_TYPE_DIDOX" &&
                                            docTypeDidox &&
                                            docTypeDidox.map((option) => (
                                                    <Option key={option.id} value={option.code}>
                                                        {option.id + ' - ' + option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "DIDOX_STATUS_REF" &&
                                            diddoxStatusRef &&
                                            diddoxStatusRef.map((option) => (
                                                <Option key={option.id} value={option.id}>
                                                        {option.id + ' - ' + option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "EXPENSE" &&
                                                refExpense &&
                                                refExpense.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.code + ' - ' + option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "DIRECTION" &&
                                                refDirection &&
                                                refDirection.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "PAYDOCTYPE" &&
                                                refPaydocType &&
                                                refPaydocType.map((option) => (
                                                    <Option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "EXISTS_ACC" &&
                                                refExistsAcc &&
                                                refExistsAcc.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "DIDOX_OWNER_TYPE" &&
                                            didoxOwnerType &&
                                            didoxOwnerType.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "EXIST_SMETA" &&
                                                refExistSmeta &&
                                                refExistSmeta.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "STRUCTURE" &&
                                                refStructure &&
                                                refStructure.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "STAFF" &&
                                                refStaff &&
                                                refStaff.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "PAYDOCSOURCE" &&
                                                refPaydocSource &&
                                                refPaydocSource.map((option) => (
                                                    <Option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            {e.typeRef === "DOCSTATE" &&
                                                refDocState &&
                                                refDocState.map((option) => (
                                                    <Option key={option.state} value={option.state}>
                                                        {option.state + ' - ' + option.name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                )
                            }
                            else if (e.type === "R" && e.datatype === "date") {
                                return (
                                    <Form.Item key={e.column}
                                               name={e.column}
                                               label={e.label}
                                               width={100}
                                    >
                                        <RangePicker size="medium" data-operator={e.operator} data-type={e.datatype}
                                                     format="DD.MM.YYYY"
                                        />
                                    </Form.Item>
                                )
                            }
                            else if (e.type === "R" && e.datatype === "number") {
                                return (
                                    <Form.Item key={e.column}
                                               name={e.column}
                                               label={e.label}
                                               width={100}
                                    >
                                        <DigitRangeInput
                                            value={[0, 0]}
                                            onChange={(value) => handleInputNumberChange(e.column, value)}
                                        />
                                    </Form.Item>
                                )
                            }
                            else
                                return (
                                    <>
                                        <Form.Item key={e.column}
                                                   name={e.column}
                                                   label={e.label}
                                                   width={100}
                                                   /*onChange={(event) => {
                                                       modalForm.setFieldsValue({
                                                           [e.column]: event.target.value,
                                                       });
                                                   }}*/
                                                   onChange={(event) => {
                                                       let inputValue = event.target.value;


                                                       if (e.datatype === 'number') {

                                                           inputValue = inputValue.replace(/[^0-9.]/g, '');

                                                           if (!isNaN(inputValue)) {
                                                               modalForm.setFieldsValue({
                                                                   [e.column]: inputValue,
                                                               });
                                                           }
                                                       } else {

                                                           modalForm.setFieldsValue({
                                                               [e.column]: inputValue,
                                                           });
                                                       }
                                                   }}
                                        >
                                            {/*                                            <Input data-operator={e.operator} data-type={e.datatype} allowClear />*/}

                                            <Input
                                                data-operator={e.operator}
                                                data-type={e.datatype}
                                                allowClear
                                                /*addonAfter={
                                                    <NotEqual
                                                        column={e.column}
                                                        buttonType={buttonType}
                                                        setButtonType={setButtonType}
                                                    />
                                                }*/
                                            />
                                            {/*<Button
                                                    type="default"
                                                    onClick={() => {
                                                        modalForm.setFieldsValue({
                                                            [e.column]: !modalForm.getFieldValue(e.column),
                                                        });
                                                    }}
                                                >
                                                    !=
                                                </Button>*/}
                            </Form.Item>

                                        {/*<Form.Item key={e.column+'ne'}
                                                   name={e.column+'ne'}
                                                   width={100}
                                                   hidden={true}
                                        >
                                            <Input/>
                                        </Form.Item>*/}
                                    </>

                        )
                        })
                    }

                </Form>
                {/*<Button htmlType="button" onClick={onReset}>
                    Reset
                </Button>*/}
            </Modal>
        </>
    )

}

export default FilterModal