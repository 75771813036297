import {Modal, Table} from 'antd';
import React, {useRef, useState} from "react";
import useStatsDescTab from "./useStatsDescTab";
import Draggable from "react-draggable";
import {useTheme} from "../../context/ThemeContext";

const StatsDescTab = ({isModalOpen, setIsModalOpen}) => {

    const { isDarkMode, toggleDarkMode } = useTheme();


    const [disabled, setDisabled] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });
    const draggleRef = useRef(null);
    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    const {mainStatsDescTabColumns,tableHeader,data,rowSelection,loading,pagination,onRow,handleOk,handleCancel} = useStatsDescTab(setIsModalOpen);
    return (
        <Modal
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                    onFocus={() => {}}
                    onBlur={() => {}}
                >
                    Худудлар бўйича қолдиқлар
                </div>
            }
            modalRender={(modal) => (
            <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
            >
                <div ref={draggleRef}>{modal}</div>
            </Draggable>
        )}
            width={"350"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Table columns={mainStatsDescTabColumns}
                           dataSource={data}
                           className={isDarkMode ? "table-striped-rows darkTheme" : "table-striped-rows"}
                           rowKey="filial"
                           rowSelection={rowSelection}
                           loading={loading}
                           title={tableHeader}
                           size='small'
                           scroll={{x: 300, y: 'calc(100vh - 350px)'}}
                           tableLayout="auto"
                           pagination={pagination}
                           onRow={onRow}
                    />
        </Modal>
    );
};

export default StatsDescTab;