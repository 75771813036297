import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0
};
const control = createSlice({
    name: 'control',
    initialState,
    reducers: {
        setControlData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setControlLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setControlError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setControlData,setControlLoading,setControlError} = control.actions;
export default control.reducer;
