import React, {useContext, useEffect, useState} from "react";
import {Col, Menu, Row, Radio, Avatar, Tag, Drawer, Timeline, Button, Typography, Switch, FloatButton} from "antd";
import {
    ClockCircleOutlined,
    HomeOutlined,
    LogoutOutlined,
    ReloadOutlined,
    UserOutlined,
    BarChartOutlined,
    MoonFilled,
    SunFilled
} from "@ant-design/icons";

import {Link, useLocation} from "react-router-dom";
import {Header} from "antd/es/layout/layout";
import {AuthContext} from "../context/AuthContext";
import { useTranslation } from 'react-i18next';

import './comp.css'
import {useAuth} from "../hooks/auth.hook";
import UserProfile from "./User/UserProfile";
import dayjs from "dayjs";
import ChartDrawer from "./charts/ui/ChartDrawer";
import {useTheme} from "../context/ThemeContext";

const { Title, Text } = Typography;

const NavigationBar = ({titleNav}) => {

//    dayjs.locale('ru');
    /* Chart*/
    const [open, setOpen] = useState(false);
    /* Chart*/
    const location = useLocation();

    const {isDarkMode, toggleDarkMode,setIsDarkMode} = useTheme()
    const auth = useContext(AuthContext);
    const isAuthenticated = !!auth.token;

    const {empId, empName} = useAuth();
    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState('Uz');

    const [openProfile, setOpenProfile] = useState(false)
    const [currentTime, setCurrentTime] = useState(dayjs());

    const showProfile = () => { setOpenProfile(true) }
    const closeProfile = () => { setOpenProfile(false) }

    const handleLogOut = () => {
        auth.logout()
    }

    const handleLangClick = (event) => {

        return false
        //event.preventDefault();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(dayjs());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleEmpClick = (event) => {
        showProfile()
        return false
        //console.log(event)
        //console.log(event.domEvent)
        //event.domEvent.preventDefault();
    }

    if (!isAuthenticated || location.pathname === '/') {
        return null;
    }

    const handleExtraButtonClick = () => {
        window.onbeforeunload = null;

        window.location.reload(true);
    }

    const handleTheme = () => {

        toggleDarkMode()
        //setIsDarkMode(prevState => !prevState);
    }

    const items = [
        {
            key: 'dateTime',
            icon: <ClockCircleOutlined />,
            label: currentTime.format('DD MMM YYYY HH:mm:ss'),
        },
        /*{
            key: 'lang',
            label: (
                <Radio.Group
                    buttonStyle='solid'
                    defaultValue="uz"
                    size='small'
                    onChange={(e) => i18n.changeLanguage(e.target.value)}
                >
                    <Radio.Button value="uz" defaultChecked={true}>Узб</Radio.Button>
                    <Radio.Button value="ru">Рус</Radio.Button>
                </Radio.Group>
            ),
        },*/

        {
            key: 'employee',
            label: (
                <Tag
                    color="#707070"
                    style={{
                        padding: '4px 10px',
                        color: 'inherit',
                        fontSize: '14px',
                        backgroundColor: "transparent",
                        borderColor: "#0985bc"
                    }}
                >
                    <UserOutlined style={{ marginRight: '6px' }} /> {empId} - {empName}
                </Tag>
            ),
            onClick: showProfile,
        },
        {
            key: 'lang',
            title: t('lang'),
            label: (
                <span onClick={(e) => {
                    let lang
                    e.target.innerText === 'Uz' ? lang = 'Ru' : lang = 'Uz';
                    setSelectedLang(lang);
                    i18n.changeLanguage(lang.toLowerCase())
                }
                }
                >
                    {selectedLang}
                </span>
                /*<Button size={'small'}
                        type={'text'}
                        style={{color: '#fff'}}
                        onClick={(e) => {
                            console.log(e.target.innerText);
                            let lang
                            e.target.innerText === 'uz' ? lang = 'ru' : lang = 'uz';
                            setSelectedLang(lang);
                            i18n.changeLanguage(lang)
                            }
                        }
                >
                    {selectedLang}
                </Button>*/
            ),

        },
        {
            key: 'reload',
            /*icon: <ReloadOutlined title={t('refresh')}/>,*/
            title: t('refresh'),
            label: (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleExtraButtonClick();
                    }}
                >
                    <ReloadOutlined title={t('refresh')} size={'large'} />
                    {/*{t('refresh')}*/}
                </a>
            ),
        },
        {
            key: 'charts',
            //icon: <BarChartOutlined />,
            title: t('Диаграмма'),
            label: (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setOpen(true);
                    }}
                >
                    <BarChartOutlined  />
                    {/*{t('Диаграмма')}*/}
                </a>
            ),
        },
        {
            key: 'layout',
            //icon: <HomeOutlined />,
            title: t('homeWindow'),
            //label: <Link to="/layout">{t('homeWindow')}</Link>,
            label: <Link to="/layout"><HomeOutlined /></Link>,
        },
        {
            key: 'login',
            /*icon: <LogoutOutlined />,*/
            title: t('exit'),
            //label: <Link to="/" onClick={handleLogOut}>{t('exit')}</Link>,
            label: <Link to="/" onClick={handleLogOut}><LogoutOutlined /></Link>,
            style: {display: 'block !important'}
        },
    ];


    return (
        <Header className="header" theme="light" style={{backgroundColor: "#262626"}}>
            <Row>
                <Col span={4}>
                    <h2 style={{color: '#f2f9fa', fontWeight: 600}} className="logonav"><span className="logoApp"/> <span>KMBAT</span></h2>
                    {/*<h2 style={{color: '#f2f9fa', fontWeight: 600}} className="logonav"><span className="logoAppBi"/> <span>QUERY BI</span></h2>*/}

                </Col>

                <Col span={6}>
                    {/*<h3 style={{color: 'rgb(206, 194, 158)', paddingLeft:'10px'}}>{titleNav}</h3>*/}
                    <Title
                        level={5}
                        style={{ width: 400, color: '#35bbf8', paddingTop:'20px'}}
                        ellipsis={{tooltip: titleNav}}
                    >
                        {titleNav}
                    </Title>
                </Col>
                <Col span={14} style={{float: 'right'}}>
                    <FloatButton
                        onClick={handleTheme}
                        style={{
                            left: 20,
                            bottom: 60
                        }}
                        shape="square"
                        type="default"
                        icon={isDarkMode ? <SunFilled /> : <MoonFilled />}
                    />

                        <Menu theme="dark" items={items} mode="horizontal" defaultSelectedKeys={['welcome']} selectable={false} style={{justifyContent: 'flex-end', backgroundColor:"#262626" }}/>




                        {/*<Menu.Item key="dateTime" icon={<ClockCircleOutlined />}>
                            {currentTime.format('DD MMM YYYY HH:mm:ss')}
                        </Menu.Item>
                        <Menu.Item key="lang" onClick={handleLangClick}>
                            <Radio.Group buttonStyle='solid' defaultValue="uz"
                                         size='small' onChange={(e) => i18n.changeLanguage(e.target.value)}>
                                <Radio.Button value="uz" defaultChecked={true}>Узб</Radio.Button>
                                <Radio.Button value="ru">Рус</Radio.Button>
                            </Radio.Group>
                        </Menu.Item>

                        <Menu.Item key="employee" onClick={handleEmpClick}>

                            <Tag color="#707070" style={{padding: '4px 10px', color: 'inherit', fontSize: '14px', backgroundColor:"transparent", borderColor:"#0985bc"}} >
                                <UserOutlined style={{marginRight: '6px'}}/> {empId} - {empName}
                            </Tag>

                        </Menu.Item>

                        <Menu.Item key="reload" icon={<ReloadOutlined />}>
                            <a href="#"
                               onClick={(e) => {
                                    e.preventDefault(); // Prevent the default behavior of the anchor tag
                                    handleExtraButtonClick();
                               }}
                            >
                                {t('refresh')}
                            </a>
                        </Menu.Item>

                        <Menu.Item key="charts" icon={<BarChartOutlined/>}>
                            <a href="#"
                               onClick={(e) => {
                                   e.preventDefault(); // Prevent the default behavior of the anchor tag
                                   setOpen(true)
                               }}
                            >
                                {t('Диаграмма')}
                            </a>
                        </Menu.Item>

                        <Menu.Item key="layout" icon={<HomeOutlined />}>
                            <Link to="/layout">{t('homeWindow')}</Link>
                        </Menu.Item>

                        <Menu.Item key="login" icon={<LogoutOutlined />}>
                            <Link to="/" onClick={handleLogOut}>{t('exit')}</Link>
                        </Menu.Item>*/}

                    {/*</Menu>*/}

                    <ChartDrawer open={open} setOpen={setOpen}/>
                    <Drawer
                        title="Фойдаланувчи"
                        width={500}
                        placement='right'
                        onClose={closeProfile}
                        open={openProfile}
                        /*styles={{
                            paddingBottom: 110,
                        }}*/
                    >
                        <UserProfile empId={empId}/>

                    </Drawer>

                </Col>

            </Row>
        </Header>
    )
}

export default NavigationBar;