import React, {useCallback, useEffect, useState} from 'react';
import {DraggableModal, DraggableModalProvider} from '@cubetiq/antd-modal'
import '@cubetiq/antd-modal/dist/index.css'

const ModalWithButton = ({modal}) => {
    const [visible, setVisible] = useState(true);
    /*const [width, setWidth] = useState(200)
    const [height, setHeight] = useState(200)*/
    const onOk = useCallback(() => setVisible(true), []);
    const onCancel = useCallback(() => setVisible(false), []);

   /* useEffect(()=>{
        console.log(modal, 'bb')
        //setWidth(modal.initialWidth)
        //setHeight(modal.initialHeight)
        setWidth(1000)
        setHeight(700)
    },[]);*/
    return (
        <>
            <DraggableModal open={visible} title={modal.title || ''} onOk={onOk} onCancel={onCancel} initialWidth={1100}
                            initialHeight={700}>
                {modal.content}
            </DraggableModal>
        </>
    );
};

export default ModalWithButton;