import React, {useEffect} from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

import "./bubble.css";
highchartsMore(Highcharts);
const CustomBubbleChart = ({comparing_by,comparing_columns,data}) => {
    const convertToNumber = (value) => {
        if (typeof value === 'string') {
            const numberValue = parseFloat(value.replace(/,/g, ''));
            return isNaN(numberValue) ? value : numberValue;
        }
        return value;
    };
    const convertedData = data.map((item) => {
        const convertedItem = { ...item };
        comparing_columns.forEach((column) => {
            if (convertedItem.hasOwnProperty(column)) {
                convertedItem[column] = convertToNumber(convertedItem[column]);
            }
        });
        return convertedItem;
    });
    let newFormat = convertedData.map((item) => {
        let main = '';
        for (let itemKey in item) {
            if (comparing_by.includes(itemKey)) {
                if (main === '') {
                    main +=  itemKey + ": " + item[itemKey];
                } else {
                    main += " , " + itemKey + ": " + item[itemKey];
                }
            }
        }
        return {main, ...item}
    });
    useEffect(()=> {

        const myData = newFormat.map((item) => ({
            name: item["main"],
            data: comparing_columns.map((column)=> ({name: item["main"] + " - " + column, value: item[column]}))
        }));
        Highcharts.chart('container', {
            chart: {
                type: 'packedbubble',
                height: '100%'
            },
            title: {
                text: '',
                align: 'center'
            },
            tooltip: {
                useHTML: true,
                pointFormat: '{point.value} so<sub>`</sub>m'
            },
            plotOptions: {
                packedbubble: {
                    minSize: '30%',
                    maxSize: '120%',
                    zMin: 0,
                    zMax: 1000,
                    layoutAlgorithm: {
                        splitSeries: false,
                        gravitationalConstant: 0.02
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        filter: {
                            property: 'y',
                            operator: '>',
                            value: 250
                        },
                        style: {
                            color: 'black',
                            textOutline: 'none',
                            fontWeight: 'normal'
                        }
                    }
                }
            },
            series: myData
        });
    }, [newFormat]);
    return (
        <div className="chart-container" style={{ width: '100%', overflow: 'auto' }}>
            <div id="container" style={{ minWidth: '600px' }} />
        </div>
    );
};

export default CustomBubbleChart;