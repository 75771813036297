import {Alert, Button, Drawer, Form, Input, Layout, message, Modal, notification, Space, Table} from 'antd';
import React, {useState, useEffect, useContext, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "../../api/axios";
import {AuthContext} from "../../context/AuthContext";
import {columns, dataFilter, dataTab} from "./RoleData";
import FilterModal from "../../components/FilterModalBM";
import AccessDocAction from "./AccessDocAction";
import {FileExcelOutlined, TableOutlined} from "@ant-design/icons";
import {useFilter} from "../../context/FilterContext";
import ExpExceljs from "../../components/ExpExceljs";
import {useAuth} from "../../hooks/auth.hook";
/*import {AppLoad} from "../../libs/eimzo/e-imzo-init";
import EIMZO from "../../libs/eimzo/Eimzo";*/

const QUERY_URL = '/api/public/query';
const DIDOX_URL = '/didox/document/';


const RoleTab = (props) => {

    let navigate = useNavigate();

    const auth = useContext(AuthContext);
    const {empInn, empName} = useAuth();

    const { filterValues } = useFilter();
    const filterKey = 'roleTabFilter'; // Set the filterKey for this route
    const filterData = filterValues[filterKey] || '';

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [stateTab, setStateTab] = useState([]);
    const [filterTab, setFilterTab] = useState("");
    const [loading, setLoading] = useState(true);
    const [openSpec, setOpenSpec] = useState(false)
    const [roleData, setRoleData] = useState({})

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [notifyApi, contextApiHolder] = notification.useNotification();

    //const EIMZOClient = new EIMZO();
    const showSpec = () => {
        setOpenSpec(true)
    }
    const closeSpec = () => {
        setOpenSpec(false)
    }

    useEffect(() => {
        props.setTitleNav('Роллар билан ишлаш');
    }, []);

    const headers = {'Content-Type':'application/json;charset=utf-8',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Credentials':'true',
        'Authorization':`Bearer ${auth.token}`,
        'withCredentials': true
    }

    const getTabData = async (filter = '') => {

        //console.log(filter,' FILTERmmm')

        let filters = [];
        if (filter!=='' && filter!== '{}') filters = filter
        //console.log(dataTab.toString(),'dataTab')

        if (filterData && filters.length === 0) {
            filters = [...filters, ...filterData];
        }

        dataTab.query['filters'] = filters;


        const { data } = await axios.post(QUERY_URL,
            ( dataTab ),
            {
                headers: headers,
                //crossDomain: true,
                withCredentials: false
            });
        setLoading(false);

        setStateTab(data);

        setFilterTab( dataFilter );

    };

    useEffect(() => {
        getTabData();
        //AppLoad() //EIMZO
    }, []);


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleAdd = () => {
        navigate("../roleAdd");
    };

    const handleAccessMenu = () => {
        if (selectedRowKeys.length === 0) { message.warning('Қаторни танланг'); return false; }
        let data = stateTab.find(row => row.id === selectedRowKeys[0])
        navigate("../accessMenu",{state: { roleId:selectedRowKeys[0], roleName: data?.name}});
    };

    const handleAccessDocAction = () => {
        if (selectedRowKeys.length === 0) { message.warning('Қаторни танланг'); return false; }
        let data = stateTab.find(row => row.id === selectedRowKeys[0])

        //navigate("../accessDocAction",{state: { roleId:selectedRowKeys[0], roleName: data?.name}});
        setRoleData({roleId: selectedRowKeys[0], roleName: data?.name})
        showSpec()
    };

    const handleApproveMenu = () => {
        if (selectedRowKeys.length === 0) { message.warning('Қаторни танланг'); return false; }
        message.success('Муввафақият');
    }

    const onSelect = (record, rowIndex) => {
        setSelectedRowKeys([record.id]);
        setSelectedRow(record);
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () =>{ onSelect(record, rowIndex) }
        }
    };

    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        getCheckboxProps: (record) => ({
            style: {
                visibility: 'hidden',
                position: 'absolute',
            },
        }),
        onChange: (record, selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
        },

    };

    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
    };

    const pagination = {
        total: stateTab.length,
        showTotal: (total, range) => (
            <span>
                <strong>{total}</strong> катордан {range[0]}-{range[1]} гача
            </span>
        ),
        defaultPageSize: '20',
        onShowSizeChange:onShowSizeChange,
        size: 'small',
        defaultCurrent: 1,
        position: ['topRight'],
        hideOnSinglePage:false,
    };


   /* const attachTimestamp = async function (pkcs7_64, signatureHex){
        try {
            const { data } = await axios.post('https://api.didox.uz/v1/dsvs/timestamp',

                {
                    "pkcs7": pkcs7_64, // Значение поля pkcs7_64
                    "signatureHex": signatureHex // Значение поля signature_hex
                }, {
                    crossDomain: true,
                    withCredentials: false
                }
            )

            if (data.success === true)  return data.timeStampTokenB64 //S TIMESTAMP
            else {
                alert('Есть ощибки с E-imzo')
                return false
            }

        } catch (e) {
            alert('Есть ощибки с E-imzo')
            console.log(e)
        }
    }
    const handleActionECP = async (iAction) => {
        try {
            setIsSubmitting(true)
            const doc_id = '11EF0AAC15689DBA95A21E0008000075'
            const queryParams = `empInn=${empInn}&docid=${doc_id}`

            const certs = await EIMZOClient.install();
            console.log(certs, 'certssss')
            const dataAllUserKey = await EIMZOClient.listAllUserKeys();
            const activeCert = dataAllUserKey.filter(el => el.TIN === empInn)

            const {data: dataDoc, status} = await axios.get(`/didox/document/${empInn}/${doc_id}`,
                {
                    headers: headers,
                    withCredentials: false
                });
            const objText = JSON.stringify(dataDoc?.data?.json)

            let resultSign, pkcs7wtst;
            try {
                resultSign = await EIMZOClient.signPkcs7(activeCert[0],objText)
                    .then(el => console.log(el,'el'))
                    .catch(er => {console.log(er,'er'); return false})
                pkcs7wtst = await attachTimestamp(resultSign.pkcs7_64, resultSign.signature_hex)
            } catch (e) {
                console.log(e, 'Err on signPkcs')
            }

            messageApi.open({
                type: 'loading',
                content: 'Запрос в DIDOX..',
                duration: 0,
                style: {
                    marginTop: '10vh',
                },
            });
            return false;

            if (iAction === 'sign') {

                const responseDidox = await axios.post(`/didox/document/${doc_id}/sign`,
                    {signature: pkcs7wtst},
                    {headers: headers, withCredentials: false}
                )
                    .then((tt) => {
                        notifyApi.success({
                            message: 'Операция успещна (SIGN)',
                            description: 'Операция успещна',
                            duration: 4,
                        });
                    })
                    .catch((e) => {
                        const {data, status} = e.response
                        const msg = JSON.parse(data?.exceptionBody).error?.message
                        notifyApi.error({
                            message: 'Возникла ощибка (SIGN)',
                            description: msg,
                            duration: 5,
                        });
                    })
            }
            if (iAction === 'reject') {

                const responseDidox = await axios.post(`/didox/document/${doc_id}/delete`,
                    {signature: pkcs7wtst},
                    {headers: headers, withCredentials: false}
                )
                    .then((tt) => {
                        notifyApi.success({
                            message: 'Операция успещна (REJECT)',
                            description: 'Операция успещна',
                            duration: 4,
                        });
                    })
                    .catch((e) => {
                        const {data, status} = e.response
                        const msg = JSON.parse(data?.exceptionBody).error?.message
                        notifyApi.error({
                            message: 'Возникла ощибка (REJECT)',
                            description: msg,
                            duration: 5,
                        });
                    })
            }


        } catch (e) {
            console.log(e, 'Error on ActionECP')
        } finally {
            setIsSubmitting(false)
            messageApi.destroy()
        }
    }

    const signCert = async (selectedCert) => {
        try {
            let resultid = 1212
            let tokenbb= ''
            const result4 = await EIMZOClient.createPkcs7(resultid, selectedCert.TIN, false)
                .then(pkcs7 => {
                    console.log('Result 3:', pkcs7);
                    tokenbb = pkcs7
                })
                .catch(error => {
                    console.error("Failed to create PKCS7555:", error);
                });
            console.log(result4,'result 4')

        } catch (error) {
            console.error(error);
            // Handle the error here
        }
    }*/

    const tableHeader = () => {
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10}}>
                <Button
                    onClick={handleAdd}
                    type="primary"
                >
                    Янги роль
                </Button>
                <Button
                    onClick={handleAccessMenu}
                    type="primary"
                >
                    Модулларга ваколат бериш
                </Button>
                <Button
                    //onClick={handleAccessDocAction}
                    onClick={handleAccessDocAction}
                    icon={<TableOutlined/>}
                    type="primary"
                >
                    Амалларга ваколат бериш
                </Button>

                <Drawer
                    title="Амалларга ваколат бериш"
                    width={700}
                    placement='right'
                    onClose={closeSpec}
                    open={openSpec}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                >
                    <AccessDocAction roleData={roleData}/>

                </Drawer>

                <Button
                    onClick={handleApproveMenu}
                    type="primary"
                >
                    Тасдиклаш
                </Button>

                {/*<Button type="primary" shape="circle" onClick={() => exportAllToExcelNew(dataTab, auth, columns)}>
                    <FileExcelOutlined/>
                </Button>
                */}

                <Button type="primary" shape="circle" onClick={() => ExpExceljs(dataTab, auth, columns)}>
                    <FileExcelOutlined/>
                </Button>

                <FilterModal filterKey={filterKey} filterTab={filterTab} onSubmit={getTabData}/>

                {/*<Button type="primary" onClick={() => handleActionECP('sign')} disabled={isSubmitting}>
                    ECP SIGN
                </Button>
                <Button type="primary" onClick={() => handleActionECP('reject')} disabled={isSubmitting}>
                    ECP REJECT
                </Button>*/}

            </div>
        );
    }

    return (

        <Layout style={{height:"100%", overflow:"hidden"}}>
            {contextHolder}
            {contextApiHolder}
            <div>

                <Table rowSelection={rowSelection}
                       columns={columns}
                       dataSource={stateTab}
                       rowKey="id"
                       loading={loading}
                       size='small'
                       title={tableHeader}
                       //scroll={{x: 300, y:'calc(100vh - 345px)'}}
                       scroll={{x: 300, y:'calc(100vh - 300px)'}}
                       tableLayout="auto"
                       pagination={pagination}
                       onRow={onRow}
                       /*style={{width: '100%', height: '100vh'}}*/
                />

            </div>
        </Layout>

    );

}

export default RoleTab