import {takeEvery, put, call} from 'redux-saga/effects';
import {makeGet, makeQuery} from "../../../apis";
import {setChartQuery} from "../../../slices/chart/chart";
import {
    DOCUMENT_CLEAR_CREATE,
    DOCUMENT_CONTROLS_CREATE, DOCUMENT_CONTROLS_DELETE,
    DOCUMENT_CONTROLS_TAB
} from "../../../types/document/documentControlsTab/documentControlsTab";
import {
    setDocumentControlsTabLoading,
    setDocumentControlsTabData,
    setDocumentControlsTabError,
    setDocumentControlsCreate,
    setClearCreate, setDocumentControlsDelete
} from "../../../slices/document/documentControlsTab/documentControlsTab";


export function* sendDocumentControlsTabSaga({mainDocumentControlsTabData}) {
    try {
        yield put(setDocumentControlsTabLoading(true));
        const result = yield call(makeQuery, mainDocumentControlsTabData);
        yield put(setDocumentControlsTabData(result));
        yield put(setChartQuery(mainDocumentControlsTabData));
        yield put(setDocumentControlsTabLoading(false));
        yield put(setDocumentControlsTabError(null));
    } catch (e) {
        yield put(setDocumentControlsTabError(e));
        yield put(setDocumentControlsTabLoading(false));
    }
}
export function* sendDocumentControlsCreateSaga({createData}) {
    try {
        yield put(setDocumentControlsTabLoading(true));
        const result = yield call(makeQuery, createData, '/document_control_off/save');
        yield put(setDocumentControlsCreate(result));
        yield put(setDocumentControlsTabLoading(false));
        yield put(setDocumentControlsTabError(null));
    } catch (e) {
        yield put(setDocumentControlsTabError(e));
        yield put(setDocumentControlsTabLoading(false));
    }
}
export function* sendDocumentControlsDeleteSaga({id}) {
    try {
        yield put(setDocumentControlsTabLoading(true));
        const result = yield call(makeGet, {docId: id}, '/document_control_off/delete');
        yield put(setDocumentControlsCreate(result));
        yield put(setDocumentControlsDelete(id));
        yield put(setDocumentControlsTabLoading(false));
        yield put(setDocumentControlsTabError(null));
    } catch (e) {
        yield put(setDocumentControlsTabError(e));
        yield put(setDocumentControlsTabLoading(false));
    }
}
export function* sendClearSaga() {
    yield put(setClearCreate());
}


export function* watchDocumentControlsTab() {
    yield takeEvery(DOCUMENT_CONTROLS_TAB, sendDocumentControlsTabSaga);
    yield takeEvery(DOCUMENT_CONTROLS_CREATE, sendDocumentControlsCreateSaga);
    yield takeEvery(DOCUMENT_CLEAR_CREATE, sendClearSaga);
    yield takeEvery(DOCUMENT_CONTROLS_DELETE, sendDocumentControlsDeleteSaga);
}
