import {takeEvery, put, call} from 'redux-saga/effects';
import {setSavedQueriesData, setSavedQueriesError, setSavedQueriesLoading} from "../../slices/chart/saved_queries";
import {makeQuery} from "../../apis";
import {SAVED_QUERIES} from "../../types/chart/saved_queries";


export function* sendSavedQueriesSaga({data}) {
    try {
        console.log('data', data);

        yield put(setSavedQueriesLoading(true));
        const result = yield call(makeQuery, data);
        yield put(setSavedQueriesData(result));
        yield put(setSavedQueriesLoading(false));
        yield put(setSavedQueriesError({}));
    } catch (e) {
        yield put(setSavedQueriesError(e.response.data.error));
        yield put(setSavedQueriesLoading(false));
    }
}


export function* watchSavedQueries() {
    yield takeEvery(SAVED_QUERIES, sendSavedQueriesSaga);
}