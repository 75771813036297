import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            uz: {
                translation: {
                    homeWindow: "Асосий ойна",
                    exit: "Чиқиш",
                    create: "Яратиш",
                    save: "Сақлаш",
                    expenses: "Харажатлар",
                    expCode: "Харажат моддаси",
                    economy: "Иқтисодий",
                    parentExpCode: "Юкори модда",
                    success: "Муввафақият",
                    selectRow: "Қаторни танланг",
                    search: "Қидириш",
                    source: "Манба",
                    confirm: "Ишончингиз комилми?",
                    approve: "Тасдиқлаш",
                    administrative_claim: 'Маъмурий',
                    id: "ИД",
                    code: "Коди",
                    unit: "Ўл. бир.",
                    name: "Номи",
                    clear: "Тозалаш",
                    close: "Ёпиш",
                    chief: "Рахбар",
                    citizen: 'Фуқаролик',
                    criminal: 'Жиноий',
                    clone: 'Клонлаштириш',
                    newDoc: "Янги хужжат",
                    document: "Хужжат",
                    docNumb: "Хужжат рақами",
                    docDate: "Хужжат санаси",
                    docType: "Хужжат тури",
                    dateOpen: "Очилган санаси",
                    dateWork: "Охирги операция санаси",
                    sumpay: "Сумма",
                    bankDay: "Банк санаси",
                    bankAcc: "Банк хисоб рақами",
                    bookkeeper: "Хисобчи",
                    benificiar: "Бенефициар",
                    accid: "ИД Х/Р",
                    acc: "Хисоб рақам",
                    accTitle: "Хисоб рақамлар",
                    accName: "Хисоб рақам номи",
                    accType: "Хисоб рақам тури",
                    accState: "Хисоб рақам холати",
                    accSaldo: "Хисоб рақам қолдиғи",
                    actions: "Амаллар",
                    asboid: "АСБОИД",
                    lotid: "ЛОТ ИД",
                    bankId: "Банк ИД",
                    bankSendFlag: "Банкга жўнатилиши",
                    mfo: "МФО",
                    inn: "ИНН",
                    filial: 'Филиал',
                    finyear: 'Молиявий йил',
                    paydoc: "Тўлов хужжати",
                    paydocWork: "Тўлов хужжатлари билан ишлаш",
                    paydocOldWork: "Тўлов хужжатлари (Архив)",
                    paydocTitle: "Тўлов хужжатини яратиш",
                    paydocReturnTitle: "Қайтган тушумларни киритиш",
                    paydocRecoveryId: "Тикланаётган хужжат ИД си",
                    budgetPaydocWork: "Бюджет тўлов хужжатлари билан ишлаш",
                    budgetContractWork: "Бюджет шартномалари билан ишлаш",
                    oper: "Операция",
                    payDetail: "Тўлов мақсади",
                    payer: "Тўловчи",
                    payerAcc: "Тўловчи Х.Р.",
                    payerInn: "Тўловчи ИНН",
                    payerMfo: "Тўловчи МФО",
                    payerName: "Тўловчи номи",
                    refresh: "Янгилаш",
                    date_open: "Очилиш санаси",
                    date_work: "Иш санаси",
                    deliveryTime: "Етказиб бериш вақти",
                    recip: "Олувчи",
                    recipAcc: "Олувчи Х.Р.",
                    recipInn: "Олувчи ИНН",
                    recipMfo: "Олувчи МФО",
                    recipName: "Олувчи номи",
                    regNumb: "Регистрация рақами",
                    regNumbShort: "Рег. рақами",
                    regDate: "Регистрация санаси",
                    bankRequest: "Банкдан маълумот олиш",
                    legal: "Юридик мажбурият",
                    application: "Сўровнома",
                    cashAppId: "ИД",
                    cash_app_id: "Сўровнома Ид си",
                    cashAppNumb: "Сўровнома рақами",
                    cashAppDate: "Сўровнома санаси",
                    vendor: "Таъминотчи",
                    vendorName: "Таъминотчи номи",
                    avans: "Олдиндан тўлов",
                    avansDate: "Аванс санаси",
                    purpose: "Тафсилотлар",
                    phone: "Телефон рақам",
                    phoneMob: "Мобил рақам",
                    postIndex: "Почта индекси",
                    procuration: 'Ишончнома',
                    pinfl: "ПИНФЛ",
                    fax: "Факс",
                    filter: "Фильтр",
                    address: "Манзил",
                    addressJur: "Юридик манзил",
                    note: "Эслатма",
                    okonh: "ОКОНХ",
                    vendorType: "Таъминотчи тури",
                    contract: "Шартнома",
                    contract_id: "Шартнома ИД си",
                    contrNumb: "Шартнома рақами",
                    contrDate: "Шартнома санаси",
                    month: "Ой",
                    state: "Холат",
                    specification: "Спецификация",
                    action: "Амал",
                    createdBy: "Яратди",
                    createdDate: "Яратилган сана",
                    modifiedBy: "Тахрирлади",
                    modifiedDate: "Тахрирланган сана",
                    goods: 'Товар ва хизматлар',
                    goodReleasePerson: 'Товарни берган ходим',
                    invoice: 'Хисоб-фактура',
                    invoiceTitle: 'Хисоб-фактура киритиш',
                    incomeType: 'Даромад тури',
                    ndsBenefit: 'ҚҚС бўйича имтиёз',
                    nds: 'ҚҚС',
                    taxGap: 'Солиқ бўшлиғи',
                    client_id: 'Клиент ИД',
                    graphic: 'График',
                    procurement: 'Харид режа',
                    procurementCreate: 'Харид режасини яратиш',
                    lang: 'Тил',
                }
            },
            ru: {
                translation: {
                    homeWindow: "Главная",
                    exit: "Выход",
                    create: "Создать",
                    save: "Сохранить",
                    expenses: "Расходы",
                    expCode: "Статья расхода",
                    economy: "Экономический",
                    parentExpCode: "Вышес. статья",
                    success: "Успешно",
                    selectRow: "Выберите строку",
                    search: "Поиск",
                    source: "Источник",
                    confirm: "Вы уверены?",
                    approve: "Утвердить",
                    administrative_claim: 'Административный',
                    id: "ИД",
                    code: "Код",
                    unit: "Ед. из.",
                    name: "Название",
                    clear: "Очистить",
                    close: "Закрыть",
                    chief: "Руководитель",
                    citizen: 'Гражданский',
                    criminal: 'Уголовный',
                    clone: 'Клонировать',
                    newDoc: "Новый документ",
                    document: "Документ",
                    docNumb: "Номер документа",
                    docDate: "Дата документа",
                    docType: "Вид документа",
                    dateOpen: "Дата открытия",
                    dateWork: "Дата последней операции",
                    sumpay: "Сумма",
                    bankDay: "Банковский день",
                    bankAcc: "Расчетный счет банка",
                    bookkeeper: "Бухгалтер",
                    benificiar: "Бенефициар",
                    accid: "ИД Л/C",
                    acc: "Лицевой счет",
                    accTitle: "Лицевые счета",
                    accName: "Название лицевого счета",
                    accType: "Тип лицевого счета",
                    accState: "Состояние лицевого счета",
                    accSaldo: "Сальдо лицевого счета",
                    actions: "Действия",
                    asboid: "АСБОИД",
                    lotid: "ЛОТ ИД",
                    bankId: "Банк ИД",
                    bankSendFlag: "Отправка в банк",
                    mfo: "МФО",
                    inn: "ИНН",
                    filial: 'Филиал',
                    finyear: 'Финансовый год',
                    paydoc: "Платежный документ",
                    paydocWork: "Работа с платежными документами",
                    paydocOldWork: "Платежные документы (Архив)",
                    paydocTitle: "Создание платежного документа",
                    paydocReturnTitle: "Ввод возврата поступлений",
                    paydocRecoveryId: "ИД документа восстановления",
                    budgetPaydocWork: "Работа с платежными документами бюджета",
                    budgetContractWork: "Работа с договорами бюджета",
                    oper: "Операция",
                    payDetail: "Детали платежа",
                    payer: "Плательщик",
                    payerAcc: "Л.С. плательщика",
                    payerInn: "ИНН плательщика",
                    payerMfo: "МФО плательщика",
                    payerName: "Название плательщика",
                    refresh: "Обновить",
                    date_open: "Дата открытия",
                    date_work: "Дата работы",
                    deliveryTime: "Время доставки",
                    recip: "Получатель",
                    recipAcc: "Л.С. получателя",
                    recipInn: "ИНН получателя",
                    recipMfo: "МФО получателя",
                    recipName: "Название получателя",
                    regNumb: "Регистрационный номер",
                    regNumbShort: "Рег. номер",
                    regDate: "Дата регистрации",
                    bankRequest: "Запрос в банк",
                    legal: "Юридическое обязательство",
                    application: "Заявка",
                    cashAppId: "ИД",
                    cash_app_id: "ИД заявки",
                    cashAppNumb: "Номер заявки",
                    cashAppDate: "Дата заявки",
                    vendor: "Поставщик",
                    vendorName: "Название поставщика",
                    avans: "Аванс",
                    avansDate: "Дата аванса",
                    purpose: "Детали",
                    phone: "Номер телефона",
                    phoneMob: "Мобилный номер",
                    postIndex: "Почтовый индекс",
                    procuration: "Доверенность",
                    pinfl: "ПИНФЛ",
                    fax: "Факс",
                    filter: "Фильтр",
                    address: "Адрес",
                    addressJur: "Юридический адрес",
                    note: "Информация",
                    okonh: "ОКОНХ",
                    vendorType: "Вид поставщика",
                    contract: "Договор",
                    contract_id: "ИД договора",
                    contrNumb: "Номер договора",
                    contrDate: "Дата договора",
                    month: "Месяц",
                    state: "Состояние",
                    specification: "Спецификация",
                    action: "Действие",
                    createdBy: "Создал",
                    createdDate: "Дата создания",
                    modifiedBy: "Изменил",
                    modifiedDate: "Дата изменения",
                    goods: 'Товары и услуги',
                    goodReleasePerson: 'Сотрудник, передавший товар',
                    invoice: 'Счет-фактура',
                    invoiceTitle: 'Ввод счет-фактуры',
                    incomeType: 'Вид дохода',
                    ndsBenefit: 'Льгота по НДС',
                    nds: 'НДС',
                    taxGap: 'Налоговый разрыв',
                    client_id: 'Клиент ИД',
                    graphic: 'График',
                    procurement: 'План закупок',
                    procurementCreate: 'Создание плана закупок',
                    lang: 'Язык',
                }
            },
        },
        lng: "uz", // if you're using a language detector, do not define the lng option
        fallbackLng: "uz",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });