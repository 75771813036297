import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    query: {},
    totalItems: 0
};
const savedQueries = createSlice({
    name: 'savedQueries',
    initialState,
    reducers: {
        setSavedQueriesData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setSavedQueriesLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setSavedQueriesError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setSavedQueriesData,setSavedQueriesLoading,setSavedQueriesError} = savedQueries.actions;
export default savedQueries.reducer;
