import axios from "axios";
/*import { useNavigate } from "react-router-dom";*/

export const getBackendApiBaseUrl = () => {
    const hostname = window.location.hostname;
    const path = window.location.pathname;
    console.log(hostname,'hostname')

    if (hostname === 'mbat.chamber.uz') {
        return 'https://mbatbackend.chamber.uz';
    //} else if (hostname === 'localhost' || (hostname === 'mbatbackend.chamber.uz' && path.startsWith('/test_be')) ) {
    } else if (hostname === 'localhost' || hostname === 'testmbat.chamber.uz' || hostname === 'kmbat.uz' ) {
        //return 'https://10.50.50.145:9898/test_be';
        //return 'http://45.138.158.185:9898/test_be';
        return 'https://main.kmbat.uz/test_be';
        //return 'https://mbatbackend.chamber.uz/test_be';
        //return 'https://mbatbackend.chamber.uz';
    }

    return 'https://main.kmbat.uz/test_be';
    //return 'https://mbatbackend.chamber.uz';
};

/*axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // Check if the error status is 401
        if (error.response && error.response.status === 401) {
            // Redirect to the index page or any other page as needed
            const navigate = useNavigate ();
            navigate('/');
        }

        // You can also handle other error cases here if needed

        return Promise.reject(error);
    }
);*/

export default axios.create({
    //baseURL: 'https://mbatbackend.chamber.uz'
    //baseURL: 'https://backend.ssp-mbat.uz',
    //baseURL: 'https://newbackend.ssp-mbat.uz/'
    baseURL: getBackendApiBaseUrl()
    //baseURL: 'http://217.30.161.190:10006'
    //baseURL: 'https://mbat.chamber.uz'
    //baseURL: 'http://172.24.255.74:9595'
    //baseURL: 'http://95.181.224.113:9595'
    /*baseURL: 'http://10.50.50.145:9797'*/
    //baseURL: 'http://192.168.44.201:8383'
})

