import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {SMETA_HISTORY_DETAILS, SMETA_HISTORY_TAB} from "../../../types/smeta/smetaHistory/smetaHistoryTab";
import {
    setSmetaHistoryTabLoading,
    setSmetaHistoryTabData,
    setSmetaHistoryTabError,
    setSmetaHistoryDetails
} from "../../../slices/smeta/smetaHistory/smetaHistoryTab";

export function* sendSmetaHistoryTabSaga({bodyData}) {
    try {
        yield put(setSmetaHistoryTabLoading(true));
        const result = yield call(makeQuery, bodyData);
        yield put(setSmetaHistoryTabData(result));
        yield put(setSmetaHistoryTabLoading(false));
        yield put(setSmetaHistoryTabError({}));
    } catch (e) {
        yield put(setSmetaHistoryTabError(e.response.data.error));
        yield put(setSmetaHistoryTabLoading(false));
    }
}
export function* sendSmetaHistoryDetailsSaga({data}) {
    try {

        yield put(setSmetaHistoryDetails(data));
    } catch (e) {
        yield put(setSmetaHistoryTabError(e.response.data.error));
        yield put(setSmetaHistoryTabLoading(false));
    }
}


export function* watchSmetaHistoryTab() {
    yield takeEvery(SMETA_HISTORY_TAB, sendSmetaHistoryTabSaga);
    yield takeEvery(SMETA_HISTORY_DETAILS, sendSmetaHistoryDetailsSaga);
}