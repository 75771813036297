import React, { useEffect, useMemo, useState } from 'react';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/line';
import ReactECharts from 'echarts-for-react';
import { generateColor } from '../index';
import './bubble.css';

const CustomLargeAreaChart = ({ comparing_columns, comparing_by, data }) => {
    const [option, setOption] = useState({});
    const [chartWidth, setChartWidth] = useState(0);

    const newFormat = useMemo(() => {
        return data.map((item) => {
            let main = '';
            for (let itemKey in item) {
                if (comparing_by.includes(itemKey)) {
                    if (main !== '') {
                        main += ' : ' + item[itemKey];
                    } else {
                        main +=   item[itemKey];
                    }
                }
            }
            return { main, ...item };
        });
    }, [data, comparing_by]);

    const allDate = useMemo(() => newFormat.map((item) => item.main), [newFormat]);

    const allData = useMemo(() => {
        return comparing_columns.map((column) => {
            return {
                column,
                data: newFormat.map((item) => item[column]),
            };
        });
    }, [comparing_columns, newFormat]);

    useEffect(() => {
        const optionData = {
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
                return [pt[0], '0%'];
                },
        },

        toolbox: {
            feature: {
                dataZoom: {
                        yAxisIndex: 'none',
                },
                restore: {},
                    saveAsImage: {},
                },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
                data: allDate,
        },
        yAxis: {
            type: 'value',
                boundaryGap: [0, '100%'],
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                    end: 10,
            },
            {
                start: 0,
                    end: 10,
                },
        ],
        series: allData.map((col) => {
                return {
                    name: col.column,
                    type: 'line',
                    symbol: 'none',
                    sampling: 'lttb',
                    itemStyle: { color: generateColor() },
                    areaStyle: {},
                    data: col.data,
                };
            }),
    };
        setOption(optionData);
    }, [allDate, allData]);

    useEffect(() => {
        const handleResize = () => {
            const width = document.getElementById('chartContainer').offsetWidth;
            setChartWidth(width);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div id="chartContainer" style={{ width: '100%', height: '100%' }}>
            <ReactECharts option={option} style={{ width: chartWidth, height: '100%' }} />
        </div>
    );
};

export default CustomLargeAreaChart;
