import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Form, Input, message, Modal, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import axios from "../../api/axios";
import NumberInputBM from "../../components/NumberInput";
import EmpCertAdd from "../../pages/Employee/EmpCertAdd";
import styled from "styled-components";

const QUERY_URL = '/api/public/query';
const SAVE_URL = '/user/changePassword';

const UserForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 10px;
  }

`;

const UserProfile = ({empId}) => {

    const navigate = useNavigate();

    const auth = useContext(AuthContext);
    const [form] = Form.useForm()
    const [formPassword] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(true);


    const headers = {
        'Content-Type':'application/json;charset=utf-8',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Credentials':'true',
        'Authorization':`Bearer ${auth.token}`,
        'withCredentials': true
    }

    const onFinish = async (values) => {

        const userLogin = form.getFieldValue("login");
        const userPass = values.passwordNew;

        try {
            const data = await axios.post(SAVE_URL,

                JSON.stringify({'login':userLogin, 'password':userPass})
                , {
                    headers: headers
                }
                // )
            ).then(response => {

                console.log(response, 'response')
                if (response.status === 200) {
                    message.success('Парол узгартирилди', navigate('/'));

                }

            })
            //message.success('Парол узгартирилди', handleFormExit);


        } catch (err) {
            message.error(err.response?.data?.message);
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const dataTab = {
        "query": {
            "id": "EMPLOYEE_UI_V",
            "source": "EMPLOYEE_UI_V",
            "fields": [
                {   "column": "id",  },
                {   "column": "filial",  },
                {   "column": "filial_name",  },
                {   "column": "fio",  },
                {   "column": "login",  },
                {   "column": "phonenumber",  },
                {   "column": "mobilenumber",  },
                {   "column": "staff",  },
                {   "column": "struct",  },
                {   "column": "state",  },
                {   "column": "state_name",  },
                {   "column": "created_by",  },
                {   "column": "created_by_name",  },
                {   "column": "created_date",  },
                {   "column": "modified_by",  },
                {   "column": "modified_by_name",  },
                {   "column": "modified_date",  }
            ]
        }
    }

    const getTabData = async () => {

        dataTab.query['filters'] = [{"column": "id", "value": empId, "operator": "=", "dataType": "number"}];

        console.log(empId,'empFilial')

        console.log(dataTab,'qweqwe')

        const { data } = await axios.post(QUERY_URL,
            ( dataTab ),
            {
                headers: headers,
                //crossDomain: true,
                withCredentials: false
            });

        setLoading(false);
        console.log(data)
        //form.setFieldValue("fio",data[0].fio)
        form.setFieldsValue({
            "fio":data[0].fio,
            "filial":data[0].filial + ' - ' + data[0].filial_name,
            "login":data[0].login,
            "phonenumber":data[0].phonenumber,
            "mobilenumber":data[0].mobilenumber,
            "state":data[0].state_name,
        })



    };

    useEffect(() => {
        getTabData();
    }, []);

    const handleFormSubmit = () => {

        formPassword
            .validateFields()
            .then(async (values) => {
                console.log(JSON.stringify(values),'eeed')
                return
                try {
                    const data = await axios.post(SAVE_URL,

                        JSON.stringify(values)
                        , {
                            headers: headers
                        }
                        // )
                    ).then(response => { console.log(response, 'response')} )
                    //message.success('Парол узгартирилди', handleFormExit);
                    messageApi.open({
                        type: 'success',
                        content: 'Парол узгартирилди',
                        duration: 2,
                    }).then(()=> null);

                } catch (err) {
                    message.error(err.response?.data?.message);
                }

            })
            .catch((errorInfo) => {
                console.log('errorInfo ...', errorInfo);
            }).then(r => r);
    }

    const formItemLayout =
        {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        }

    const handleFormExit = () => {
        navigate(-1)
    }

    return (
        <>
            {contextHolder}
            <Space direction="horizontal" size="middle" style={{ display: "flex", justifyContent:"center", alignItems: "center", height: "100%", width: "100%", position: "relative", overflow: "hidden" }}/>

            <UserForm name="userProfile" form={form} scrollToFirstError initialValues={{}}
                  {...formItemLayout}>
                {/*onFinishFailed={onFinishFailed}*/}

                <Form.Item name="filial" label="Филиал" rules={[{required: false, message:'Филиални киритинг!'}]}>
                    <Input readOnly="readOnly"/>
                </Form.Item>

                <Form.Item name="fio" label="Ф.И.О" rules={[{required: false, message:'Ф.И.О. ни киритинг!'}]}>
                    <Input readOnly="readOnly"/>
                </Form.Item>

                <Form.Item name="phonenumber" label="Телефон ракам" rules={[{required: false, message:'Телефон ракамни киритинг!'}]}>
                    <Input readOnly="readOnly"/>
                </Form.Item>

                <Form.Item name="mobilenumber" label="Мобил ракам" rules={[{required: false, message:'Мобил ракамни киритинг!'}]}>
                    <Input readOnly="readOnly"/>
                </Form.Item>

                <Form.Item name="state" label="Холат" rules={[{required: false, message:'Холатни киритинг!'}]}>
                    <Input readOnly="readOnly"/>
                </Form.Item>

                <Form.Item name="login" label="Логин" rules={[{required: false, message:'Логинни киритинг!'}]}>
                    <Input readOnly="readOnly"/>
                </Form.Item>

            </UserForm>

            <Divider>Паролни узгартириш</Divider>

            <UserForm form={formPassword} scrollToFirstError initialValues={{passwordNew:""}} onFinish={onFinish}
                  {...formItemLayout}
            >

                <Form.Item name="passwordNew" label="Парол" rules={[{required:true, message: 'Паролни киритинг'}]}>
                    <Input.Password/>
                </Form.Item>

                <Form.Item name="confirmNew" label="Паролни тасдиклаш" rules={[{required:true, message: 'Тасдиклаш паролини киритинг'},
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('passwordNew') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Пароллар бир бирига тенг эмас'))
                        }
                    })]}>
                    <Input.Password/>
                </Form.Item>

                <Form.Item style={{ textAlign: 'right' }} labelCol={{span:0}} wrapperCol={{span: 24}}>
                    <Button type="primary" htmlType="submit" style={{marginRight:'10px'}}>
                        Паролни узгартириш
                    </Button>
                </Form.Item>

            </UserForm>

            <Divider>Электрон рақамли имзони боғлаш</Divider>


            <EmpCertAdd empId={empId}/>

            {/*<Button
                onClick={handleEcpDialog}
                type="primary"
            >
                ЭРИ боглаш
            </Button>

            <Modal
                title="ЭРИ боглаш"
                open={openEcpDialog}
                //onOk={handleAction}
                //confirmLoading={confirmLoading}
                onCancel={() => setOpenEcpDialog(false)}
            >
                <EmpCertAdd/>
            </Modal>*/}
        </>
    )
}

export default UserProfile;