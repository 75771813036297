import {Space, Tabs} from 'antd';
import Login from './Login';
import CertsEcp from './CertsEcp';

const { TabPane } = Tabs;

function LoginTabs() {
  return (

      <div style={{
          width: '80%',
          height: '80%',
          backgroundColor: '#ffffff',
          padding: 0,
          position: "relative",
          overflow: "hidden",
          border: "0px solid #f6d98f",
          boxShadow: "-2px -2px 100px rgb(213, 213, 213)",
          borderRadius: "10px"
      }}>
          {/*<Login/>*/}
            <Tabs defaultActiveKey="1" size="large" centered style={{width: '100%', height: '100%', backgroundColor: '#ffffff'}} >

                <TabPane tab="Логин" key="1" style={{backgroundColor: '#ffffff', height: "100%"}}>

                        <Login/>
                </TabPane>
                <TabPane tab="ЭЦП" key="2" style={{backgroundColor: '#ffffff', height: "100%", padding: "10px 40px", overflowY: 'scroll'}}>

                        <CertsEcp/>
                </TabPane>

            </Tabs>
      </div>

)
    ;
}

export default LoginTabs;