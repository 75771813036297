import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DraggableModal, DraggableModalProvider} from "@cubetiq/antd-modal";
import {SAVED_QUERIES} from "../../../store/types/chart/saved_queries";
import {AuthContext} from "../../../context/AuthContext";
import {Button, Descriptions, Layout, message, Table, Typography} from "antd";
import useTableActions from "../../../hooks/useTableActions";

const {Title} = Typography;
const savedQueriesTab = {
    "query": {
        "ID": "get_bi_diagrams_by_emp_id_ui_v",
        "source": "get_bi_diagrams_by_emp_id_ui_v",
        "fields": [
            {"column": "id"},
            {"column": "name"},
            {"column": "menu_name"},
            {"column": "query_json"},
            {"column": "diagram_json"},
            {"column": "details"},
            {"column": "created_by_name"},
            {"column": "filial"},
            {"column": "created_date", "format": "datetime", "type": "char"}
        ]
    }
};
export const savedQueriesTabColumns = [
    {
        title: 'ИД',
        dataIndex: 'id',
        width: 100,
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Номи',
        dataIndex: 'name',
        width: 100,
        sorter: (a, b) => a.name - b.name

    },
    {
        title: 'Меню номи',
        dataIndex: 'menu_name',
        width: 100,
        sorter: (a, b) => a.menu_name - b.menu_name
    },
    {
        title: 'Детали',
        dataIndex: 'details',
        width: 200,
        sorter: (a, b) => a.details - b.details
    }
];
const SavedQueries = ({modal, setModal, onFinish}) => {
    const data = useSelector((state) => state.savedQueries.data);
    const totalItems = useSelector((state) => state.savedQueries.totalItems);
    const loading = useSelector((state) => state.savedQueries.loading);
    const error = useSelector((state) => state.savedQueries.error);
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleDoubleClick = (record, rowIndex) => {
        onFinish(JSON.parse(record.diagram_json.value), JSON.parse(record.query_json.value), record.name);
    };
    const {rowSelection, onRow} = useTableActions(totalItems, selectedRowKeys, setSelectedRow, setSelectedRowKeys, "id", handleDoubleClick);
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const onOk = () => {
        setModal(false)
    };
    useEffect(() => {
        if (modal) {
            const data = {
                query: {
                    id: savedQueriesTab.query.ID || savedQueriesTab.query.id,
                    source: "get_bi_diagrams_by_emp_id_ui_v(" + auth.empId + ")",
                    fields: savedQueriesTab.query.fields
                }
            };
            dispatch({type: SAVED_QUERIES, data})
        }
    }, [modal]);
    return (
        <DraggableModalProvider>
            <DraggableModal open={modal} onOk={onOk} onCancel={onOk} initialWidth={900} initialHeight={700}>
                <Layout style={{height: "100%", overflow: "hidden"}}>
                    {contextHolder}
                    <div style={{position: 'relative', height: '100%'}}>
                        <Table rowSelection={rowSelection}
                               columns={savedQueriesTabColumns}
                               dataSource={data}
                               className='table-striped-rows table-th-nobreak-word'
                               rowKey={"id"}
                               loading={loading}
                               size='small'
                               bordered='bordered'
                               scroll={{x: 300, y: 'calc(100vh - 350px)'}}
                               tableLayout="auto"
                               pagination={false}
                               title={() => {
                                   return (
                                       <Title level={4}>Сақланган диаграммалар</Title>
                                   )
                               }}
                               onRow={onRow}
                        />
                        {selectedRow && (

                            <Descriptions bordered
                                          size='small'
                                          style={{position: 'absolute', left: 0, bottom: 0, width: '100%'}}
                                          column={{
                                              xxl: 2,
                                              xl: 2,
                                              lg: 2
                                          }}
                            >

                                <Descriptions.Item
                                    label="Томонидан яратилгани">{selectedRow.created_by_name || ''}</Descriptions.Item>
                                <Descriptions.Item
                                    label="Яратилган санаси">{selectedRow.created_date || ''}</Descriptions.Item>
                            </Descriptions>
                        )}
                    </div>
                </Layout>
            </DraggableModal>
        </DraggableModalProvider>
    );
};

export default SavedQueries;