import React, {useEffect} from 'react';
import {sortCompareString} from "../../libs/formbm";
import {useSelector} from "react-redux";
import {Col, Form, Row, Table} from "antd";

const EmployeeHistoryDetails = () => {
    const info = useSelector((state) => state.smetaHistoryTab.details);
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    useEffect(()=> {
        console.log('info', info)
    },[info]);
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div style={{padding: '20px'}}>
            <Form
                name="basic"
                style={{
                    width: '100%',
                    rowGap: '15px'
                }}
                initialValues={{
                    remember: false,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="inline"
            >
                <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item
                                label="ИД"
                                name="id"
                            >
                                {info?.id}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Логин"
                                name="login"
                            >
                                {info?.login}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Мобил рақами"
                                name="mobilenumber"
                            >
                                {info?.mobilenumber}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Телефон рақами"
                                name="phonenumber"
                            >
                                {info?.phonenumber}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="ПИНФЛ"
                                name="pinfl"
                            >
                                {info?.pinfl}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Фойдаланувчи"
                                name="username"
                            >
                                {info?.username}
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
};

export default EmployeeHistoryDetails;