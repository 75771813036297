import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {DOC_CONTROL_REF} from "../../../types/extraSelectingData/control/control";
import {setControlData,setControlError, setControlLoading} from "../../../slices/extraSelectingData/control/control";


export function* sendControlSaga({controlData}) {
    try {
        yield put(setControlLoading(true));
        const result = yield call(makeQuery, controlData);
        yield put(setControlData(result));
        yield put(setControlLoading(false));
        yield put(setControlError({}));
    } catch (e) {
        yield put(setControlError(e.response.data.error));
        yield put(setControlLoading(false));
    }
}


export function* watchControl() {
    yield takeEvery(DOC_CONTROL_REF, sendControlSaga);
}
