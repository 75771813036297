import React, {useEffect} from 'react';
import {sortCompareString} from "../../../libs/formbm";
import {useSelector} from "react-redux";
import {Col, Form, Row, Table} from "antd";
const tableColumns = [
    {
        title: 'Харажат',
        dataIndex: 'expense',
        width: 200,
        sorter: (a, b) => sortCompareString(a.expense, b.expense)
    },
    {
        title: 'Ой',
        dataIndex: 'month',
        width: 100,
        sorter: (a, b) => sortCompareString(a.month, b.month)
    },
    {
        title: 'Тўлов суммаси',
        dataIndex: 'sumPay',
        width: 200,
        align: 'right',
        sorter: (a, b) => a.sumPay -  b.sumPay,
        render: (value) => {
            return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(value);
        }
    }
];
const SmetaOldHistoryDetails = () => {
    const info = useSelector((state) => state.smetaHistoryTab.details);
    const dateFormat = 'YYYY-MM-DD';
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    useEffect(()=> {
        console.log('info', info)
    },[info]);
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div style={{padding: '20px'}}>
            <Form
                name="basic"
                style={{
                    width: '100%',
                    rowGap: '15px'
                }}
                initialValues={{
                    remember: false,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="inline"
            >
                <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item
                                label="ИД"
                                name="id"
                            >
                                {info?.id}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ҳисоб рақам ИД"
                                name="accId"
                            >
                                {info?.accId}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ҳисоб рақам"
                                name="acc"
                            >
                                {info?.acc}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Филиал"
                                name="filial"
                            >
                                {info?.filial}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Молиявий йил"
                                name="finYear"
                            >
                                {info?.finYear}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Мақсад"
                                name="purpose"
                            >
                                {info?.purpose}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Table  dataSource={info?.smetapoints} columns={tableColumns} pagination={true}/>
                </div>
            </Form>
        </div>
    );
};

export default SmetaOldHistoryDetails;