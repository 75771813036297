import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0
};
const type = createSlice({
    name: 'type',
    initialState,
    reducers: {
        setTypeData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setTypeLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setTypeError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setTypeData,setTypeLoading,setTypeError} = type.actions;
export default type.reducer;
