import {takeEvery, put, call} from 'redux-saga/effects';
import {setChartQuery} from "../../../slices/chart/chart";
import {BANK_ACC_EXPENSE_PERIOD} from "../../../types/account/bankAccExpensePeriod/bankAccExpensePeriod";
import {
    setBankAccExpensePeriodData,
    setBankAccExpensePeriodError,
    setBankAccExpensePeriodLoading,
    setBankAccExpensePeriodRowCount
} from "../../../slices/account/bankAccExpensePeriod/bankAccExpensePeriod";
import {makeQueryPageable} from "../../../apis";

export function* sendBankAccExpensePeriodSaga({queryData, params}) {
    try {
        yield put(setBankAccExpensePeriodLoading(true));
        const result = yield call(makeQueryPageable, queryData,params);
        yield put(setBankAccExpensePeriodData(result.data));
        yield put(setBankAccExpensePeriodRowCount(result.totalRowCount));
        yield put(setChartQuery(queryData));
        yield put(setBankAccExpensePeriodLoading(false));
        yield put(setBankAccExpensePeriodError({}));
    } catch (e) {
        yield put(setBankAccExpensePeriodError(e.response.data.error));
        yield put(setBankAccExpensePeriodLoading(false));
    }
}


export function* watchBankAccExpensePeriod() {
    yield takeEvery(BANK_ACC_EXPENSE_PERIOD, sendBankAccExpensePeriodSaga);
}