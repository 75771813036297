import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";

const useStatsDescTabData = () => {
    const auth = useContext(AuthContext);

    const mainStatsDescTabData = {
        "query": {
            //"id": "get_bank_account_saldo_by_emp_id_ui_v",
            "id": "GET_ACCOUNT_SALDO_GROUP_BY_ACCOUNT_FOR_EMP_ID_UI_V",
            "source": "GET_ACCOUNT_SALDO_GROUP_BY_ACCOUNT_FOR_EMP_ID_UI_V("+auth.empId+")",
            "fields": [
                {
                    "column": "saldo_in",
                    "format": "sum",
                    "type": "char",
                    "function": {
                        "type": "sum"
                    }
                },
                {
                    "column": "debet",
                    "format": "sum",
                    "type": "char",
                    "function": {
                        "type": "sum"
                    }
                },
                {
                    "column": "credit",
                    "format": "sum",
                    "type": "char",
                    "function": {
                        "type": "sum"
                    }
                },
                {
                    "column": "saldo_out",
                    "format": "sum",
                    "type": "char",
                    "function": {
                        "type": "sum"
                    }
                },
                {
                    "column": "filial"
                },
                {
                    "column": "filial_name"
                }
            ],
            "groupBy": [
                "filial",
                "filial_name",
            ],
            "orderBy": [
                {
                    "column": "filial",
                    "direction": "asc"
                }
            ]
        }
    };

    const mainStatsDescTabColumns = [
        {
            title: 'Филиал',
            dataIndex: 'filial',
            width: 200,
            defaultSortOrder: 'ascend',
            render: (text,record) => ( record.filial + ' - ' + record.filial_name ),
            sorter: (a, b) => a.filial - b.filial,
        },
        {
            title: 'Кун бошига қолдиқ',
            dataIndex: 'saldo_in',
            width: 100,
            align: "right",
            //sorter: (a, b) => a.saldo_in - b.saldo_in,
            sorter: (a, b) => {
                // Parse the string as a float for sorting
                const sumA = parseFloat(a.saldo_in.replace(/,/g, ''));
                const sumB = parseFloat(b.saldo_in.replace(/,/g, ''));
                return sumA - sumB;
            },
        },
        {
            title: 'Дебет',
            dataIndex: 'debet',
            width: 100,
            align: "right",
            //sorter: (a, b) => a.debet - b.debet,
            sorter: (a, b) => {
                // Parse the string as a float for sorting
                const sumA = parseFloat(a.debet.replace(/,/g, ''));
                const sumB = parseFloat(b.debet.replace(/,/g, ''));
                return sumA - sumB;
            },
        },
        {
            title: 'Ҳаражат',
            dataIndex: 'credit',
            width: 100,
            align: "right",
            //sorter: (a, b) => a.credit - b.credit,
            sorter: (a, b) => {
                // Parse the string as a float for sorting
                const sumA = parseFloat(a.credit.replace(/,/g, ''));
                const sumB = parseFloat(b.credit.replace(/,/g, ''));
                return sumA - sumB;
            },
        },
        {
            title: 'Жорий қолдиқ',
            dataIndex: 'saldo_out',
            width: 100,
            align: "right",
            //sorter: (a, b) => a.saldo_out - b.saldo_out
            sorter: (a, b) => {
                // Parse the string as a float for sorting
                const sumA = parseFloat(a.saldo_out.replace(/,/g, ''));
                const sumB = parseFloat(b.saldo_out.replace(/,/g, ''));
                return sumA - sumB;
            },
        }
    ];
    return {mainStatsDescTabColumns, mainStatsDescTabData};
};

export default useStatsDescTabData;