import {Alert, Button, Descriptions, Dropdown, Input, Layout, message, Modal, Space, Table} from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import {useNavigate} from "react-router-dom";
import axios from "../../api/axios";
//import AuthContext from "../../context/AuthProvider";
import {AuthContext} from "../../context/AuthContext";
import {columns, dataTab, dataFilter} from "./EmpData";
import {DownOutlined, EyeOutlined, FileAddOutlined, FileExcelOutlined} from "@ant-design/icons";
import FilterModal from "../../components/FilterModalBM";
import dayjs from "dayjs";
import exportToExcel from "../../components/ExportToExcel";
import {useFilter} from "../../context/FilterContext";
import EmpCertAdd from "./EmpCertAdd";
import SmetaHistoryTab from "../Smeta/SmetaHistory/SmetaHistoryTab";
import EmployeeHistoryTab from "./EmployeeHistoryTab";
const EMPLOYEE_URL = '/user/listAll';
const QUERY_URL = '/api/public/query';
const EXECUTE_URL = '/user/execute';

const itemsAction = [
    {
        key: '2',
        label: 'Тасдиқлаш',
    },
    {
        key: '4',
        label: 'Тасдиқни бекор қилиш',
    },
    /*{
        key: '5',
        label: 'Таҳрирлаш',
    },*/
    {
        key: '3',
        label: 'Ўчириш',
    },

]

const EmployeeTab = (props) => {
    let navigate = useNavigate();

    //const { auth, setAuth } = useContext(AuthContext);
    const auth = useContext(AuthContext);

    const { filterValues } = useFilter();
    const filterKey = 'employeeTabFilter'; // Set the filterKey for this route
    const filterData = filterValues[filterKey] || '';
    const [currentState, setCurrentState] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [stateTab, setStateTab] = useState([]);
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);
    const [filterTab, setFilterTab] = useState("");
    const [tabHeight, setTabHeight] = useState();
    const [messageApi, contextHolder] = message.useMessage();

    const [openAction, setOpenAction] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [purposeValue, setPurposeValue] = useState('');
    const [action, setAction] = useState('');

    const [openEcpDialog, setOpenEcpDialog] = useState(false);



    useEffect(() => {
        props.setTitleNav('Фойдаланувчилар билан ишлаш');
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    let v_data = [];

    useEffect(() => {
        getTabData();
       // const layEl = document.getElementById("bmTabLayout");
       // const tabEl = document.getElementById("bmTabHeader");

       // setTabHeight(layEl.offsetHeight-tabEl.offsetHeight);

    }, []);

    const headers = {'Content-Type':'application/json;charset=utf-8',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Credentials':'true',
        'Authorization':`Bearer ${auth.token}`,
        'withCredentials': true
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const getTabData = async (filter = '') => {

        let filters = [];
        if (filter!=='' && filter!== '{}') filters = filter
        //console.log(dataTab.toString(),'dataTab')
        if (filterData && filters.length === 0) {
            filters = [...filters, ...filterData];
        }

        dataTab.query['filters'] = filters;

        const { data } = await axios.post(QUERY_URL,
            ( dataTab ),
            {
                headers: headers,
                //crossDomain: true,
                withCredentials: false
            });
        setLoading(false);
        //setStateTab(data);
        console.log(data, 'employeeData')
        setStateTab(data);

        setSelectedRow(null)
        setSelectedRowKeys([])

        setFilterTab( dataFilter );

    };

    const getDataEmployeesOld = async (filter = '') => {

        const { data } = await axios.post(EMPLOYEE_URL,
            JSON.stringify({ login: "TEST", password: "SPRING" }), //#TODO login: auth.userId, password: auth.pwd
            {
                headers: headers,
                crossDomain: true,
                withCredentials: false
            });
        setLoading(false);
        //setStateTab(data);
        setStateTab(data.map(row => ({
            key: row.id,
            userId: row.id,
            deptLevel: row.deptlevel,
            struct: row.struct,
            staff: row.staff,
            name: row.fio,
            login: row.login,
            phoneNumber: row.phonenumber,
            mobileNumber: row.mobilenumber,
            dateOpen: row.dateopen,
            dateExpire: row.dateexpire,
            cntTries: row.cnttries,
            action: row.action,
            filial: row.filial,
            filialName: row.filial_name,
            state: row.state,
            stateName: row.state_name,
            emp: row.emp,
            createdDate: row.created_date,
            createdBy: row.created_by + ' - ' +row.created_by_name,
            modifiedDate: row.modified_date,
            modifiedBy: row.modified_by + ' - ' +row.modified_by_name,
            isEnabled: row.enabled,
            accountNonExpired: row.accountNonExpired,
            accountNonLocked: row.accountNonLocked,
            credentialsNonExpired: row.credentialsNonExpired
        })));
        console.log(stateTab);
        //setFilials(data);
    };

    const onSelect = (record, rowIndex) => {
        setSelectedRowKeys([record.id]);
        setSelectedRow(record);
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () =>{ onSelect(record, rowIndex) }
        }
    };

    const handleAdd = () => {
        navigate("../employeeAdd");
    };

    const handleEdit = () => {
        if (selectedRowKeys.length === 0) { message.warning('Каторни танланг'); return false; }
        let empId = selectedRowKeys[0]
        console.log(selectedRowKeys[0],'lololo') //empid
        navigate(`../employeeEdit/${empId}`);
    };

    const handleApprove = () => {
        if (selectedRowKeys.length === 0) { message.warning('Каторни танланг'); return false; }
        message.success({content:'Муввафакият',
            className: 'custom-class',
            style: {
                right: '0',
                top:'0',
                marginTop: '20px',
            }
        })
    }

    const showModalAction = (values) => {

        if (selectedRowKeys.length === 0) {
            message.warning('Каторни танланг');
            setActionLoading(false);
            return false;
        }

        setActionLoading(true);
        setAction(values.key)
        /*if (values.key === "3" || values.key === "4") setOpenAction(true);
        else handleAction()*/
    };

    useEffect(() => {
        if (action !== '') {
            if (action === "3" || action === "4") setOpenAction(true);
            else handleAction();
        }
    }, [action]);

    const handleAction = async () => {


        if (selectedRowKeys.length === 0) { message.warning('Каторни танланг'); return false; }

        let data = stateTab.find(row => row.id === selectedRowKeys[0]),
            userId = data.id,
            act = action;

        if (act === "3" || act === "4") {
            if (purposeValue === null || purposeValue === '' || purposeValue.trim() === '') {
                message.warning('Тафсилотни киритинг');
                return false;
            }
        }

        try {
            const { data } = await axios.post(`/user/execute?userId=${userId}&action=${act}`,
                purposeValue===''?'null':purposeValue,
                {
                    headers: headers,
                    //crossDomain: true,
                    withCredentials: false
                })
            messageApi.open({
                type: 'success',
                content: 'Муввафакият',
                duration: 2
            });
            setOpenAction(false)
            getTabData()

        } catch (err) {
            messageApi.open({
                type: 'error',
                content: err.response?.data?.message,
                duration: 8
            });
            //message.error(err.response?.data);
        } finally {
            setActionLoading(false);
            setAction('')
        }
    }

    const handleHistoryView = () => {
        if (selectedRowKeys.length === 0) {
            messageApi.open({
                type: 'warning',
                content: 'Каторни танланг',
            });

            return false;
        }
        let data = stateTab.find(row => row.id === selectedRowKeys[0]);
        setCurrentState({state: {doc_id: data.id, doc_type: 'EMPLOYEE' }});
        showModal();
    };
    const handleAccessRole = () => {
        if (selectedRowKeys.length === 0) { message.warning('Қаторни танланг'); return false; }
        let data = stateTab.find(row => row.id === selectedRowKeys[0])
        navigate("../accessRole",{state: { empId:selectedRowKeys[0], empName: data?.fio}});
    };

    const handleEcpDialog = () => {
        if (selectedRowKeys.length === 0) { message.warning('Каторни танланг'); return false; }
        setOpenEcpDialog(true)
    }

    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        getCheckboxProps: (record) => ({
            style: {
                visibility: 'hidden',
                position: 'absolute',
            },
        }),
        onChange: (record, selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
        },

    };

    const pagination = {
        total: stateTab.length,
        showTotal: (total, range) => (
            <span>
                <strong>{total}</strong> катордан {range[0]}-{range[1]} гача
            </span>
        ),
        pageSize: 500,
        size: 'small',
        defaultCurrent: 1,
        position: ['topRight'],
        hideOnSinglePage:false,
    };

    const tableHeader = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }}>

                <Button
                    onClick={handleAdd}
                    type="primary"
                >
                    Кушиш
                </Button>

                <Button
                    onClick={handleAccessRole}
                    type="primary"
                >
                    Рольни боглаш
                </Button>

                <Dropdown
                    menu={{
                        items: itemsAction,
                        onClick: (values) => showModalAction(values),
                    }}
                    disabled={actionLoading}
                >
                    <Button type='primary'>
                        <Space>
                            Амаллар
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>

                <Modal
                    title="Тафсилот"
                    open={openAction}
                    onOk={handleAction}
                    confirmLoading={confirmLoading}
                    onCancel={() => setOpenAction(false)}
                >
                    <Input.TextArea
                        value={purposeValue}
                        onChange={(e) => setPurposeValue(e.target.value)}
                    />

                </Modal>

                <Button
                    onClick={handleEdit}
                    type="primary"
                >
                    Тахрирлаш
                </Button>
                <Button
                    onClick={handleHistoryView}
                    type="primary"
                    icon={<EyeOutlined style={{fontSize:'16px'}} />}
                >
                    Протокол
                </Button>
                {/*<Button
                    onClick={handleEcpDialog}
                    type="primary"
                >
                    ЭРИ боглаш
                </Button>

                <Modal
                    title="ЭРИ боглаш"
                    open={openEcpDialog}
                    //onOk={handleAction}
                    confirmLoading={confirmLoading}
                    onCancel={() => setOpenEcpDialog(false)}
                >
                    <EmpCertAdd/>
                </Modal>*/}

                <Button type="primary" shape="circle" onClick={() => exportToExcel(stateTab)}>
                    <FileExcelOutlined/>
                </Button>

                <FilterModal filterKey={filterKey} filterTab={filterTab} onSubmit={getTabData}/>
            </div>
        );
    }

    return (
        <Layout style={{height:"100%", overflow:"hidden"}}>
            {contextHolder}
            <div style={{position: 'relative', height: '100%'}}>
                {/*<div id="bmTabHeader">
                    <Button
                        onClick={handleAdd}
                        type="primary"
                        style={{
                            margin: 10,
                        }}
                    >
                        Кушиш
                    </Button>
                    <Button
                        onClick={handleApprove}
                        type="primary"
                        style={{
                            margin: 10,
                        }}
                    >
                        Тасдиклаш
                    </Button>
                </div>*/}

                <Table rowSelection={rowSelection}
                       columns={columns}
                       dataSource={stateTab}
                       rowKey="id"
                       loading={loading}
                       pagination={pagination}
                       onRow={onRow}
                       size='small'
                       scroll={{x: 300, y: 'calc(100vh - 400px)' }}
                       tableLayout="auto"
                       title={tableHeader}
                       /*style={{width: '100%', height: '100vh'}}*/
                />

                {selectedRow && (

                    <Descriptions bordered
                                  size='small'
                                  style={{ position: 'absolute', left: 0, bottom: 0, width: '100%' }}
                                  column={{
                                      xxl: 4,
                                      xl: 3,
                                      lg: 3
                                  }}
                    >

                        <Descriptions.Item label="Даража">{selectedRow.deptlevel}</Descriptions.Item>
                        <Descriptions.Item label="Телефон ракам">{selectedRow.phonenumber||''}</Descriptions.Item>
                        <Descriptions.Item label="Яратди">{selectedRow.created_by+' - '+selectedRow.created_by_name||''}</Descriptions.Item>
                        <Descriptions.Item label="Яратилган сана">{selectedRow.created_date||''}</Descriptions.Item>

                        <Descriptions.Item label="Тузилма">{selectedRow.struct_name||''}</Descriptions.Item>
                        <Descriptions.Item label="Мобил тел. раками">{selectedRow.mobilenumber||''}</Descriptions.Item>
                        <Descriptions.Item label="Тахрирлади">{selectedRow.modified_by+' - '+selectedRow.modified_by_name||''}</Descriptions.Item>
                        <Descriptions.Item label="Тахрирланган сана">{selectedRow.modified_date||''}</Descriptions.Item>

                        <Descriptions.Item label="Филиал">{selectedRow.filial+' - '+selectedRow.filial_name||''}</Descriptions.Item>
                        <Descriptions.Item label="Тафсилотлар">{selectedRow.reason||''}</Descriptions.Item>

                    </Descriptions>

                )}
                <EmployeeHistoryTab currentState={currentState} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}/>
            </div>
        </Layout>
    );
};

export default EmployeeTab;