import {Layout, Modal, Table} from "antd";
import CashAppHistoryDetails from "../CashApp/CashAppHistoryDetails";
import useEmployeeHistoryTab from "./useEmployeeHistoryTab";
import EmployeeHistoryDetails from "./EmployeeHistoryDetails";
import {useTheme} from "../../context/ThemeContext";

const EmployeeHistoryTab = ({isModalOpen,handleOk,handleCancel,currentState}) => {
    const { isDarkMode, toggleDarkMode } = useTheme();

    const {contextHolder, smetaData, rowSelection, loading, pagination, onRow, tableColumns, innerIsModalOpen,  handleInnerOk, handleInnerCancel} = useEmployeeHistoryTab(currentState);
    return (
        <>
            <Modal width={1200} height={900} title="Протокол" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Layout style={{height: "100%", overflow: "hidden"}}>
                    {contextHolder}
                    <div style={{position: 'relative', height: '100%'}}>
                        <Table columns={tableColumns}
                               dataSource={smetaData}
                               className={isDarkMode ? "table-striped-rows darkTheme" : "table-striped-rows"}
                               rowKey="id"
                               rowSelection={rowSelection}
                               loading={loading}
                               size='small'
                               scroll={{x: 300, y: 'calc(100vh - 350px)'}}
                               tableLayout="auto"
                               pagination={pagination}
                               onRow={onRow}
                        />

                    </div>
                </Layout>
            </Modal>
            <Modal width={1200} height={900} title="Ўзгартмалар параметрлари" open={innerIsModalOpen} onOk={handleInnerOk} onCancel={handleInnerCancel}>
                <EmployeeHistoryDetails />
            </Modal>
        </>
    );
};

export default EmployeeHistoryTab;