import {Form, Input, Button, Alert, Modal, Card, Descriptions, Space, Divider, message, Table} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import axios from "../../api/axios";
import {AuthContext} from "../../context/AuthContext";
import EIMZO from "../../libs/eimzo/Eimzo";
import {AppLoad} from "../../libs/eimzo/e-imzo-init";
import {ArrowRightOutlined, PlusOutlined} from "@ant-design/icons";
import styled from "styled-components";
import NumberInput from "../../components/NumberInput";
import isHaveEmpCert from "../../api/controls/isHaveEmpCert";
import { notification } from 'antd';

const { Column } = Table;

const USER_ECP_URL = 'asdasda/ada'

const CustomSpace = styled(Space)`
      width: 80%;
      height: 68vh;
    `;

const EmpCertAdd = ({ empId }) => {

    const [form] = Form.useForm();
    const auth = useContext(AuthContext);

    const [messageApi, contextHolder] = message.useMessage();

    const [error, setError] = useState(null);
    const [openEcpDialog, setOpenEcpDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const [certKeys, setCertKeys] = useState(null);
    const [selectedCert, setSelectedCert] = useState(null);
    const [existCert, setExistCert] = useState([]);
    const [IsExistCert, setIsExistCert] = useState(false);

    const EIMZOClient = new EIMZO();

    const handleEcpDialog = async () => {
        try {
            const certs = await EIMZOClient.install();
            console.log(certs,'certs')
            const data = await EIMZOClient.listAllUserKeys();

            const currentDate = new Date();
            data.map(el => {
                el.validTo > currentDate ? el.activeCert = true :  el.activeCert = false
            })
            setCertKeys(data);
            setOpenEcpDialog(true);

        } catch (err) {
            console.error("Error fetching data from EIMZO:", err);
            if (err === 1006) {
                console.log('sdsds');
                notification.warning({
                    message: 'Компютерингизда E-IMZO дастурини ишга туширинг',
                    duration: 5,
                });
            }
            // Handle error fetching data from EIMZO
        }
    };

    useEffect(() => {

        const fetchExistingCerts = async () => {
            const data = await isHaveEmpCert({empId:empId})
            data.length > 0 ? setIsExistCert(true): setIsExistCert(false)
            setExistCert(data)
        }

        fetchExistingCerts()

    }, []);



    /*useEffect(() => {
        const listAllKeys = async () => {
            const certs = await EIMZOClient.install();
            const data = await EIMZOClient.listAllUserKeys();
            console.log(data,'lololo')
            console.log(data[0].CN,'data.CN')
            setCertKeys(data)
            setCertificates(data);
        };

        listAllKeys();

        console.log("certificates", certificates);
    }, []);*/

    const handleCardClick = (sn) => {
        setSelectedCert(sn);

        const certInfo = certKeys.find(el => el.serialNumber === sn)
        addCertToForm(certInfo)
    };

    const signCert = async (selectedCert) => {
        try {
            const result = await EIMZOClient.loadKey(selectedCert);
            console.log(result);
            const signature = result.id
            const tin = selectedCert.TIN
            const pinfl = selectedCert.PINFL
            const serialNumber = selectedCert.serialNumber

            const result1 = await EIMZOClient.signPkcs7(selectedCert,'login');
            console.log('Result:', result1);
            let pkcs7_64 = result1.pkcs7_64
            const signatureHex = result1.signature_hex

            try {
                const { data } = await axios.post('https://api.didox.uz/v1/dsvs/timestamp',

                    {
                        "pkcs7": pkcs7_64, // Значение поля pkcs7_64
                        "signatureHex": signatureHex // Значение поля signature_hex
                    },
                    {
                        //headers: headers,
                        crossDomain: true,
                        withCredentials: false
                    }
                )

                if (data.success === true)  pkcs7_64 = data.timeStampTokenB64 //S TIMESTAMP
                else {
                    alert('Есть ощибки с E-imzo')
                    return false
                }

            } catch (e) {
                alert('Есть ощибки с E-imzo')
                console.log(e)
            }

            return {
                signature,
                tin,
                pinfl,
                serialNumber,
                pkcs7_64,
                signatureHex
            }



            //const pkcs7 = await EIMZOClient.createPkcs7(result.id, 'login', true );
            //console.log(pkcs7,'kokokok');

            // Handle the result here
        } catch (error) {
            console.error(error);
            // Handle the error here
        }
    }

    const onFinish = values => {
        setLoading(true);
        const { INN, PINFL, SERIALNUMBER } = values;
        if (!INN && !PINFL) {
          setError("ИНН еки ЖШШИР талаб қилинади!");
          setLoading(false);
          return;
        }



        return
        /*
        axios.post('/api/saveDetails', {
          userId: userId,
          INN,
          PINFL,
          SERIALNUMBER
        })
        .then(res => {
          console.log(res);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });

         */
    };

    const headers = {'Content-Type':'application/json;charset=utf-8',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Credentials':'true',
        'Authorization':`Bearer ${auth.token}`,
        'withCredentials': true
    }

    const handleOk = () => {

        if (!selectedCert) {
            messageApi.open({
                type: 'warning',
                content: "ЭРИ танланмаган",
                duration: 5,
            });
        }

        form
            .validateFields()
            .then(async (values) => {

                const certInfo = certKeys.find(el => el.serialNumber === selectedCert)

                signCert(certInfo).then(async (res) => {

                    console.log(res, 'lololo');

                    setLoading(true);
                    try {
                        const data = await axios.post('/e_imzo/signature/save',

                            JSON.stringify(res),
                            {
                                headers: headers,
                                crossDomain: true,
                                withCredentials: false
                            }
                            // )
                        )

                        messageApi.open({
                            type: 'success',
                            content: 'Муввафакият',
                            duration: 2,
                        }).then(()=> null);
                        setLoading(false);
                        formReset()
                    } catch (err) {
                        messageApi.open({
                            type: 'error',
                            content: err.response?.data?.message || err,
                            duration: 5,
                        });
                        setLoading(false);
                    }

                }).catch(err => {
                    console.error(err);

                });

            })
            .catch((errorInfo, values) => {
                console.log('errorInfo ...', errorInfo);
                console.log('errorInfo ...', errorInfo.values);
            }).then(r => r);
    }

    const addCertToForm = (certInfo) => {
        form.setFieldsValue({inn: certInfo.TIN, pinfl: certInfo.PINFL, serialNumber: certInfo.serialNumber})
    }

    const formReset =  () => {
        form.resetFields();
        setSelectedCert(null);
        setOpenEcpDialog(false)
    }

    /*const handleEcpDialog = () => {
        setOpenEcpDialog(true)
    }*/

    const DateFormat = (val) => {
        let yyyy = val.getFullYear().toString();
        let mm = (val.getMonth() + 1).toString(); // getMonth() is zero-based
        let dd = val.getDate().toString();
        return (dd[1] ? dd : "0" + dd[0]) + "." + (mm[1] ? mm : "0" + mm[0]) + "." + yyyy; // padding
    }

    const formItemLayout =
         {
            labelCol: {
                span: 6,
            },
            wrapperCol: {
                span: 18,
            },
        }

    return (
        <>
            {/*<Button
                onClick={handleEcpDialog}
                type="primary"
            >
                ЭРИ боглаш
            </Button>*/}

            <Button type="dashed" block icon={<PlusOutlined/>}
                    onClick={handleEcpDialog}
            >
                ЭРИ қўшиш
            </Button>

            <Divider style={{textAlign:"left"}}>Фойдаланувчига боғланганлар ЭРИ лар</Divider>

            {IsExistCert ?
                 <>
                     <Table dataSource={existCert}>
                         <Column title="ИНН" dataIndex="tin" key="tin" />
                         <Column title="ПИНФЛ" dataIndex="pinfl" key="pinfl" />
                         <Column title="Серия рақами" dataIndex="serial_number" key="serial_number" />
                         {/*<Column
                             title=""
                             key="action"
                             render={(_, record) => (
                                 <Space size="middle">
                                     <a>Ўчириш</a>
                                 </Space>
                             )}
                         />*/}
                     </Table>

                 </>
                    :
                <>
                    <Table dataSource={[]}>
                        <Column title="ИНН" dataIndex="tin" key="tin" />
                        <Column title="ПИНФЛ" dataIndex="pinfl" key="pinfl" />
                        <Column title="Серия рақами" dataIndex="serial_number" key="serial_number" />

                    </Table>

                </>
                 /*<Button type="dashed" block icon={<PlusOutlined/>}
                         onClick={handleEcpDialog}
                 >
                     ЭРИ қўшиш
                 </Button>*/

            }




            {
                openEcpDialog &&

            <Modal
                title="Электрон рақамли имзони боғлаш"
                width={840}
                open={openEcpDialog}
                //onOk={handleAction}
                //confirmLoading={confirmLoading}
                onCancel={() => setOpenEcpDialog(false)}
                footer={[]}
                /*footer={[
                    <Button key="back" onClick={() => setOpenEcpDialog(false)}>
                        Бекор қилиш
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk} loading={loading} >
                        Сақлаш
                    </Button>
                ]}*/
            >
                <>
                    {contextHolder}
                    {error && <Alert message={error} type="error" showIcon />}

                    <Space>
                        <Button key="submit" type="primary" onClick={handleOk} loading={loading} >
                            Сақлаш
                        </Button>
                        <Button key="back" onClick={() => formReset()}>
                            Бекор қилиш
                        </Button>
                    </Space>

                    <Divider type="horizontal" ></Divider>

                    <Space direction="horizontal" align="start" size={20}>

                        <Space direction="vertical" >

                            <Card title="Танланган ЭРИ"
                                  size="small"
                                  type="inner"
                                  style={{width:'240px'}}
                            >

                                <Space direction="vertical">

                                    <Form form={form} onFinish={onFinish} layout="vertical">
                                        <Form.Item
                                            name='inn'
                                            label="ИНН"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                      if (!value && !getFieldValue('pinfl')) {
                                                          return Promise.reject(new Error('ИНН ни киритинг!'));
                                                      }
                                                      return Promise.resolve();
                                                    },
                                                }),
                                                //{ type: 'number', message: 'ИНН сон бўлиши керак' },
                                            ]}
                                        >
                                          <NumberInput readOnly={true} placeholder="ИНН" pattern="\d*" showCount maxLength={9}/>
                                        </Form.Item>
                                        <Form.Item
                                          name='pinfl'
                                          label="ЖШШИР"
                                          rules={[
                                              ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                      if (!value && !getFieldValue('inn')) {
                                                          return Promise.reject(new Error('ЖШШИР ни киринтинг!'));
                                                      }
                                                      return Promise.resolve();
                                                  },
                                              }),
                                              //{ type: 'number', message: 'ЖШШИР сон бўлиши керак' },
                                          ]}
                                        >
                                          <NumberInput readOnly={true} placeholder="ЖШШИР" pattern="\d*" showCount maxLength={14}/>
                                        </Form.Item>
                                        <Form.Item
                                          name='serialNumber'
                                          label="Серия рақами"
                                          rules={[
                                              { required: true, message: 'Серия рақамини киритинг!' },
                                              //{ type: 'number', message: 'Серия рақами сон бўлиши керак' },
                                          ]}
                                        >
                                          <Input readOnly={true} placeholder="Серия рақами" pattern="\d*" />
                                        </Form.Item>

                                    </Form>

                                </Space>

                            </Card>
                        </Space>




                        <CustomSpace direction="vertical" size={16} style={{width:'500px', overflowX:'hidden', overflowY:'auto'}}>
                            {/*<Divider orientation="center">Мавжуд электрон рақамли имзолар</Divider>*/}
                            <Card title="Мавжуд электрон рақамли имзолар"
                                  type="inner"
                                  style={{width:'500px'}}
                            >
                                {
                                    certKeys && certKeys.map((cert, index) => (
                                            <>
                                                <Card title={cert.CN}
                                                      size="small"
                                                      style={{
                                                          marginBottom: 16,
                                                          width: '100%',
                                                          //border: selectedCert === cert.serialNumber ? '2px solid #1890ff' : '1px solid #d9d9d9', // Change border style based on isSelected
                                                          border: cert.activeCert ? (selectedCert === cert.serialNumber ? '2px solid #1890ff' : '1px solid #d9d9d9') :  '2px solid red',
                                                          pointerEvents: cert.activeCert ? 'auto' : 'none', // Disable clicking if the date is expired
                                                          cursor: cert.activeCert ? 'pointer' : 'notAllowed', // Disable clicking if the date is expired
                                                      }}
                                                      onClick={() => handleCardClick(cert.serialNumber)}
                                                      key={cert.serialNumber}
                                                      hoverable
                                                      /*extra={
                                                          <Button type="primary" onClick={() => addCertToForm(cert)}  size="small">
                                                              <PlusOutlined />
                                                          </Button>
                                                      }*/
                                                     // onSelect={() => console.log('1')}
                                                      /*extra={
                                                          <Button type="primary" onClick={() => console.log(cert)} className="btn-grad login-form-button" size="medium">
                                                              Кириш
                                                              {/!*                            <LoginOutlined />*!/}
                                                              <ArrowRightOutlined />
                                                          </Button>
                                                      }*/
                                                >

                                                    <Descriptions layout="horizontal"
                                                                  column={{
                                                                      xxl: 2,
                                                                      xl: 2,
                                                                      lg: 2
                                                                  }}
                                                    >

                                                        <>
                                                            <Descriptions.Item label="ИНН" key={cert.TIN}>
                                                                {cert.TIN}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="ПИНФЛ" key={cert.PINFL}>
                                                                {cert.PINFL}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="№ сертификата" key={cert.serialNumber}>
                                                                {cert.serialNumber}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Тип" key={cert.type}>
                                                                {cert.type === 'pfx' ? 'Физическое лицо' : 'Юридическое лицо'}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Срок действия сертификата" key={cert.validFrom}>
                                                                {DateFormat(cert.validFrom)} - {DateFormat(cert.validTo)}
                                                            </Descriptions.Item>
                                                        </>

                                                    </Descriptions>

                                                </Card>


                                            </>

                                        )
                                    )
                                }
                            </Card>
                        </CustomSpace>

                    </Space>
                </>
            </Modal>

            }
        </>
    );
};

export default EmpCertAdd;