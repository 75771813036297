import {
    LaptopOutlined, NotificationOutlined, UserOutlined,
    AreaChartOutlined,
    ContainerOutlined,
    ProjectOutlined,
    BankOutlined,
    FundOutlined, ApartmentOutlined, AuditOutlined
} from '@ant-design/icons';
import {Breadcrumb, Calendar, Col, Layout, Menu, Row, Radio, Button, Descriptions, Alert, message} from 'antd';
import React, {useState} from 'react';
import {useNavigate, Outlet} from "react-router-dom";
import './layout.css';
import {lvl1, lvl2} from './MenuData'
import {useEffect, useCallback,useContext } from "react";
import axios from "../api/axios";
import {useAuth} from "../hooks/auth.hook";

import {AuthContext} from "../context/AuthContext";
import {RecentlyComeMenusContext} from "../context/recentlyComeMenus/RecentlyComeMenusProvider";
import {ChartQueryContext} from "../context/chartQueryContext/ChartQueryContextProvider";
import DynamicIcon from "../components/DynamicIcon";

const { Header, Content, Sider } = Layout;
const MENU_URL = '/api/public/query';

//const items1 = ['1', '2', '3'].map((key) => ({
/*const items1 = lvl1.map((key) => ({
    id: key.id,
    label: key.name,
}));*/


function isDef(ev){
    if (ev!=undefined) return ev; else return [];
}
let keyMenu = 0;

export const LayoutBM = () => {
    const {handleChangeMenu} = useContext(RecentlyComeMenusContext);
    let navigate = useNavigate();
    //const handleMenuClick = useCallback((args) => navigate('employeeTab', {replace: true}), [navigate]);
    const {handleMenuId} = useContext(ChartQueryContext);
    const [menu, setMenu] = useState('');
    const [stateTab, setStateTab] = useState([]);
    const [topMenu, setTopMenu] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [menus, setMenus] = useState([]);

    const auth = useContext(AuthContext);
    const {empId, empName} = useAuth();

    useEffect(() => {
        if (empId) {
            getDataMenu();
        }
    }, [empId]);

    const getDataMenu = async () => {
        try {

            const headers = {'Content-Type':'application/json;charset=utf-8',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Credentials':'true',
                'withCredentials': true
            }

            const response = await axios.post(MENU_URL,
                JSON.stringify({
                    "query": {
                        "id": "MENU_SSP_HIERARCHY_V",
                        "source": "MENU_SSP_HIERARCHY_V",
                        "fields": [
                            {"column": "id"},
                            {"column": "parent_id"},
                            {"column": "name"},
                            {"column": "url"},
                            {"column": "icon"}
                        ],
                        "filters": [
                            {
                                "column": "emp",
                                "value": empId,
                                "operator": "=",
                                "dataType": "number"
                            },
                        ]
                    }
                }),
                {
                    headers: headers,
                    crossDomain: true,
                    withCredentials: false
                });
            setStateTab(response?.data.filter(row => (row.parent_id !== null)).map(row => ({
                menuId: row.id,
                parentId: row.parent_id,
                name: row.name,
                url: row.url,
            })));

            setTopMenu( response?.data.filter(row => (row.parent_id === null)) )

        } catch (err) {
            if (!err?.response) {
                message.error(err.response?.data);
            } else if (err.response?.status === 400) {
                message.error(err.response?.data ||'INTERNAL');
            } else if (err.response?.status === 401) {
                message.error(err.response?.data ||'UNAUTHORIZED');
            } else if (err.response?.status === 404) {
                message.error(err.response?.data ||'NOT FOUND');
            } else {
                message.error(err.response?.data ||'OTHERS');
            }
        }

    }

    const items2 = topMenu.map((elem, index) => {
        const key = String(index);
        const parentName = elem.name;
        const childMenus = stateTab.filter(obj => obj.parentId === elem.id)
        return {
            key: `sub${elem.id}`,
            icon: <DynamicIcon iconName={elem.icon} />,
            label: parentName,
            size: 'lg',
            children: isDef(childMenus).map((_, j) => {
                return {
                    key: _.menuId,
                    url: _.url,
                    label: _.name,
                    menuid: _.menuId,
                    menuparent: _.parentId,
                };
            }),
        };
    });

    function handleOpenMenu(ev){
        const eventUrl = ev.item.props.url;
        const openedMenu = stateTab.find(menu => eventUrl === menu.url);
        handleChangeMenu(openedMenu);
        handleMenuId(openedMenu.menuId);
        setCollapsed(true);
        eventUrl.substring(0,3)!=='tmp'?navigate(eventUrl):message.warning('Ривожланишда')
        setMenu(eventUrl);
    }

    return (
        <Layout style={{width:'100%', height:'100%', overflow:"hidden" }}>
            <Layout style={{height:'100%'}}>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value) } width={300} className="site-layout-background">

                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[]}
                        defaultOpenKeys={[]}
                        style={{
                            height: '100%',
                            borderRight: 0,
                            overflowY: 'auto'
                        }}
                        onClick={handleOpenMenu}
                        items={items2}

                    />
                </Sider>
                <Layout
                    style={{
                        padding: '0 24px 24px'
                    }}
                >

                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 0,
                            margin: 0,
                            minHeight:'100%',
                            overflow: 'auto'
                        }}
                    >

                        <Outlet/>

                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
};



