import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {DOC_ACTION_REF} from "../../../types/extraSelectingData/action/action";
import {setActionData, setActionError,setActionLoading} from "../../../slices/extraSelectingData/action/action";


export function* sendActionSaga({actionData}) {
    try {
        yield put(setActionLoading(true));
        const result = yield call(makeQuery, actionData);
        yield put(setActionData(result));
        yield put(setActionLoading(false));
        yield put(setActionError({}));
    } catch (e) {
        yield put(setActionError(e.response.data.error));
        yield put(setActionLoading(false));
    }
}


export function* watchAction() {
    yield takeEvery(DOC_ACTION_REF, sendActionSaga);
}
