import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0
};
const accountTab = createSlice({
    name: 'accountTab',
    initialState,
    reducers: {
        setAccountTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setAccountTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setAccountTabError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setAccountTabData,setAccountTabLoading,setAccountTabError} = accountTab.actions;
export default accountTab.reducer;
