import {Button, Card, Descriptions, Input, Layout, Space, Spin} from 'antd';
import {useCallback, useContext, useEffect, useState} from "react";
import {AppLoad} from "../../libs/eimzo/e-imzo-init";
import EIMZO from "../../libs/eimzo/Eimzo";
import styled from "styled-components";
import {ArrowRightOutlined, LoginOutlined, UserOutlined} from "@ant-design/icons";
import axios from "../../api/axios";
import getFilials from "../../api/refs/filials";
import {useAuth} from "../../hooks/auth.hook";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";


const CustomSpace = styled(Space)`
      width: 100%;
      height: 68vh;
      overflow-y: auto;
      display: flex;
      align-items: center;
      padding: 15px;
    `;
const CertsEcp = () => {

    let navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/layout', {replace: true}), [navigate]);

    const auth = useContext(AuthContext);
    const { login } = useAuth();

    const [spinning, setSpinning] = useState(false);
    const [certificates, setCertificates] = useState(null);
    const [certKeys, setCertKeys] = useState(null);
    const [selectedCert, setSelectedCert] = useState(null);

    const EIMZOClient = new EIMZO();

    useEffect(() => {
        try {
        const listAllKeys = async () => {
            const certs = await EIMZOClient.install();
                    console.log(certs,'certsloasd')
            const data = await EIMZOClient.listAllUserKeys();
            const currentDate = new Date();
            data.map(el => {
                el.validTo > currentDate ? el.activeCert = true :  el.activeCert = false
            })
            setCertKeys(data)
            setCertificates(data);

            }
        listAllKeys();
        AppLoad()

            console.log("certificates", certificates)
        } catch (e) {
            alert(e)
        }

    }, []);

    const DateFormat = (val) => {
        let yyyy = val.getFullYear().toString();
        let mm = (val.getMonth() + 1).toString(); // getMonth() is zero-based
        let dd = val.getDate().toString();
        return (dd[1] ? dd : "0" + dd[0]) + "." + (mm[1] ? mm : "0" + mm[0]) + "." + yyyy; // padding
    }

    const loginViaCert = async (selectedCert) => {
        const result = await EIMZOClient.loadKey(selectedCert);
        //const result1 = await EIMZOClient.signPkcs7(selectedCert,selectedCert.TIN);
        let isError = false;
        let signature= ''
        const result4 = await EIMZOClient.createPkcs7(result.id, selectedCert.TIN, false)
            .then(pkcs7 => {
                signature = pkcs7
            })
            .catch(error => {
                isError = true
                console.error("Failed to create PKCS7555:", error);
            });

        console.log(result4,'result4')
        if (!isError) {
            try {
                setSpinning(true);
                const {data, headers, status} = await axios.post(`/user/loginByKey`,
                    {
                        "signature_hex": '',
                        "login": selectedCert.TIN,
                        "password": "",
                        "signature": signature
                    },
                    {

                        crossDomain: true,
                        withCredentials: false
                    }
                )

                if (status === 200) {
                    const accessToken = headers?.jwt;

                    const empId = data?.id;
                    const empName = data?.fio;
                    const empFilial = data?.filial;
                    const empInn = selectedCert.TIN;
                    const user = data?.login;

                    const dataFilial = await getFilials();
                    const filial = dataFilial.find(el => {
                        return el.code === empFilial
                    })
                    console.log(empInn,'popqweqwe')
                    const filialName = filial?.name
                    auth.login(accessToken, user, empId, empName, empFilial, filialName, empInn)
                    login(accessToken, user, empId, empName, empFilial, filialName, empInn)
                    handleOnClick();
                    setSpinning(false);
                }
            } catch (e) {
                if (e?.response?.status === 500) alert('Ощибка: ' + e?.response?.data?.message)
                else {
                    alert('Есть ощибки с E-imzo')
                    console.log(e)
                }
            } finally {
                setSpinning(false);
            }
        }
    }


//     const signCert = async (selectedCert) => {
//             try {
//                 const result = await EIMZOClient.loadKey(selectedCert);
//                 console.log(result);
//                 console.log(selectedCert,'selectedCert');
//
//                 const result1 = await EIMZOClient.signPkcs7(selectedCert,selectedCert.TIN);
//                 console.log('Result:', result1);
//
//                 let tokenbb= ''
//                 const result4 = await EIMZOClient.createPkcs7(result.id, selectedCert.TIN, false)
//                     .then(pkcs7 => {
//                         console.log('Result 3:', pkcs7);
//                         tokenbb = pkcs7
//                     })
//                     .catch(error => {
//                         console.error("Failed to create PKCS7555:", error);
//                     });
//                 console.log(result4,'result 4')
//
//
//                 try {
//                     const { data, headers} = await axios.post(`/user/loginByKey`,
//                         {
//                             "signature_hex": result1.signature_hex,
//                             "login": selectedCert.TIN,
//                             "password": "",
//                             "signature": tokenbb
//                         },
//                     {
//                             //headers: headers,
//                             crossDomain: true,
//                             withCredentials: false
//                         }
//                     )
//                     console.log(data, 'dataujjuju')
//                     console.log(headers, 'dataujjujuheaders')
//                     /*if (data.success === true)  pkcs7_64 = data.timeStampTokenB64 //S TIMESTAMP
//                     else {
//                         alert('Есть ощибки с E-imzo')
//                         return false
//                     }*/
//
//                 } catch (e) {
//                     if (e?.response?.status === 500) alert('Ощибка: ' + e?.response?.data?.message)
//                     else {
//                         alert('Есть ощибки с E-imzo')
//                         console.log(e)
//                     }
//
//
//                 }
//
//                 return false;
//
//
//
//                 const result2 = await EIMZOClient.getTimestampToken(result1.signature_hex);
//                 console.log('Result 2:', result2);
//
//                 //EIMZOClient.getTimestampToken
//                 /*const tt = await EIMZOClient.createPkcs7(result.id, 'login', false)
//                     .then(timestampToken => {
//                         console.log("Successfully retrieved timestamp token:", timestampToken);
//                     })
//                     .catch(error => {
//                         console.error("Failed to get timestamp token:", error);
//                     });
//                 console.log(tt,'tt')*/
//
//                 const timestampWrapper = async (signature, callback) => {
//                     try {
//                         const timestampToken = await EIMZOClient.getTimestampToken(signature);
//                         callback(timestampToken); // Pass the timestampToken to the callback
//                     } catch (error) {
//                         console.error("Failed to get timestamp token:", error);
//                     }
//                 };
//
// // Call createPkcs7 with the timestampWrapper function
//                 const result3 = await EIMZOClient.createPkcs7(result.id, 'login', timestampWrapper)
//                     .then(pkcs7 => {
//                         console.log('Result 3:', pkcs7);
//                     })
//                     .catch(error => {
//                         console.error("Failed to create PKCS7555:", error);
//                     });
//
//
//                 /*EIMZOClient.getTimestampToken(result1.signature_hex)
//                     .then(timestampToken => {
//                         console.log("Successfully retrieved timestamp token:", timestampToken);
//                     })
//                     .catch(error => {
//                         console.error("Failed to get timestamp token:", error);
//                     });*/
//
//                 /*const result3 = await EIMZOClient.createPkcs7(result.id,'login', timestampWrapper);
//                 console.log('Result 3:', result3);*/
//
//                 //const pkcs7 = await EIMZOClient.createPkcs7(result.id, 'login', true );
//                 //console.log(pkcs7);
//
//                 // Handle the result here
//             } catch (error) {
//                 console.error(error);
//                 // Handle the error here
//             }
//     }

    return (
        <>
            <Spin spinning={spinning} fullscreen tip="Подключение к E-IMZO" size="large"/>
        <CustomSpace direction="vertical" size={16}>
            {
            certKeys && certKeys.map((cert, index) => (
            <>
                <Card title={cert.CN}
                          key={`${cert.serialNumber}-${cert.TIN}`}
                      size="small"
                          style={{
                              marginBottom: 16,
                              width: '100%',
                              //border: selectedCert === cert.serialNumber ? '2px solid #1890ff' : '1px solid #d9d9d9', // Change border style based on isSelected
                              border: cert.activeCert ? (selectedCert === cert.serialNumber ? '2px solid #1890ff' : '1px solid #d9d9d9') :  '2px solid red',
                              pointerEvents: cert.activeCert ? 'auto' : 'none',
                              cursor: cert.activeCert ? 'pointer' : 'notAllowed',
                              filter: cert.activeCert ? '' : 'blur(1px)',
                              opacity: cert.activeCert ? '1' : '0.9',
                              backgroundColor: cert.activeCert ? '' : 'rgba(241,241,241,0.5)'
                          }}
                      extra={
                              cert.activeCert &&

                            <Button type="default" onClick={() => loginViaCert(cert)}  size="medium" key={index+'btn'}>
                                {/*<Button type="primary" onClick={() => loginViaCert(cert)} className="btn-grad login-form-button" size="medium">*/}
                          Кириш
{/*                            <LoginOutlined />*/}
                            <ArrowRightOutlined />
                        </Button>
                }
                >

                    <Descriptions layout="horizontal"
                                      key={index+'desc'}
                                  column={{
                                      xxl: 2,
                                      xl: 2,
                                      lg: 2
                                  }}
                    >

                            <>
                                    <Descriptions.Item label="ИНН" key={`inn_${cert.TIN}`}>
                                    {cert.TIN}
                                </Descriptions.Item>
                                    <Descriptions.Item label="ПИНФЛ" key={`pinfl_${cert.PINFL}`}>
                                    {cert.PINFL}
                                </Descriptions.Item>
                                    <Descriptions.Item label="№ сертификата" key={`snum_${cert.serialNumber}`}>
                                  {cert.serialNumber}
                                </Descriptions.Item>
                                    <Descriptions.Item label="Тип" key={`type_${cert.type}`}>
                                  {cert.type === 'pfx' ? 'Физическое лицо' : 'Юридическое лицо'}
                                </Descriptions.Item>
                                    <Descriptions.Item label="Срок действия сертификата" key={`validFrom_${cert.validFrom}${index}`}>
                                  {DateFormat(cert.validFrom)} - {DateFormat(cert.validTo)}
                                </Descriptions.Item>
                            </>

                    </Descriptions>

                </Card>


                </>

                )
            )
        }
        </CustomSpace>
        </>

    )
}

export default CertsEcp


