import {takeEvery, put, call} from 'redux-saga/effects';
import {
    DIDOX_DOCUMENTS_BY_EMP_ID
} from "../../../types/document/didox/didoxDocuments";
import {
    setDidoxDocumentsByEmpIdTabData,
    setDidoxDocumentsByEmpIdTabError,
    setDidoxDocumentsByEmpIdTabLoading
} from "../../../slices/document/didox/didoxDocumentsByEmpId";
import {makeQueryNew, makeQueryPageable} from "../../../apis";


export function* sendDidoxDocumentsByEmpIdSaga({data, params}) {
    try {
        yield put(setDidoxDocumentsByEmpIdTabLoading(true));
        const result = yield call(makeQueryNew, data, params);
        //const result = yield call(makeQueryPageable, data, params);
        yield put(setDidoxDocumentsByEmpIdTabData(result));
        yield put(setDidoxDocumentsByEmpIdTabLoading(false));
        yield put(setDidoxDocumentsByEmpIdTabError({}));
    } catch (e) {
        yield put(setDidoxDocumentsByEmpIdTabError(e.response.data.error));
        yield put(setDidoxDocumentsByEmpIdTabLoading(false));
    }
}


export function* watchDidoxDocumentsByEmpId() {
    yield takeEvery(DIDOX_DOCUMENTS_BY_EMP_ID, sendDidoxDocumentsByEmpIdSaga);
}