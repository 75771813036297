import React, {createContext, useContext, useEffect, useState} from 'react';
import {useAuth} from "../hooks/auth.hook";
import {AuthContext} from "./AuthContext";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const auth = useContext(AuthContext);

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    const formattedCurrentDate = `${day}.${month}.${year}`;

    //console.log(auth.empFilial,'auth.empFilial')
    /*const defFilter = auth.empFilial === "000" ?
      {"paydocTabFilter": [
              {
                  column: 'filial',
                  operator: '=',
                  value: '000',
                  dataType: 'text',
              },
          ],
       "paydocAccFilter": [
              {
                  column: 'filial',
                  operator: '=',
                  value: '000',
                  dataType: 'text',
              },
          ]
      } : {}*/

    const [filterValues, setFilterValues] = useState({});

    useEffect(() => {
        // Update default filter values based on the condition
        const defFilter = auth.empFilial === "000" ?
            {
                "paydocTabFilter": [
                    {
                        column: 'filial',
                        operator: 'in',
                        value: ['000'],
                        dataType: 'text',
                    },
                ],
                "paydocAccFilter": [
                    {
                        column: 'filial',
                        operator: 'in',
                        value: ['000'],
                        dataType: 'text',
                    },
                ],
                "bankAccSaldoPeriodFilters": [
                    {
                        "column": "date_saldo",
                        "operator": "range",
                        "value": formattedCurrentDate,
                        "value2": formattedCurrentDate,
                        "dataType": "date"
                    },
                ],/*
                "contractTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],*/
                "cashAppTabFilter": [
                    {
                        "column": "fin_year",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                "smetaExecuteTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                "contractExecuteTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                "cashAppExecuteTabFilter": [
                    {
                        "column": "fin_year",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                /*"smetaTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ]*/
            } : {
                "bankAccSaldoPeriodFilters": [
                    {
                        "column": "date_saldo",
                        "operator": "range",
                        "value": "26.12.2023",
                        "value2": "26.12.2023",
                        "dataType": "date"
                    },
                ],
                /*"contractTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],*/
                "cashAppTabFilter": [
                    {
                        "column": "fin_year",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                "smetaExecuteTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                "contractExecuteTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                "cashAppExecuteTabFilter": [
                    {
                        "column": "fin_year",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ],
                /*"smetaTabFilter": [
                    {
                        "column": "finyear",
                        "operator": "=",
                        "value": year,
                        "dataType": "number"
                    }
                ]*/
            };

        // Set the default filter values
        setFilterValues(defFilter);
    }, [auth.empFilial]);

    const updateFilterValues = (newFilterValues) => {
        setFilterValues((prevFilterValues) => ({
            ...filterValues,
            ...prevFilterValues,
            ...newFilterValues,

        }));
    };


    return (
        <FilterContext.Provider value={{ filterValues, updateFilterValues }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilter = () => {
    return useContext(FilterContext);
};