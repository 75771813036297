import React, {useState, useContext, useEffect} from 'react'
import { render } from 'react-dom'
import {DatePicker, Layout, message, ConfigProvider, theme} from 'antd'
import logo from './logo.svg'
import 'antd/dist/reset.css'
import './i18n';

import './index.css'

import NavigationBar from "./components/NavigationBar";
import {useRoutes} from "./routes/routes"
import {useAuth} from "./hooks/auth.hook"
import {AuthContext} from "./context/AuthContext";

import {BrowserRouter as Router, useNavigate} from 'react-router-dom';

import locale from "antd/es/locale/ru_RU";
import dayjs from "dayjs";
import 'dayjs/locale/uz';
import ChartQueryContextProvider from "./context/chartQueryContext/ChartQueryContextProvider";
import RecentlyComeMenusProvider from "./context/recentlyComeMenus/RecentlyComeMenusProvider";
import {FilterProvider} from "./context/FilterContext";
import {useTheme} from "./context/ThemeContext";

const AppRoutes = ({ routes, logout }) => {

    // Получим функцию навигации из React Router
    // Добавим состояние для отслеживания времени последней активности пользователя
    const [lastActivityTime, setLastActivityTime] = useState(new Date());

    // Функция для обновления времени последней активности
    const updateLastActivityTime = () => {
        setLastActivityTime(new Date());
    };

    // Получим функцию навигации из React Router
    const navigate = useNavigate();

    const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds
    const oneMinute = 60 * 1000; // 1 minutes in milliseconds
    //const fiveMinutes = 6000; // 5 minutes in milliseconds
    //const oneMinute = 6000; // 1 minutes in milliseconds

    // Эффект для установки обработчика событий при монтировании компонента
    useEffect(() => {
        const handleUserActivity = () => {
            // Обновляем время последней активности при любой активности пользователя
            updateLastActivityTime();
        };

        // Добавляем слушатели событий для отслеживания активности пользователя
        document.addEventListener('mousemove', handleUserActivity);
        //document.addEventListener('click', handleUserActivity);
        document.addEventListener('keydown', handleUserActivity);

        // Функция для проверки времени бездействия и автоматического выхода
        const checkInactivity = async () => {

            const currentPageLocation = window.location.pathname;

            if (currentPageLocation !== '/') {
                const currentTime = new Date();
                const inactiveTime = currentTime - lastActivityTime;

                // Если время бездействия превышает 10 минут, выходим пользователя
                if (inactiveTime > fiveMinutes) {
                    await logout()
                    console.log('logout')
                    // Очищаем любые данные аутентификации
                    // Перенаправляем на главную страницу
                    navigate('/');

                }
            }
        };

        // Устанавливаем интервал проверки бездействия каждую минуту
        const inactivityInterval = setInterval(checkInactivity, oneMinute);

        // Очищаем слушатели событий и интервал при размонтировании компонента
        return () => {
            document.removeEventListener('mousemove', handleUserActivity);
            //document.removeEventListener('click', handleUserActivity);
            document.removeEventListener('keydown', handleUserActivity);
            clearInterval(inactivityInterval);
        };
    }, [lastActivityTime, logout, navigate]);

    // Your useEffect and other logic here!

    return (
        <Layout style={{width:'100%', height:'100%', backgroundColor:'#c6c6c6'}}>
            <div style={{width:'100%', height:'100%', backgroundColor:'#c6c6c6'}}>
                {routes}
            </div>
        </Layout>)
}

function App() {

    dayjs.locale('uz');

    window.onbeforeunload = (event) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = "";
    }
    return "";
    };

    const { isDarkMode} = useTheme();
    const { darkAlgorithm, defaultAlgorithm } = theme;

    const [titleNav, setTitleNav] = useState('Асосий ойна');
    const [date, setDate] = useState(null);
    const handleChange = value => {
        message.info(`Selected Date: ${value ? value.format('YYYY-MM-DD') : 'None'}`);
        setDate(value);
    };

    const {token, login, logout, userId, ready, empId, empFilial, empName, filialName, empInn} = useAuth();
    const isAuthenticated = !!token;
    const routes = useRoutes(isAuthenticated, setTitleNav);

    /*const navigate = useNavigate();

    useEffect(() => {
        // called when the component is unmounted
        const cleanup = () => {
            logout().then(() => {
                // clear any authentication data
                // redirect to index page
                navigate('/');
            });
        };

        // called when the user navigates away or closes the browser
        window.addEventListener('beforeunload', cleanup);

        return () => {
            window.removeEventListener('beforeunload', cleanup);
        };
    }, [navigate]);*/


    const customComponentColors = {
        Table: {
            //rowHoverBg: "#6bc735",
            rowSelectedBg: "#2b4464",
            rowSelectedHoverBg: "#1e3245"
        }
    }

  return (

      <Layout style={{width:'100vw', height:'100vh' }}>
        <AuthContext.Provider value={{
          token, login, logout, userId, isAuthenticated, empId, empFilial, empName, filialName, empInn
        }}>
          <ConfigProvider
              theme={
                //algorithm: theme.defaultAlgorithm,
                //algorithm: theme.darkAlgorithm,
                  {
                      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
                      token: {
                          "colorPrimary": "#069CDF",
                          "colorInfo": "#03859a",
                          "colorSuccess": "#59d31c",
                          "colorWarning": "#dcb057",
                          "colorError": "#ed6b21",
                          //"colorTextBase": "#050505",
                          "borderRadius": 6,
                          "wireframe": false,
                          //"colorBgBase": "#ffffff",
                          algorithm: "defaultAlgorithm"
                      },
                      components: isDarkMode
                          ? customComponentColors
                          : {
                                Table: {
                                    rowSelectedHoverBg: '#7ed7ff',
                                }
                          }

                  }
              }
              locale={locale}
          >
            <FilterProvider>
                  <ChartQueryContextProvider>
                      <RecentlyComeMenusProvider>
                <Router>
                  { isAuthenticated && ready && <NavigationBar titleNav={titleNav} style={{width:'100%', height:'100%', backgroundColor:'red'}} /> }

                                {/*<Layout style={{width:'100%', height:'100%', backgroundColor:'#c6c6c6'}}>

                    <div style={{width:'100%', height:'100%', backgroundColor:'#c6c6c6'}}>
                      {routes}
                    </div>

                              </Layout>*/}

                                <AppRoutes routes={routes} logout={logout}/>

                </Router>
                      </RecentlyComeMenusProvider>
                  </ChartQueryContextProvider>
            </FilterProvider>

          </ConfigProvider>

        </AuthContext.Provider>

      </Layout>

  );
}

export default App;
