import {combineReducers, configureStore} from "@reduxjs/toolkit";

import createSagaMiddleware from 'redux-saga';
import {rootSaga} from "./sagas";
import accountType from "./slices/references/accountType/accountType";
import bank from "./slices/references/bank/bank";
import bankType from "./slices/references/bankType/bankType";
import filial from "./slices/references/filial/filial";
import chart from "./slices/chart/chart";
import savedQueries from "./slices/chart/saved_queries";
import chartSave from "./slices/chart/chart_save";
import smetaTab from "./slices/smeta/smetaTab/smetaTab";
import accountTab from "./slices/account/accountTab/accountTab";
import accountStateTab from "./slices/accountState/accountStateTab/accountStateTab";
import smetaExecuteTab from "./slices/accountState/smetaExecuteTab/smetaExecuteTab";
import accSaldoPeriodTab from "./slices/accountState/accSaldoPeriodTab/accSaldoPeriodTab";
import bankAccExpensePeriod from "./slices/account/bankAccExpensePeriod/bankAccExpensePeriod";
import documentControlsTab from "./slices/document/documentControlsTab/documentControlsTab";
import action from "./slices/extraSelectingData/action/action";
import type from "./slices/extraSelectingData/type/type";
import control from "./slices/extraSelectingData/control/control";
import statsDesc from "./slices/references/statsDesc/statsDesc";
import didoxDocuments from "./slices/document/didox/didoxDocuments";
import smetaHistoryTab from "./slices/smeta/smetaHistory/smetaHistoryTab";
import didoxDocumentsByEmpId from "./slices/document/didox/didoxDocumentsByEmpId";
import refGoodMhikTab from "./slices/references/refGoodMhikTab/refGoodMhikTab";

const sagaMiddleware = createSagaMiddleware();
const reducer = combineReducers({
    accountType,
    bank,
    bankType,
    filial,
    chart,
    smetaTab,
    accountTab,
    accountStateTab,
    smetaExecuteTab,
    accSaldoPeriodTab,
    bankAccExpensePeriod,
    documentControlsTab,
    action,
    type,
    control,
    statsDesc,
    didoxDocuments,
    smetaHistoryTab,
    chartSave,
    savedQueries,
    didoxDocumentsByEmpId,
    refGoodMhikTab
});

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: false}).concat(sagaMiddleware)
});
sagaMiddleware.run(rootSaga);
