import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {setChartQuery} from "../../../slices/chart/chart";
import {ACC_SALDO_PERIOD_TAB} from "../../../types/accountState/accSaldoPeriodTab/accSaldoPeriodTab";
import {setAccSaldoPeriodTabLoading, setAccSaldoPeriodTabError, setAccSaldoPeriodTabData} from "../../../slices/accountState/accSaldoPeriodTab/accSaldoPeriodTab";

export function* sendAccSaldoPeriodTabSaga({dataAccSaldoPeriodTab}) {
    try {
        yield put(setAccSaldoPeriodTabLoading(true));
        const result = yield call(makeQuery, dataAccSaldoPeriodTab);
        yield put(setAccSaldoPeriodTabData(result));
        yield put(setChartQuery(dataAccSaldoPeriodTab));
        yield put(setAccSaldoPeriodTabLoading(false));
        yield put(setAccSaldoPeriodTabError({}));
    } catch (e) {
        yield put(setAccSaldoPeriodTabError(e.response.data.error));
        yield put(setAccSaldoPeriodTabLoading(false));
    }
}


export function* watchAccSaldoPeriodTab() {
    yield takeEvery(ACC_SALDO_PERIOD_TAB, sendAccSaldoPeriodTabSaga);
}