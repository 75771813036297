import React, {useState, createContext} from 'react';

export const ChartQueryContext = createContext({});

const ChartQueryContextProvider = ({children}) => {
    const [chartQuery, setChartQuery] = useState({});
    const [filterKey, setFilterKey] = useState('');
    const [menuId, setMenuId] = useState(0);
    const handleUpdateChartQuery = (chartQuery) => {
        setChartQuery(chartQuery);
    };
    const handleFilterKey = (filterKey) => {
        setFilterKey(filterKey);
    };
    const handleMenuId = (id) => {
        setMenuId(id);
    };
    return (
        <ChartQueryContext.Provider
            value={{chartQuery, handleUpdateChartQuery, filterKey, handleFilterKey, menuId, handleMenuId}}>
            {children}
        </ChartQueryContext.Provider>
    );
};

export default ChartQueryContextProvider;