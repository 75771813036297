import {takeEvery, put, call} from 'redux-saga/effects';
import {setChartSaveData, setChartSaveError, setChartSaveLoading} from "../../slices/chart/chart_save";
import {makeQuery} from "../../apis";
import {CHART_SAVE, CHART_SAVE_CLEAR} from "../../types/chart/chart_save";


export function* sendChartSaveSaga({data, url}) {
    try {
        yield put(setChartSaveLoading(true));
        const res = yield call(makeQuery, data, url);
        yield put(setChartSaveData(res));
        yield put(setChartSaveLoading(false));
        yield put(setChartSaveError({}));
    } catch (e) {

        yield put(setChartSaveError(e.response.data.error));
        yield put(setChartSaveLoading(false));
    }
}
export function* sendChartSaveClearSaga() {
        yield put(setChartSaveData({}));
        yield put(setChartSaveError({}));
}


export function* watchChartSave() {
    yield takeEvery(CHART_SAVE, sendChartSaveSaga);
    yield takeEvery(CHART_SAVE_CLEAR, sendChartSaveClearSaga);
}