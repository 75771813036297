import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: null,
    totalItems: 0,
    selectedRow: null
};
const didoxDocuments = createSlice({
    name: 'didoxDocuments',
    initialState,
    reducers: {
        setDidoxDocumentsTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setDidoxDocumentsTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setDidoxDocumentsTabError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        setDidoxCurrentDocument: (state, action) => {
            state.selectedRow = action.payload;
            return state;
        },
    }
});
export const {setDidoxDocumentsTabData,setDidoxDocumentsTabLoading,setDidoxDocumentsTabError, setDidoxCurrentDocument} = didoxDocuments.actions;
export default didoxDocuments.reducer;
