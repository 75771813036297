import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0
};
const accountStateTab = createSlice({
    name: 'accountStateTab',
    initialState,
    reducers: {
        setAccountStateTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setAccountStateTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setAccountStateTabError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setAccountStateTabData,setAccountStateTabLoading,setAccountStateTabError} = accountStateTab.actions;
export default accountStateTab.reducer;
