import React, {useEffect, useState} from 'react';
import {
    Radar,
    RadarChart,
    PolarGrid,
    Legend,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
    Cell, PieChart, Tooltip
} from 'recharts';
import {generateColor} from "../index";

const CustomRadarChart = ({comparing_columns,comparing_by,data}) => {
    const convertToNumber = (value) => {
        if (typeof value === 'string') {
            const numberValue = parseFloat(value.replace(/,/g, ''));
            return isNaN(numberValue) ? value : numberValue;
        }
        return value;
    };

    const convertedData = data.map((item) => {
        const convertedItem = { ...item };
        comparing_columns.forEach((column) => {
            if (convertedItem.hasOwnProperty(column)) {
                convertedItem[column] = convertToNumber(convertedItem[column]);
            }
        });
        return convertedItem;
    });
    let newFormat = convertedData.map((item) => {
        let main = '';
        for (let itemKey in item) {
            if (comparing_by.includes(itemKey)) {
                if (main === '') {
                    main +=  itemKey + ": " + item[itemKey];
                } else {
                    main += " , " + itemKey + ": " + item[itemKey];
                }
            }
        }
        return {main, ...item}
    });
    return (
            <RadarChart width={window.innerWidth / 100 * 85} height={window.innerHeight / 100 * 85} data={newFormat}>
                <PolarGrid />
                <PolarAngleAxis dataKey={"main"} />
                <PolarRadiusAxis angle={30}  />
                {Object.keys(newFormat[0]).filter(key => comparing_columns.includes(key)).map((value) => {
                    let color = generateColor();
                    return (
                    <Radar name={`${value}`} dataKey={`${value}`} stroke={color} fill={color} fillOpacity={0.6} />
                    )
                })}
                <Tooltip />
                <Legend />
            </RadarChart>
    );
};

export default CustomRadarChart;