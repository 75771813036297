import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {setChartQuery} from "../../../slices/chart/chart";
import {ACCOUNT_STATE_TAB} from "../../../types/accountState/accountStateTab/accountStateTab";
import {setAccountStateTabData,setAccountStateTabError,setAccountStateTabLoading} from "../../../slices/accountState/accountStateTab/accountStateTab";


export function* sendAccountStateTabSaga({bodyData}) {
    try {
        yield put(setAccountStateTabLoading(true));
        const result = yield call(makeQuery, bodyData);
        yield put(setAccountStateTabData(result));
        yield put(setChartQuery(bodyData));
        yield put(setAccountStateTabLoading(false));
        yield put(setAccountStateTabError({}));
    } catch (e) {
        yield put(setAccountStateTabError(e.response.data.error));
        yield put(setAccountStateTabLoading(false));
    }
}


export function* watchAccountStateTab() {
    yield takeEvery(ACCOUNT_STATE_TAB, sendAccountStateTabSaga);
}