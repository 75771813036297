import {takeEvery, put, call} from 'redux-saga/effects';
import {setRefGoodMhikTabData, setRefGoodMhikTabLoading, setRefGoodMhikTabError} from "../../../slices/references/refGoodMhikTab/refGoodMhikTab";
import {makeQueryPageable} from "../../../apis";
import {REF_GOOD_MHIK_TAB} from "../../../types/references/refGoodMhikTab/refGoodMhikTab";


export function* sendRefGoodMhikTabSaga({queryData, params}) {
    try {
        yield put(setRefGoodMhikTabLoading(true));
        const result = yield call(makeQueryPageable, queryData, params);
        yield put(setRefGoodMhikTabData(result));
        yield put(setRefGoodMhikTabLoading(false));
        yield put(setRefGoodMhikTabError({}));
    } catch (e) {
        yield put(setRefGoodMhikTabError(e.response.data.error));
        yield put(setRefGoodMhikTabLoading(false));
    }
}


export function* watchRefGoodMhikTabType() {
    yield takeEvery(REF_GOOD_MHIK_TAB, sendRefGoodMhikTabSaga);
}
