import {takeEvery, put, call} from 'redux-saga/effects';
import {makeGet} from "../../../apis";
import {setChartQuery} from "../../../slices/chart/chart";
import {DIDOX_CURRENT_DOC, DIDOX_DOCUMENTS} from "../../../types/document/didox/didoxDocuments";
import {
    setDidoxDocumentsTabData,
    setDidoxDocumentsTabError,
    setDidoxDocumentsTabLoading,
    setDidoxCurrentDocument
} from "../../../slices/document/didox/didoxDocuments";


export function* sendDidoxDocumentsSaga({params, url}) {
    try {
        yield put(setDidoxDocumentsTabLoading(true));
        const result = yield call(makeGet, params, url);
        yield put(setDidoxDocumentsTabData(result));
        yield put(setChartQuery({}));
        yield put(setDidoxDocumentsTabLoading(false));
        yield put(setDidoxDocumentsTabError({}));
    } catch (e) {
        yield put(setDidoxDocumentsTabError(e.response.data.error));
        yield put(setDidoxDocumentsTabLoading(false));
    }
}

export function* sendDidoxCurrentDataSaga({selectedRow}) {
    try {
        yield put(setDidoxCurrentDocument(selectedRow));
    } catch (e) {
        console.log('e', e);
    }
}


export function* watchDidoxDocuments() {
    yield takeEvery(DIDOX_DOCUMENTS, sendDidoxDocumentsSaga);
    yield takeEvery(DIDOX_CURRENT_DOC, sendDidoxCurrentDataSaga);
}