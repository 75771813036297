import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0,
    details: {}
};
const smetaHistoryTab = createSlice({
    name: 'smetaHistoryTab',
    initialState,
    reducers: {
        setSmetaHistoryTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setSmetaHistoryTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setSmetaHistoryTabError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        setSmetaHistoryDetails: (state, action) => {
            state.details = action.payload;
            return state;
        }
    }
});
export const {setSmetaHistoryTabData, setSmetaHistoryTabLoading, setSmetaHistoryTabError, setSmetaHistoryDetails} = smetaHistoryTab.actions;
export default smetaHistoryTab.reducer;