import {sortCompareString} from "../../libs/formbm";
import {Button, message} from "antd";
import EyeOutlined from "@ant-design/icons/es/icons/EyeOutlined";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import useSmetaHistoryData from "../Smeta/SmetaHistory/useSmetaHistoryData";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {useFilter} from "../../context/FilterContext";
import {ChartQueryContext} from "../../context/chartQueryContext/ChartQueryContextProvider";
import useTableActions from "../../hooks/useTableActions";
import {SMETA_HISTORY_DETAILS, SMETA_HISTORY_TAB} from "../../store/types/smeta/smetaHistory/smetaHistoryTab";

const useEmployeeHistoryTab = (currentState) => {
    const tableColumns = [
        {
            title: 'Исми-шарифи',
            dataIndex: 'emp_fio',
            width: 200,
            sorter: (a, b) => sortCompareString(a.emp_fio, b.emp_fio)
        },
        {
            title: 'Амал',
            dataIndex: 'action_name',
            width: 100,
            sorter: (a, b) => sortCompareString(a.action_name, b.action_name)
        },
        {
            title: 'Ўзгариш санаси',
            dataIndex: 'modified_date',
            width: 100,
            sorter: (a, b) => sortCompareString(a.modified_date, b.modified_date)
        },
        {
            title: 'Ҳаракат',
            dataIndex: 'operation',
            width: 100,
            render: (_, record) => {
                return (
                    <Button type="primary" style={{fontSize: '16px'}} shape="circle"
                            icon={<EyeOutlined onClick={() => show(record)}/>} size={'middle'}/>
                )
            },
        },
    ];
    const smetaData = useSelector((state) => state.smetaHistoryTab.data);
    const totalItems = useSelector((state) => state.smetaHistoryTab.totalItems);
    const loading = useSelector((state) => state.smetaHistoryTab.loading);
    const error = useSelector((state) => state.smetaHistoryTab.error);
    let navigate = useNavigate();
    const {columns} = useSmetaHistoryData();
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const {filterValues} = useFilter();
    const filterKey = 'CashAppHistoryTab';
    const filterData = filterValues[filterKey] || '';
    const [filterTab, setFilterTab] = useState("");
    const {handleUpdateChartQuery, handleFilterKey} = useContext(ChartQueryContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const {rowSelection, pagination, onRow} = useTableActions(totalItems, selectedRowKeys, setSelectedRow, setSelectedRowKeys);
    const [innerIsModalOpen, setInnerIsModalOpen] = useState(false);

    const showInnerModal = () => {
        setInnerIsModalOpen(true);
    };

    const handleInnerOk = () => {
        setInnerIsModalOpen(false);
    };

    const handleInnerCancel = () => {
        setInnerIsModalOpen(false);
    };

    useEffect(() => {
        getTabData();
    }, [currentState]);

    const show = (selectedRow) => {
        dispatch({type: SMETA_HISTORY_DETAILS, data: JSON.parse(selectedRow.document.value)});
        showInnerModal();
    };
    const getTabData = async (filter = '') => {

        //console.log(filter,' FILTERmmm')

        let filters = [];
        if (filter !== '' && filter !== '{}') filters = filter;

        if (filterData && filters.length === 0) {
            filters = [...filters, ...filterData];
        }
        if (currentState?.state && Object.keys(currentState?.state).length !== 0) {
            let bodyData = {
                query: {
                    id: columns.query.id || columns.query.ID,
                    source: "get_doc_action_log_by_emp_id(" + currentState?.state?.doc_id + ", '" + currentState?.state?.doc_type + "')",
                    fields: columns.query.fields,
                    filters,
                }
            };
            handleFilterKey(filterKey);
            dispatch({type: SMETA_HISTORY_TAB, bodyData});

            setSelectedRow(null);
            setSelectedRowKeys([]);
        }

    };

    useEffect(() => {
        getTabData();
    }, []);
    return {
        contextHolder,
        smetaData,
        rowSelection,
        loading,
        pagination,
        onRow,
        selectedRow,
        tableColumns,
        innerIsModalOpen,
        showInnerModal,
        handleInnerOk,
        handleInnerCancel
    };
};

export default useEmployeeHistoryTab;