
const getDirection = async () => {

    return [
        {'code': 1, 'name': 'Кирим'},
        {'code': -1, 'name': 'Чиким'},
    ]

}
const getExistsAcc = async () => {

    return [
        {'code': 0, 'name': 'Бор'},
        {'code': 1, 'name': 'Йўқ'},
    ]

}

export default getDirection