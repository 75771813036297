import React from 'react';
import {
    LaptopOutlined, NotificationOutlined, UserOutlined,
    AreaChartOutlined,
    ContainerOutlined,
    ProjectOutlined,
    BankOutlined,
    FundOutlined, ApartmentOutlined, AuditOutlined, DeploymentUnitOutlined
} from '@ant-design/icons';


const iconMapping = {
    LaptopOutlined,
    NotificationOutlined,
    UserOutlined,
    AreaChartOutlined,
    ContainerOutlined,
    ProjectOutlined,
    BankOutlined,
    FundOutlined,
    ApartmentOutlined,
    AuditOutlined,
    DeploymentUnitOutlined
};
const DynamicIcon = ({ iconName }) => {
    // Извлечение имени иконки из строки формата '<IconName/>'
    if (iconName === null ) return ''
    const IconComponent = iconMapping[iconName];

    if (!IconComponent) {
        return <span>Иконка не найдена</span>;
    }

    return <IconComponent />;
};

export default DynamicIcon;