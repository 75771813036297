import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0
};
const refGoodMhikTab = createSlice({
    name: 'refGoodMhikTab',
    initialState,
    reducers: {
        setRefGoodMhikTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setRefGoodMhikTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setRefGoodMhikTabError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setRefGoodMhikTabData, setRefGoodMhikTabLoading, setRefGoodMhikTabError} = refGoodMhikTab.actions;
export default refGoodMhikTab.reducer;
