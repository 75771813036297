import React, {createContext, useState, useContext} from "react";

const ThemeContext = createContext()

const ThemeProvider = ({children}) => {
    const storedDarkMode = localStorage.getItem('isDarkMode');
    const initialDarkMode = storedDarkMode ? JSON.parse(storedDarkMode) : false;

    const [ isDarkMode, setIsDarkMode ] = useState(false);

    const toggleDarkMode = () => {
        const newDarkMode = !isDarkMode;

        localStorage.setItem('isDarkMode', JSON.stringify(newDarkMode));

        setIsDarkMode(newDarkMode)
    }

    return (
        <ThemeContext.Provider value={{isDarkMode, setIsDarkMode, toggleDarkMode}}>
            {children}
        </ThemeContext.Provider>
    )
}

const useTheme = () => {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useThemeBM must be used within a ThemeProviderBM");
    }
    return context
}

export { ThemeProvider, useTheme }