import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button} from "antd";
import useTableActions from "../../hooks/useTableActions";
import exportToExcel from "../../components/ExportToExcel";
import {FileExcelOutlined} from "@ant-design/icons";
import useStatsDescTabData from "./useStatsDescTabData";
import {STATS_DESC} from "../../store/types/references/statsDesc/statsDesc";

const useStatsDescTab = (setIsModalOpen) => {
    let currentDate = new Date();
    let day = String(currentDate.getDate()).padStart(2, '0');
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let year = currentDate.getFullYear();
    let formattedDate = `${day}.${month}.${year}`;
    const data = useSelector((state) => state.statsDesc.data);
    const totalItems = useSelector((state) => state.statsDesc.totalItems);
    const loading = useSelector((state) => state.statsDesc.loading);
    const dispatch = useDispatch();
    const {mainStatsDescTabColumns, mainStatsDescTabData} = useStatsDescTabData();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const {rowSelection, pagination, onRow} = useTableActions(totalItems, selectedRowKeys,setSelectedRow, setSelectedRowKeys);
    const getTabData = async () => {
        /*mainStatsDescTabData.query['filters'] = [
            {
                "column": "date_saldo",
                "operator": "range",
                "value": formattedDate,
                "value2": formattedDate,
                "dataType": "date"
            }
        ];*/
        dispatch({type: STATS_DESC, mainStatsDescTabData});
        setSelectedRow(null);
        setSelectedRowKeys([]);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        getTabData();
    }, []);

    const handleExport = () => {
        exportToExcel(data, mainStatsDescTabColumns)
    };
    const tableHeader = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }}>
                <Button type="primary" shape="circle" onClick={handleExport}>
                    <FileExcelOutlined/>
                </Button>
            </div>
        );
    };
    return {mainStatsDescTabColumns,tableHeader,data,rowSelection,loading,pagination,onRow,selectedRow, handleOk, handleCancel};
};

export default useStatsDescTab;