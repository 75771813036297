const useSmetaHistoryData = () => {
    const columns = {
        "query": {
            "id": "get_doc_action_log_by_emp_id",
            "source": "get_doc_action_log_by_emp_id",
            "fields": [
                {   "column": "action"},
                {   "column": "emp_fio"},
                {   "column": "id"},
                {   "column": "doc_type"},
                {   "column": "doc_id"},
                {   "column": "action_name"},
                {   "column": "modified_date", "format": "datetime", "type": "char"},
                {   "column": "document"},
                {   "column": "emp_id"}
            ]
        }
    };
    return {columns};
};

export default useSmetaHistoryData;