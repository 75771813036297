import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

const exportToExcel = (dataTable, queryColumns) => {

    //format sum
    const dataClone = [...dataTable];

    /* Convert numeric "amount" values to the desired format and alignment */
    dataClone.forEach((item) => {
        //item.amount BankInfoTab.js uchun ishlidi
        if (item.amount !== undefined) {
            const numericValue = parseFloat(item.amount.toString().replace(/,/g, ''))/100; // Convert to number
            if (!isNaN(numericValue)) {
                item.amount = {
                    t: 'n', // 'n' for numeric data type
                    v: numericValue, // Numeric value
                    z: '#,##0.00', // Number format with two decimal places and thousands separator
                    s: { alignment: { horizontal: 'right' } }, // Set alignment to right
                };
            }
        }

        if (item.saldo !== undefined) {
            const numericValue = parseFloat(item.saldo.toString().replace(/,/g, '')); // Convert to number
            if (!isNaN(numericValue)) {
                item.saldo = {
                    t: 'n', // 'n' for numeric data type
                    v: numericValue, // Numeric value
                    z: '#,##0.00', // Number format with two decimal places and thousands separator
                    s: { alignment: { horizontal: 'right' } }, // Set alignment to right
                };
            }
        }
    });

    let dataExcel = dataClone

    const tableData = dataClone
    const columnData = queryColumns

    if (columnData && columnData.length !== 0) {

        const dataIndexToTitleMap = columnData.reduce((acc, column) => {
            acc[column.dataIndex] = column.title;
            return acc;
        }, {});

        // Replace the response object keys with titles
        const updatedResponse = tableData.map(item => {
            const updatedItem = {};
            for (const key in item) {
                if (dataIndexToTitleMap[key]) {
                    updatedItem[dataIndexToTitleMap[key]] = item[key];
                } else {
                    updatedItem[key] = item[key]; // Keep the original key if no matching dataIndex is found
                }
            }
            return updatedItem;
        });

        dataExcel = updatedResponse

    }
    console.log(dataExcel)

    /* Convert tableData to worksheet */
    const worksheet = XLSX.utils.json_to_sheet(dataExcel);

    //WIDTH
    const columnCount = XLSX.utils.decode_range(worksheet['!ref']).e.c + 1; // Get the number of columns
    const defaultColumnWidth = 20;
    worksheet['!cols'] = Array(columnCount).fill({ wch: defaultColumnWidth });

    /* Create workbook and add the worksheet */
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    /* Generate Excel file buffer */
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    /* Save the file */
    const excelBlob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(excelBlob, 'tableData.xlsx');
};

export default exportToExcel;