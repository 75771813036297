import React, { useMemo } from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { Spin } from 'antd';
import styled from 'styled-components';
import { generateColor } from '../index';

const CustomLineChart = ({ comparing_by, comparing_columns, data }) => {
    const lineColors = useMemo(() => comparing_columns.map(() => generateColor()), [
        comparing_columns
    ]);

    const convertToNumber = (value) => {
        if (typeof value === 'string') {
            const numberValue = +value.replace(/,/g, '');
            return isNaN(numberValue) ? value : numberValue;
        }
        return value;
    };

    const convertedData = useMemo(
        () =>
            data.map((item) => {
                const convertedItem = { ...item };
                comparing_columns.forEach((column) => {
                    if (convertedItem.hasOwnProperty(column)) {
                        convertedItem[column] = convertToNumber(convertedItem[column]);
                    }
                });
                return convertedItem;
            }),
        [comparing_columns, data]
    );

    const newFormat = useMemo(
        () =>
            convertedData.map((item) => {
                let main = '';
                for (let itemKey in item) {
                    if (comparing_by.includes(itemKey)) {
                        if (main === '') {
                            main += itemKey + ': ' + item[itemKey];
                        } else {
                            main += ' , ' + itemKey + ': ' + item[itemKey];
                        }
                    }
                }
                return { main, ...item };
            }),
        [comparing_by, convertedData]
    );

    return (
        <div className="chart-line-container">
            <LineChart
                width={convertedData.length * 100}
                height={window.innerHeight / 100 * 80}
                data={newFormat}
                margin={{ top: 5, right: 40, left: 20, bottom: 100 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="main" interval={0} tick={{ angle: -30, textAnchor: 'end' }} height={100} />
                <YAxis />
                <Tooltip />
                <Legend />
                {comparing_columns.map((value, index) => (
                    <Line type="monotone" key={value} dataKey={`${value}`} stroke={lineColors[index]} />
                ))}
            </LineChart>
        </div>
    );
};

export default CustomLineChart;
