import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0
};
const accSaldoPeriodTab = createSlice({
    name: 'accSaldoPeriodTab',
    initialState,
    reducers: {
        setAccSaldoPeriodTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setAccSaldoPeriodTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setAccSaldoPeriodTabError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setAccSaldoPeriodTabData,setAccSaldoPeriodTabLoading,setAccSaldoPeriodTabError} = accSaldoPeriodTab.actions;
export default accSaldoPeriodTab.reducer;
