import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: {},
    loading: false,
    error: {}
};
const chartSave = createSlice({
    name: 'chartSave',
    initialState,
    reducers: {
        setChartSaveData: (state, action) => {
            state.data = action.payload;
            return state;
        },
        setChartSaveLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setChartSaveError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setChartSaveData, setChartSaveLoading, setChartSaveError} = chartSave.actions;
export default chartSave.reducer;
