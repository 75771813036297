import React, {useEffect, useState} from 'react';
import {Button, Table} from 'antd';
import {FileExcelOutlined} from "@ant-design/icons";
import exportToExcel from "../../ExportToExcel";
import {propertiesOfFields} from "../ui/data";

const CustomTable = ({comparing_columns, comparing_by, data, comparableColumns}) => {
    const [columns, setColumns] = useState([]);
    const handleExport = () => {
        exportToExcel(data, columns);
    };
    const tableHeader = () => {
        return (
            <Button type="primary" shape="circle" onClick={handleExport}>
                <FileExcelOutlined/>
            </Button>
        )
    };
    useEffect(() => {
        const columns = comparableColumns?.filter((col) => comparing_by.includes(col.column)).map((col) => {
            return {
                title: col.label,
                dataIndex: col.column,
                defaultSortOrder: 'descend',
                sorter: (a, b) => {
                    const aValue = a[col.column];
                    const bValue = b[col.column];
                    if (typeof aValue === 'number' && typeof bValue === 'number') {
                        return aValue - bValue;
                    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
                        return aValue.localeCompare(bValue);
                    } else {
                        return 0;
                    }
                },
            };
        });
        console.log('comparableColumns', comparing_columns);
        // const columns_second = comparableColumns?.filter((col) => comparing_columns.includes(col.column)).map((col) => {
        //     return {
        //         title: col.label,
        //         dataIndex: col.column,
        //         defaultSortOrder: 'descend',
        //         align: "right",
        //         sorter: (a, b) => {
        //             const parseValue = (value) => {
        //                 if (typeof value === 'string') {
        //                     return parseFloat(value.replace(/,/g, '')) || 0;
        //                 }
        //                 return Number(value) || 0;
        //             };
        //
        //             const aValue = parseValue(a[col.column]);
        //             const bValue = parseValue(b[col.column]);
        //
        //             return aValue - bValue;
        //         },
        //         render: (text, record) => {
        //             const value = typeof record[col.column] === 'string'
        //                 ? parseFloat(record[col.column].replace(/,/g, ''))
        //                 : record[col.column];
        //
        //             return new Intl.NumberFormat().format(value || 0);
        //         }
        //     };
        //
        //
        // });
        const columns_second = comparing_columns.map((col) => {
            let arr = col.split("$");
            const comparableCol = comparableColumns.find(c => c.column === arr[0]);
            return {
                title: `${comparableCol.label}нинг ${propertiesOfFields[arr[1]]}`,
                dataIndex: col,
                defaultSortOrder: 'descend',
                align: "right",
                sorter: (a, b) => {
                    const parseValue = (value) => {
                        if (typeof value === 'string') {
                            return parseFloat(value.replace(/,/g, '')) || 0;
                        }
                        return Number(value) || 0;
                    };

                    const aValue = parseValue(a[col]);
                    const bValue = parseValue(b[col]);

                    return aValue - bValue;
                },
                render: (text, record) => {
                    const value = typeof record[col] === 'string'
                        ? parseFloat(record[col].replace(/,/g, ''))
                        : record[col];

                    return new Intl.NumberFormat().format(value || 0);
                }
            };


        });
        setColumns([...columns, ...columns_second]);
    }, [comparableColumns]);
    return <Table dataSource={data} title={tableHeader} columns={columns}/>;
};

export default CustomTable;