import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: null,
    totalItems: 0,
    createMessage: null
};
const documentControlsTab = createSlice({
    name: 'documentControlsTab',
    initialState,
    reducers: {
        setDocumentControlsTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setDocumentControlsTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setDocumentControlsTabError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        setDocumentControlsCreate: (state, action) => {
            state.createMessage = action.payload;
            return state;
        },
        setDocumentControlsDelete: (state, action) => {
            state.data = state.data.filter(item => item.id !== action.payload);
            state.totalItems = state.totalItems - 1;
            return state;
        },
        setClearCreate: (state, action) => {
            state.createMessage = null;
            state.error = null;
            return state;
        },
    }
});
export const {setDocumentControlsTabData,setDocumentControlsTabLoading,setDocumentControlsTabError,setDocumentControlsDelete, setDocumentControlsCreate,setClearCreate} = documentControlsTab.actions;
export default documentControlsTab.reducer;
