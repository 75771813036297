import React, { useMemo } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine
} from 'recharts';
import './bubble.css';
import { generateColor } from '../index';

const CustomBarChart = ({ comparing_columns, comparing_by, data }) => {
    const convertToNumber = (value) => {
        if (typeof value === 'string') {
            const numberValue = parseFloat(value.replace(/,/g, ''));
            return isNaN(numberValue) ? value : numberValue;
        }
        return value;
    };

    const convertedData = useMemo(() => {
        return data.map((item) => {
            const convertedItem = { ...item };
            comparing_columns.forEach((column) => {
                if (convertedItem.hasOwnProperty(column)) {
                    convertedItem[column] = convertToNumber(convertedItem[column]);
                }
            });
            return convertedItem;
        });
    }, [data, comparing_columns]);

    const newFormat = useMemo(() => {
        return convertedData.map((item) => {
            let main = '';
            for (let itemKey in item) {
                if (comparing_by.includes(itemKey)) {
                    if (main === '') {
                        main += itemKey + ': ' + item[itemKey];
                    } else {
                        main += ' , ' + itemKey + ': ' + item[itemKey];
                    }
                }
            }
            return { main, ...item };
        });
    }, [convertedData, comparing_by]);

    const barChartWidth = newFormat.length * 100; // Adjust as needed

    return (
        <div className="chart-line-container">
            <BarChart
                width={barChartWidth}
                height={window.innerHeight / 100 * 70}
                data={newFormat}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 100
                }}
                key="bar-chart"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={'main'} interval={0} tick={{ angle: -45, textAnchor: 'end' }} height={100} />
                <YAxis />
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                {comparing_columns.map((value) => (
                    <Bar dataKey={`${value}`} key={value} fill={generateColor()} />
                ))}
            </BarChart>
        </div>
    );
};

export default CustomBarChart;
