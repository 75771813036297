import React, {useState} from 'react';
import * as XLSX from "xlsx";
import {Button, message} from "antd";
import * as ExcelJS from "exceljs";
import axios from "../../api/axios";
import {dataSmetaExcel} from "./SmetaData";
import {PrinterOutlined} from "@ant-design/icons";

const QUERY_URL = '/api/public/query';

const SmetaPrint = ({dataRow, reqHeader}) => {

    const [open, setOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const printTable = () => {
        // Create a new worksheet
        const ws = XLSX.utils.aoa_to_sheet([["Column 1", "Column 2", "Column 3"]]);

        // Set the width of the first column (A) to 15 characters
        ws['!cols'] = [{ wch: 50 }];

        // Set the alignment of a specific cell (A1) to center
        const cellA1Style = { alignment: { horizontal: 'center' } };
        ws['A1'].s = cellA1Style;

        // Merge cells A1 to C1 (colspan)
        /*ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];*/
        //ws['A2'].v = 'asdasd'
        // Add data to the worksheet
        XLSX.utils.sheet_add_aoa(ws, [["Data 1", "Data 2", "Data 3"]]);
//        XLSX.utils.sheet_add_aoa(ws);


        // Save the workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, 'smeta.xlsx');

    };


    const getSmetaData = async () => {
        console.log(reqHeader,'reqHeader')

        dataSmetaExcel.query.source = "GET_SMETA_EXPENSES_EXCEL_BY_ID("+dataRow.id+")"

        const { data } = await axios.post(QUERY_URL,
            ( dataSmetaExcel ),
            {
                headers: reqHeader,
                withCredentials: false
            });
        console.log(data,'lololololo')
        return data;


    };

    const printTableExcelJs = async () => {

        if (!dataRow) {
            messageApi.open({
                type: 'warning',
                content: 'Каторни танланг',
            });

            return false;
        }

        const data = await getSmetaData(); // Replace 'YOUR_API_URL' with the actual API endpoint
        console.log(data,'lololololo')

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Paydoc');

        // Define custom styles
        const titleStyle = {
            font: { bold: true, size: 12 },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            /*border: {
                top: { style: 'medium', color: { argb: '646464' } },
                bottom: { style: 'medium', color: { argb: '646464' } },
                right: { style: 'medium', color: { argb: '646464' } },
                left: { style: 'medium', color: { argb: 'AAAAAA' } }
            },*/

        };

        const centeredStyle = {
            font: { bold: true, size: 10 },
            alignment: { horizontal: 'center', vertical: 'middle' },
            /*border: {
                top: { style: 'medium', color: { argb: 'AAAAAA' } },
                bottom: { style: 'medium', color: { argb: 'AAAAAA' } },
                right: { style: 'medium', color: { argb: 'AAAAAA' } },
                left: { style: 'medium', color: { argb: 'AAAAAA' } }
            },*/
        };

        const rightenStyle = {
            font: { bold: true, size: 10 },
            alignment: { horizontal: 'right', vertical: 'middle' },
            /*border: {
                top: { style: 'none', color: { argb: 'AAAAAA' } },
                bottom: { style: 'none', color: { argb: 'AAAAAA' } },
                right: { style: 'none', color: { argb: 'AAAAAA' } },
                left: { style: 'none', color: { argb: 'AAAAAA' } }
            },*/
        };

        worksheet.columns = [
            { width: 6 }, // Column A
            { width: 60 }, // Column B
            { width: 17 }, // Column C
            { width: 13 }, // Column D
            { width: 13 }, // Column E
            { width: 13 }, // Column F
            { width: 13 }, // Column G
        ];

        worksheet.getRow(1).height = 140
        worksheet.getRow(2).height = 63


        // Set cell values and apply the defined styles
        worksheet.mergeCells('A1:B1');
        const cellValue = `КЕЛИШИЛДИ\n Ўзбекистон Республикаси\n Савдо-саноат палатаси\n раиси ўринбосари\n _________________\n "____" _____________ ${dataRow.finyear} й.`;

        // Set the cell value
        worksheet.getCell('A1').value = cellValue;
        worksheet.getCell('A1').style = titleStyle;

        worksheet.mergeCells('D1:G1');
        worksheet.getCell('D1').value = `"ТАСДИКЛАЙМАН"\n Ўзбекистон Республикаси\n Савдо-саноат палатаси\n ${dataRow.filial_name} ҳудудий бошқармаси\n бошлиғи\n _________________\n "____" _____________ ${dataRow.finyear} й.`;
        worksheet.getCell('D1').style = titleStyle;


        worksheet.mergeCells('A2:G2');
        worksheet.getCell('A2').value = `Ўзбекистон Савдо-саноат палатаси\n ${dataRow.filial_name} бошқармасининг ${dataRow.finyear} йил учун\n ХАРАЖАТЛАР СМЕТАСИ`;
        worksheet.getCell('A2').style = titleStyle;

        worksheet.getCell('G3').value = `минг сум`;
        worksheet.getCell('G3').style = titleStyle;

        worksheet.mergeCells('A4:A5');
        worksheet.getCell('A4').value = `№`;
        worksheet.getCell('A4').style = titleStyle;

        worksheet.mergeCells('B4:B5');
        worksheet.getCell('B4').value = `Харажатлар номи`;
        worksheet.getCell('B4').style = titleStyle;

        worksheet.mergeCells('C4:C5');
        worksheet.getCell('C4').value = `Йиллик режа`;
        worksheet.getCell('C4').style = titleStyle;

        worksheet.mergeCells('D4:G4');
        worksheet.getCell('D4').value = `Чораклар бўйича`;
        worksheet.getCell('D4').style = titleStyle;
        worksheet.getCell('D5').value = `I`;
        worksheet.getCell('D5').style = titleStyle;
        worksheet.getCell('E5').value = `II`;
        worksheet.getCell('E5').style = titleStyle;
        worksheet.getCell('F5').value = `III`;
        worksheet.getCell('F5').style = titleStyle;
        worksheet.getCell('G5').value = `IV`;
        worksheet.getCell('G5').style = titleStyle;

        const sumFormat = (sum) => parseFloat(sum).toLocaleString("en-EN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })


        for (let i = 0; i < data.length; i++) {
            const row = worksheet.getRow(i + 6);

            row.getCell('A').value = i + 1;
            row.getCell('B').value = data[i].code + ' - ' + data[i].name;
            row.getCell('C').value = sumFormat(data[i].sumyear);
            row.getCell('D').value = sumFormat(data[i].totalquart1);
            row.getCell('E').value = sumFormat(data[i].totalquart2);
            row.getCell('F').value = sumFormat(data[i].totalquart3);
            row.getCell('G').value = sumFormat(data[i].totalquart4);

            row.getCell('A').alignment = { horizontal: 'right', vertical: 'top'};
            row.getCell('B').alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

            // Right-align cells D, E, F, and G
            ['C', 'D', 'E', 'F', 'G'].forEach(col => {
                row.getCell(col).alignment = { horizontal: 'right', vertical: 'middle' };
            });

            if (data[i].istotal === 'Y') {
                // Apply the bold style to cells A, B, C, D, E, F, G
                for (let col of ['A', 'B', 'C', 'D', 'E', 'F', 'G']) {
                    row.getCell(col).style.font = { bold: true };
                }
            }
        }

        let totalQuart1Sum = 0;
        let totalQuart2Sum = 0;
        let totalQuart3Sum = 0;
        let totalQuart4Sum = 0;
        let sumYearTotal = 0;

        for (let i = 0; i < data.length; i++) {
            if (data[i].istotal !== 'N' ) {
                totalQuart1Sum += data[i].totalquart1;
                totalQuart2Sum += data[i].totalquart2;
                totalQuart3Sum += data[i].totalquart3;
                totalQuart4Sum += data[i].totalquart4;
                sumYearTotal += data[i].sumyear;
            }
        }

// Add a last row with totals
        const lastRow = worksheet.addRow([
            '', // Empty cell for A
            'Жами', // Label for B
            sumFormat(sumYearTotal), // Total for C
            sumFormat(totalQuart1Sum), // Total for D
            sumFormat(totalQuart2Sum), // Total for E
            sumFormat(totalQuart3Sum), // Total for F
            sumFormat(totalQuart4Sum), // Total for G
        ]);

// Center-align the last row
        ['A', 'B', 'C'].forEach(col => {
            lastRow.getCell(col).alignment = { horizontal: 'center', vertical: 'middle' };
        });

// Right-align the total cells
        ['C', 'D', 'E', 'F', 'G'].forEach(col => {
            lastRow.getCell(col).alignment = { horizontal: 'right', vertical: 'middle' };
        });

// Apply bold style to the last row
        ['C', 'B', 'D', 'E', 'F', 'G'].forEach(col => {
            lastRow.getCell(col).style.font = { bold: true };
        });

        //ADD STYLE BORDERS BEG

        const targetRow = 4; // Specify the row number
        const targetColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G']; // Specify the columns

/*        targetColumns.forEach(col => {
            for (let rowNumber = 1; rowNumber <= 3; rowNumber++) {
                const cell = worksheet.getCell(`${col}${rowNumber}`);
                cell.border = {
                    top: { style: 'none' },
                    bottom: { style: 'none' },
                    left: { style: 'none' },
                    right: { style: 'none' },
                };
            }
        });*/

        targetColumns.forEach(col => {
            for (let rowNumber = 0; rowNumber <= worksheet.rowCount; rowNumber++) {
                const cell = worksheet.getCell(`${col}${rowNumber}`);

                // Check if the cell is part of a merged region
                if (cell.master) {
                    cell.border = {
                        top: { style: 'medium', color: { argb: 'AAAAAA' } }, // Red color
                        bottom: { style: 'medium', color: { argb: 'AAAAAA' } }, // Red color
                        left: { style: 'medium', color: { argb: 'AAAAAA' } }, // Red color
                        right: { style: 'medium', color: { argb: 'AAAAAA' } }, // Red color
                    };
                }
            }
        });



        /*targetColumns.forEach(col => {
            const cell = worksheet.getCell(`${col}${targetRow}`);
            cell.border = {
                //top: { style: 'medium', color: { argb: 'FF0000' } }, // Adjust color if needed
                top: { style: 'medium', color: { argb: 'FF0000' } },
                bottom: { style: 'medium', color: { argb: 'FF0000' } },
                right: { style: 'medium', color: { argb: 'FF0000' } },
                left: { style: 'medium', color: { argb: 'FF0000' } }
            };
            cell.style.font = { bold: true };
        });*/

/*        worksheet.getCell('A4').border = {
            top: { style: 'medium', color: { argb: '646464' } }, // Adjust color if needed
            left: { style: 'medium', color: { argb: '000000' } }, // Adjust color if needed
        };
        worksheet.getCell('G4').border = {
            top: { style: 'medium', color: { argb: '646464' } }, // Adjust color if needed
            right: { style: 'medium', color: { argb: '000000' } }, // Adjust color if needed
        };*/

        const endRow = worksheet.rowCount;

        /*for (let rowNumber = 0; rowNumber <= endRow; rowNumber++) {

            const row = worksheet.getRow(rowNumber+4);
            targetColumns.forEach(col => {
                row.getCell(col).border = {
                    top: { style: 'medium', color: { argb: 'FF0000' } },
                    bottom: { style: 'medium', color: { argb: 'FF0000' } },
                    right: { style: 'medium', color: { argb: 'FF0000' } },
                    left: { style: 'medium', color: { argb: 'FF0000' } }
                };
            });
        }*/

        /*const startRow = 5; // Specify the starting row (A4)
        const endRow = worksheet.rowCount; // Get the last row number (assuming it's the last row of your data)
// Add left border to cells from A4 to the last row of column A
        for (let rowNumber = startRow; rowNumber <= endRow; rowNumber++) {
            const cell = worksheet.getCell(`A${rowNumber}`);
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } }, // Adjust color if needed
            };
        }
// Add right border to cells from G4 to the last row of column G
        for (let rowNumber = startRow; rowNumber <= endRow; rowNumber++) {
            const cell = worksheet.getCell(`G${rowNumber}`);
            cell.border = {
                right: { style: 'medium', color: { argb: '000000' } }, // Adjust color if needed
            };
        }*/

        //ADD STYLE BORDERS END

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Смета.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);



    }

    const showModal = () => {
        if (!dataRow) {
            messageApi.open({
                type: 'warning',
                content: 'Каторни танланг',
            });

            return false;
        }
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            {contextHolder}
            <Button type="primary" shape="circle" onClick={() => printTableExcelJs()}>
                <PrinterOutlined />
            </Button>
        </>
    )
}
export default SmetaPrint;