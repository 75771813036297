import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {

    const navigate = useNavigate();

    const handleHomeClick = () => {
        try {
            navigate('/layout');
        } catch (error) {
            console.log(error)
            navigate('/');
        }
    };

    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="not-found-title">404</h1>
                <p className="not-found-message">Сахифа топилмади</p>
                {/*<p className="not-found-description">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>*/}
                <button className="not-found-home-button" onClick={handleHomeClick}>Асосий ойнага кайтиш</button>
            </div>
        </div>
    );
};

export default NotFound;