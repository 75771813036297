import React from "react";
import CustomLineChart from "./chartTypes/CustomLineChart";
import CustomBarChart from "./chartTypes/CustomBarChart";
import CustomAreaChart from "./chartTypes/CustomAreaChart";
import CustomPieChart from "./chartTypes/CustomPieChart";
import CustomTable from "./chartTypes/CustomTable";
import CustomBubbleChart from "./chartTypes/CustomBubbleChart";
import CustomRadarChart from "./chartTypes/CustomRadarChart";
import CustomLargeAreaChart from "./chartTypes/CustomLargeAreaChart";
import CustomBarRaceChart from "./chartTypes/CustomBarRaceChart";
import CustomBarRaceChartSecond from "./chartTypes/CustomBarRaceChartSecond";


export const generateColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};



export class LineChartCustom {
    constructor(comparing_columns, comparing_by,data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomLineChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data}/>;
    }
}

export class BarChartCustom {
    constructor(comparing_columns, comparing_by,data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomBarChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data} />;
    }
}

export class AreaChartCustom {
    constructor(comparing_columns, comparing_by,data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomAreaChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data}/>;
    }
}

export class PieChartCustom {
    constructor(comparing_columns, comparing_by, data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomPieChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data} />;
    }
}
export class BubbleChartCustom {
    constructor(comparing_columns, comparing_by, data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomBubbleChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data} />;
    }
}
export class BarRaceCustom {
    constructor(comparing_columns, comparing_by, data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomBarRaceChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} apiData={this.data} />;
    }
}
export class LargeAreaChartCustom {
    constructor(comparing_columns, comparing_by, data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomLargeAreaChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data} />;
    }
}
export class RadarChartCustom {
    constructor(comparing_columns, comparing_by, data) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomRadarChart comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data} />;
    }
}

export class TableCustom {
    constructor(comparing_columns, comparing_by, data,comparableColumns) {
        this._comparing_columns = comparing_columns;
        this._comparing_by = comparing_by;
        this._data = data;
        this._comparableColumns = comparableColumns;
    }

    get comparableColumns() {
        return this._comparableColumns;
    }

    set comparableColumns(value) {
        this._comparableColumns = value;
    }

    get comparing_columns() {
        return this._comparing_columns;
    }

    set comparing_columns(value) {
        this._comparing_columns = value;
    }

    get comparing_by() {
        return this._comparing_by;
    }

    set comparing_by(value) {
        this._comparing_by = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    draw() {
        return <CustomTable comparing_columns={this.comparing_columns} comparing_by={this.comparing_by} data={this.data} comparableColumns={this.comparableColumns}/>;
    }
}
