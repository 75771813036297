import {all} from 'redux-saga/effects';
import {watchAccountType} from "./references/accountType/accountType";
import {watchBank} from "./references/bank/bank";
import {watchBankType} from "./references/bankType/bankType";
import {watchFilialType} from "./references/filial/filial";
import {watchChart} from "./chart/chart";
import {watchSmetaTab} from "./smeta/smetaTab/smetaTab";
import {watchAccountTab} from "./account/accountTab/accountTab";
import {watchAccountStateTab} from "./accountState/accountStateTab/accountStateTab";
import {watchSmetaExecuteTab} from "./accountState/smetaExecuteTab/smetaExecuteTab";
import {watchAccSaldoPeriodTab} from "./accountState/accSaldoPeriodTab/accSaldoPeriodTab";
import {watchBankAccExpensePeriod} from "./account/bankAccExpensePeriod/bankAccExpensePeriod";
import {watchDocumentControlsTab} from "./document/documentControlsTab/documentControlsTab";
import {watchAction} from "./extraSelectingData/action/action";
import {watchType} from "./extraSelectingData/type/type";
import {watchControl} from "./extraSelectingData/control/control";
import {watchStatsDescType} from "./references/statsDesc/statsDesc";
import {watchDidoxDocuments} from "./document/didox/didoxDocuments";
import {watchSmetaHistoryTab} from "./smeta/smetaHistory/smetaHistory";
import {watchChartSave} from "./chart/chart_save";
import {watchSavedQueries} from "./chart/saved_queries";
import {watchDidoxDocumentsByEmpId} from "./document/didox/didoxDocumentsByEmpId";
import {watchRefGoodMhikTabType} from "./references/refGoodMhikTab/refGoodMhikTab";

export function* rootSaga() {
    yield all([
        watchAccountType(),
        watchBank(),
        watchBankType(),
        watchFilialType(),
        watchChart(),
        watchSmetaTab(),
        watchAccountTab(),
        watchAccountStateTab(),
        watchSmetaExecuteTab(),
        watchAccSaldoPeriodTab(),
        watchBankAccExpensePeriod(),
        watchDocumentControlsTab(),
        watchAction(),
        watchType(),
        watchControl(),
        watchStatsDescType(),
        watchDidoxDocuments(),
        watchSmetaHistoryTab(),
        watchChartSave(),
        watchSavedQueries(),
        watchDidoxDocumentsByEmpId(),
        watchRefGoodMhikTabType()
    ])
}
