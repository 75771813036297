import {request} from "../../api/request";


export const makeQuery = async (data, url = '/api/public/query') => {
    try {
        const response = await request.post(url, (data));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const makeQueryNew = async (data, params) => {
    try {
        const response = await request.post(`/query/paging?${params}`, (data));
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const makeGet = async (params, url = '/api/public/query') => {
    try {
        const response = await request.get(url, {params});
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const makeExecute = async (data, params) => {
    try {
        const response = await request.post('/smeta/execute', (data), {params});
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const makeQueryPageable = async (data, params) => {
    try {
        const response = await request.post(`/api/public/query/paging?${params}`, (data));
        return response.data;
    } catch (error) {
        throw error;
    }
};