import React, {createContext, useState} from 'react';

export const RecentlyComeMenusContext = createContext({});
const RecentlyComeMenusProvider = ({children}) => {
    const [menus, setMenus] = useState(JSON.parse(localStorage.getItem('recentlyMenus')) || []);
    const handleChangeMenu = (newMenu) => {
        let old_menus = JSON.parse(localStorage.getItem('recentlyMenus')) || [];
        let exists = old_menus.some(menu => menu.menuId === newMenu.menuId);
        if (!exists) {
            if (old_menus.length > 4) {
                old_menus.pop();
            }
            old_menus.unshift(newMenu);
            localStorage.setItem('recentlyMenus',JSON.stringify(old_menus));
            setMenus(old_menus);
        }
    };
    return (
        <RecentlyComeMenusContext.Provider value={{menus, handleChangeMenu}}>
            {children}
        </RecentlyComeMenusContext.Provider>
    );
};

export default RecentlyComeMenusProvider;