import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {DOC_TYPE_REF} from "../../../types/extraSelectingData/type/type";
import {setTypeLoading, setTypeError, setTypeData} from "../../../slices/extraSelectingData/type/type";


export function* sendTypeSaga({typeData}) {
    try {
        yield put(setTypeLoading(true));
        const result = yield call(makeQuery, typeData);
        yield put(setTypeData(result));
        yield put(setTypeLoading(false));
        yield put(setTypeError({}));
    } catch (e) {
        yield put(setTypeError(e.response.data.error));
        yield put(setTypeLoading(false));
    }
}


export function* watchType() {
    yield takeEvery(DOC_TYPE_REF, sendTypeSaga);
}
