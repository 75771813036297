
import {sortCompareString} from '../../libs/formbm'
import {Badge, Space, Tag} from "antd";
import React from "react";
import {UserOutlined} from "@ant-design/icons";
export const dataAccTab = {
    "query": {
        "ID": "GET_ACCOUNTS_BY_EMP_ID_UI_V",
        "source": "GET_ACCOUNTS_BY_EMP_ID_UI_V",
        "fields": [
            {   "column": "id"/*, "format": "number", "type": "number"*/ },
            {   "column": "mfo"/*, "format": "text", "type": "text"*/ },
            {   "column": "code"/*, "format": "text", "type": "text"*/ },
            {   "column": "name"/*, "format": "text", "type": "text"*/ },
            {   "column": "inn"/*, "format": "text", "type": "text"*/ },
            {   "column": "acc_type"/*, "format": "number", "type": "number"*/ },
            {   "column": "pinfl"/*, "format": "text", "type": "text"*/ },
            {   "column": "filial"/*, "format": "text", "type": "text"*/ },
            {   "column": "filial_name"/*, "format": "text", "type": "text"*/ },
            {   "column": "state"/*, "format": "number", "type": "number"*/ },
            {   "column": "state_name"/*, "format": "text", "type": "text"*/ },
            {   "column": "isHaveSmeta"/*, "format": "text", "type": "text"*/ },
            {   "column": "created_by"/*, "format": "number", "type": "number"*/ },
            {   "column": "created_by_name"/*, "format": "number", "type": "number"*/ },
            {   "column": "created_date"/*, "format": "date", "type": "char"*/ },
            {   "column": "modified_by"/*, "format": "number", "type": "number"*/ },
            {   "column": "modified_by_name"/*, "format": "number", "type": "number" */},
            {   "column": "modified_date"/*, "format": "date", "type": "char"*/ }
        ]
    }
};
export const accTabDataChart = {
    "query": {
        "ID": "GET_ACCOUNTS_BY_EMP_ID_UI_V",
        "source": "GET_ACCOUNTS_BY_EMP_ID_UI_V",
        "fields": [
            {   "column": "id", "is_comparable": false, "is_main_comparing": true, "label": "ИД"},
            {   "column": "mfo", "is_comparable": false, "is_main_comparing": true, "label": "МФО" },
            {   "column": "code", "is_comparable": false, "is_main_comparing": true, "label": "Код" },
            {   "column": "inn", "is_comparable": false, "is_main_comparing": true, "label": "ИНН" },
            {   "column": "pinfl", "is_comparable": false, "is_main_comparing": true, "label": "ПИНФЛ" },
            {   "column": "filial", "is_comparable": false, "is_main_comparing": true, "label": "Филиал" },
            {   "column": "filial_name", "is_comparable": false, "is_main_comparing": true, "label": "Филиал номи" },
            {   "column": "state", "is_comparable": false, "is_main_comparing": true, "label": "Ҳолати" },
            {   "column": "state_name", "is_comparable": false, "is_main_comparing": true, "label": "Ҳолат номи" }
        ]
    }
};
export const bankAccSaldoPeriod = {
    "query": {
        "ID": "BANK_ACCOUNT_SALDO_BY_EMP_ID_UI_V",
        "source": "BANK_ACCOUNT_SALDO_BY_EMP_ID_UI_V",
        "context": "userFilialFilterContextStrategy",
        "fields": [
            {   "column": "id"},
            {   "column": "bank"},
            {   "column": "account"},
            {   "column": "acc_name"},
            {   "column": "is_exist_acc"},
            {   "column": "date_open", "format": "date", "type": "char" },
            {   "column": "date_work", "format": "date", "type": "char" },
            {   "column": "saldo_in", "format": "sum", "type": "char" },
            {   "column": "debet", "format": "sum", "type": "char" },
            {   "column": "credit", "format": "sum", "type": "char" },
            {   "column": "saldo_out", "format": "sum", "type": "char" },
            {   "column": "is_exist_acc_name"},
            {   "column": "created_by" },
            {   "column": "created_date", "format": "date", "type": "char" },
            {   "column": "modified_by"},
            {   "column": "modified_date", "format": "datetime", "type": "char" },
            {   "column": "date_saldo", "format": "date", "type": "char" },
            {   "column": "client_id"},
            {   "column": "filial" }
        ]
    }
};
export const bankAccSaldoPeriodDataChart = {
    "query": {
        "ID": "get_bank_account_saldo_by_emp_id_ui_v",
        "source": "get_bank_account_saldo_by_emp_id_ui_v",
        "context": "userFilialFilterContextStrategy",
        "fields": [
            {   "column": "id", "is_comparable": false, "is_main_comparing": true, "label": "ИД"},
            {   "column": "bank", "is_comparable": false, "is_main_comparing": true, "label": "Банк"},
            {   "column": "account", "is_comparable": false, "is_main_comparing": true, "label": "Ҳисоб рақам"},
            {   "column": "acc_name", "is_comparable": false, "is_main_comparing": true, "label": "Ҳисоб рақам номи"},
            {   "column": "saldo_in", "is_comparable": true, "is_main_comparing": false, "label": "Кун бошига қолдиқ"},
            {   "column": "debet", "is_comparable": true, "is_main_comparing": false, "label": "Дебет"},
            {   "column": "credit", "is_comparable": true, "is_main_comparing": false, "label": "Ҳаражат"},
            {   "column": "saldo_out", "is_comparable": true, "is_main_comparing": false, "label": "Жорий қолдиқ"},
            {   "column": "client_id", "is_comparable": false, "is_main_comparing": true, "label": "Клиент ИДиси"},
            {   "column": "filial" , "is_comparable": false, "is_main_comparing": true, "label": "Филиал"}
        ]
    }
};
export const bankAccExpensePeriodTab = {
    "query": {
        "ID": "get_smeta_execute_by_expense_month_2_by_emp_id",
        "source": "get_smeta_execute_by_expense_month_2_by_emp_id",
        "fields": [
            {   "column": "filial"},
            {   "column": "mfo"},
            {   "column": "account"},
            {   "column": "account_name"},
            {   "column": "expense"},
            {   "column": "expense_name"},
            {   "column": "smeta", "format": "sum", "type": "char" },
            {   "column": "paysum", "format": "sum", "type": "char" },
            {   "column": "sum_contract_sumpay", "format": "sum", "type": "char" },
            {   "column": "sum_cash_app_sumpay", "format": "sum", "type": "char" },
            {   "column": "sum_contract", "format": "sum", "type": "char" },
            {   "column": "sum_cash_app", "format": "sum", "type": "char" },
            {   "column": "sum_smeta_sumpay_saldo", "format": "sum", "type": "char" },
            {   "column": "sum_smeta_yur_saldo", "format": "sum", "type": "char" },
            {   "column": "sum_contract_sumpay_saldo", "format": "sum", "type": "char" },
            {   "column": "sum_cash_app_sumpay_saldo", "format": "sum", "type": "char" }
        ]
    }
};

export const bankAccExpensePeriodTabFilter = [
    { "column": "mfo", "label": "Банк", "operator": "=", "datatype":"text"},
    { "column": "account", "label": "Ҳисоб рақам", "operator": "_like_", "datatype":"text"},
    { "column": "filial", "label": "Филиал", "operator": "in", "datatype":"text","type":"M","typeRef":"FILIAL"},
    { "column": "expense", "label": "Харажат моддаси", "operator": "in", "datatype":"number", "type":"M","typeRef":"EXPENSE"},
];

export const bankAccExpensePeriodDataChart = {
    "query": {
        "ID": "get_smeta_execute_by_expense_month_2_by_emp_id",
        "source": "get_smeta_execute_by_expense_month_2_by_emp_id",
        "fields": [
            {   "column": "filial", "is_comparable": false, "is_main_comparing": true, "label": "Филиал"},
            {   "column": "mfo", "is_comparable": false, "is_main_comparing": true, "label": "МФО"},
            {   "column": "account", "is_comparable": false, "is_main_comparing": true, "label": "Ҳисоб рақам"},
            {   "column": "expense", "is_comparable": false, "is_main_comparing": true, "label": "Харажат моддаси"},
            {   "column": "expense_name", "is_comparable": false, "is_main_comparing": true, "label": "Харажат моддаси номи"},
            {   "column": "smeta", "is_comparable": true, "is_main_comparing": false, "label": "Смета"},
            {   "column": "paysum", "is_comparable": true, "is_main_comparing": false, "label": "Тўлов хужжатлари"},
            {   "column": "sum_contract_sumpay", "is_comparable": true, "is_main_comparing": false, "label": "Шартнома буйича туловлар"},
            {   "column": "sum_cash_app_sumpay", "is_comparable": true, "is_main_comparing": false, "label": "Суровнома буйича туловлар"},
            {   "column": "sum_contract", "is_comparable": true, "is_main_comparing": false, "label": "Шартнома"},
            {   "column": "sum_cash_app", "is_comparable": true, "is_main_comparing": false, "label": "Суровнома суммаси"},
            {   "column": "sum_smeta_sumpay_saldo", "is_comparable": true, "is_main_comparing": false, "label": "Смета - харажатлар"},
            {   "column": "sum_smeta_yur_saldo", "is_comparable": true, "is_main_comparing": false, "label": "Смета - юр. маж."},
            {   "column": "sum_contract_sumpay_saldo", "is_comparable": true, "is_main_comparing": false, "label": "Шартнома - харажат"},
            {   "column": "sum_cash_app_sumpay_saldo", "is_comparable": true, "is_main_comparing": false, "label": "Сўровнома - харажат"}
        ]
    }
};
export const bankAccExpensePeriodTabColumns = [
    {
        title: 'Филиал',
        dataIndex: 'filial',
        width: 100,
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.filial - b.filial,
    },
    {
        title: 'МФО',
        dataIndex: 'mfo',
        width: 100,
        sorter: (a, b) => a.mfo - b.mfo,

    },
    {
        title: 'Ҳисоб рақам',
        dataIndex: 'account',
        width: 100,
        sorter: (a, b) => sortCompareString(a.account, b.account)
    },
    {
        title: 'Ҳисоб рақам номи',
        dataIndex: 'account_name',
        width: 200,
        sorter: (a, b) => sortCompareString(a.account_name, b.account_name)
    },
    {
        title: 'Харажат моддаси',
        dataIndex: 'expense',
        width: 100,
        align: 'right',
        sorter: (a, b) => sortCompareString(a.expense, b.expense)
    },
    {
        title: 'Харажат моддаси номи',
        dataIndex: 'expense_name',
        width: 200,
        sorter: (a, b) => sortCompareString(a.expense_name, b.expense_name)
    },
    {
        title: 'Смета',
        dataIndex: 'smeta',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.smeta.replace(/,/g, ''));
            const sumB = parseFloat(b.smeta.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Шартнома',
        dataIndex: 'sum_contract',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_contract.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_contract.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Шартнома буйича туловлар',
        dataIndex: 'sum_contract_sumpay',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_contract_sumpay.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_contract_sumpay.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Суровнома суммаси',
        dataIndex: 'sum_cash_app',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_cash_app.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_cash_app.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Суровнома буйича туловлар',
        dataIndex: 'sum_cash_app_sumpay',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_cash_app_sumpay.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_cash_app_sumpay.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Тўлов хужжатлари',
        dataIndex: 'paysum',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.paysum.replace(/,/g, ''));
            const sumB = parseFloat(b.paysum.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Смета - харажатлар ',
        dataIndex: 'sum_smeta_sumpay_saldo',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_smeta_sumpay_saldo.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_smeta_sumpay_saldo.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Смета - юр. маж.',
        dataIndex: 'sum_smeta_yur_saldo',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_smeta_yur_saldo.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_smeta_yur_saldo.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Шартнома - харажат',
        dataIndex: 'sum_contract_sumpay_saldo',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_contract_sumpay_saldo.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_contract_sumpay_saldo.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Сўровнома - харажат',
        dataIndex: 'sum_cash_app_sumpay_saldo',
        width: 200,
        align: 'right',
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.sum_cash_app_sumpay_saldo.replace(/,/g, ''));
            const sumB = parseFloat(b.sum_cash_app_sumpay_saldo.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
];
export const bankAccSaldoPeriodFilters = [
    { "column": "bank", "label": "Банк", "operator": "=", "datatype":"text"},
    { "column": "account", "label": "Ҳисоб рақам", "operator": "_like_", "datatype":"text"},
    { "column": "date_saldo", "label": "Қолдиқ санаси", "operator": "range", "datatype":"date", "type": "R"},
    { "column": "saldo", "label": "Қолдиқ", "operator": "range", "datatype":"number", "type": "R"},
    { "column": "filial", "label": "Филиал", "operator": "in", "datatype":"text","type":"M","typeRef":"FILIAL"},
    { "column": "is_exist_acc", "label": "Бор/Йўқ", "operator": "in", "datatype":"number","type":"M","typeRef":"EXISTS_ACC"}

];
export const bankAccSaldoPeriodColumns = [
    {
        title: 'ИД',
        dataIndex: 'id',
        width: 100,
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Банк',
        dataIndex: 'bank',
        width: 200,
        sorter: (a, b) => a.bank - b.bank,

    },
    {
        title: 'Ҳисоб рақам',
        dataIndex: 'account',
        width: 200,
        sorter: (a, b) => sortCompareString(a.account, b.account)
    },
    {
        title: 'Филиал',
        dataIndex: 'filial',
        width: 200,
        sorter: (a, b) => sortCompareString(a.filial, b.filial)
    },
    {
        title: 'Ҳисоб рақам номи',
        dataIndex: 'acc_name',
        width: 200,
        sorter: (a, b) => sortCompareString(a.acc_name, b.acc_name),
        //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
    },
    {
        title: 'Кун бошига қолдиқ',
        dataIndex: 'saldo_in',
        align: 'right',
        width: 200,
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.saldo_in.replace(/,/g, ''));
            const sumB = parseFloat(b.saldo_in.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Тушум',
        dataIndex: 'debet',
        align: 'right',
        width: 200,
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.debet.replace(/,/g, ''));
            const sumB = parseFloat(b.debet.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Ҳаражат',
        dataIndex: 'credit',
        align: 'right',
        width: 200,
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.credit.replace(/,/g, ''));
            const sumB = parseFloat(b.credit.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Жорий қолдиқ',
        dataIndex: 'saldo_out',
        align: 'right',
        width: 200,
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.saldo_out.replace(/,/g, ''));
            const sumB = parseFloat(b.saldo_out.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Қолдиқ санаси',
        dataIndex: 'date_saldo',
        width: 200,
        sorter: (a, b) => {
            // Parse the string as a float for sorting
            const sumA = parseFloat(a.date_saldo.replace(/,/g, ''));
            const sumB = parseFloat(b.date_saldo.replace(/,/g, ''));
            return sumA - sumB;
        },
    },
    {
        title: 'Бор/Йўқ',
        dataIndex: 'is_exist_acc_name',
        width: 200,
        sorter: (a, b) => sortCompareString(a.is_exist_acc_name, b.is_exist_acc_name),
        render: (text, record) => {
            return (
                <Space size="middle">
                    {
                        record.is_exist_acc === 1 ? <Badge status="success" text={text} />
                            : record.is_exist_acc === 0 ? <Badge status="error" text={text} />
                            : <Badge status="default" text={text} />
                    }
                </Space>
            )
        },
    },
    {
        title: 'Бор/Йўқ',
        dataIndex: 'is_exist_acc',
        hidden: true,
    },
    {
        title: 'Яратди',
        dataIndex: 'created_by',
        hidden: true
    },
    {
        title: 'Яратилган сана',
        dataIndex: 'created_date',
        hidden: true
    },
    {
        title: 'Тахрирлади',
        dataIndex: 'modified_by',
        hidden: true
    },
    {
        title: 'Тахрирланган сана',
        dataIndex: 'modified_date',
        hidden: true
    },
    {
        title: 'Клиент ИД',
        dataIndex: 'client_id',
        hidden: true
    },
    {
        title: 'Очилган санаси',
        dataIndex: 'date_open',
        hidden: true
    },
    {
        title: 'Охирги операция санаси',
        dataIndex: 'date_work',
        hidden: true
    },
];
export const dataAccFilter = [
    { "column": "id", "label": "ИД", "operator": "=", "datatype":"number"},
    { "column": "mfo", "label": "МФО", "operator": "=", "datatype":"text"},
    { "column": "code", "label": "Хисоб ракам", "operator": "_like_", "datatype":"text"},
    { "column": "name", "label": "Номи", "operator": "_like_", "datatype":"text"},
    { "column": "filial", "label": "Филиал", "operator": "in", "datatype":"text", "type":"M","typeRef":"FILIAL"},
    { "column": "isHaveSmeta", "label": "Смета", "operator": "=", "datatype":"text","type":"S","typeRef":"EXIST_SMETA",},
    { "column": "state", "label": "Холат", "operator": "in", "datatype":"number","type":"M","typeRef":"DOCSTATE","paramRef":"ACCOUNT"}

];

export const columnsAcc = [
    {
        title: 'ИД',
        dataIndex: 'id',
        width: 100,
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Филиал',
        dataIndex: 'filial',
        width: 200,
        defaultSortOrder: 'descend',
        render: (text, record) => text + ' - ' + record.filial_name,
        sorter: (a, b) => a.filial - b.filial,
    },
    {
        title: 'Хисоб ракам',
        dataIndex: 'code',
        width: 200,
        sorter: (a, b) => a.code.length - b.code.length,
        //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
    },
    {
        title: 'Номи',
        dataIndex: 'name',
        width: 200,
        //sorter: (a, b) => a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' }),
        sorter: (a, b) => sortCompareString(a.name, b.name),
        //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
    },
    {
        title: 'МФО',
        dataIndex: 'mfo',
        width: 100,
        sorter: (a, b) => a.mfo - b.mfo,
        //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
    },
    {
        title: 'ИНН',
        dataIndex: 'inn',
        width: 100,
        sorter: (a, b) => a.inn - b.inn,
        //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
    },
    {
        title: 'Смета',
        dataIndex: 'isHaveSmeta',
        width: 200,
        sorter: (a, b) => sortCompareString(a.isHaveSmeta, b.isHaveSmeta),
        ellipsis: true,
        render: (text, record) => {
            return (
                    <Space size="middle">
                        {
                            record.isHaveSmeta === "Y" ?
                                <Tag color="green" style={{padding: '2px 8px', fontSize: '14px',}} >
                                    Бор
                                </Tag>
                            :
                                <Tag color="default" style={{padding: '4px 10px', fontSize: '14px',}} >
                                    Йўк
                                </Tag>
                        }
                    </Space>
                )
        }
    },
    {
        title: 'Холат',
        dataIndex: 'state_name',
        width: 200,
        sorter: (a, b) => a.state_name - b.state_name,
        render: (text, record) => {
            return (
                <Space size="middle">
                    {
                          record.state === 2 ? <Badge status="success" text={text} />
                        : record.state === 3 ? <Badge status="volcano" text={text} />
                        : <Badge status="default" text={text} />
                    }
                </Space>
            )
        },
        //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
    },
    /*{
        title: 'Яратди',
        dataIndex: 'created_by',
        width: 100,
        sorter: (a, b) => a.created_by - b.created_by,
    },
    {
        title: 'Яратилган сана',
        dataIndex: 'created_date',
        width: 100,
        sorter: (a, b) => a.created_date - b.created_date,
    },
    {
        title: 'Тахрирлади',
        dataIndex: 'modified_by',
        width: 100,
        sorter: (a, b) => a.modified_by - b.modified_by,
    },
    {
        title: 'Тахрирланган сана',
        dataIndex: 'modified_date',
        width: 100,
        sorter: (a, b) => a.modified_date - b.modified_date,
    },*/
];

export const dataAccoountTypeData = {
    "query": {
        "ID": "ACCOUNT_TYPE_REF",
        "source": "ACCOUNT_TYPE_REF",
        "fields": [
            {   "column": "id"/*, "format": "number", "type": "number"*/ },
            {   "column": "name"/*, "format": "text", "type": "text"*/ },

        ]
    }
}

