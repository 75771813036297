import React, {useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react';
import {generateColor} from "../index";

const CustomBarRaceChart = ({comparing_columns, comparing_by, apiData}) => {
    const [option, setOption] = useState({});

    useEffect(() => {
        const updateFrequency = 2000;
        const dimension = 0;
        let demoFlags = apiData.map((item) => item[comparing_by[0]]);
        let demoFlagsSecond = apiData.map((item) => item[comparing_by[1]]);
        /*demoFlags = [...new Set(demoFlags)];
        demoFlagsSecond = [...new Set(demoFlagsSecond)];*/
        demoFlags = demoFlags.sort((a, b) => a - b);

        let startYear = demoFlags[0];

        const option = {
            grid: {
                top: 10,
                bottom: 30,
                left: 150,
                right: 80
            },
            xAxis: {
                max: 'dataMax',
                axisLabel: {
                    formatter: (n) => Math.round(n) + ''
                }
            },
            dataset: {
                source: apiData.filter((d) => d[comparing_by[0]] === startYear)
            },
            yAxis: {
                type: 'category',
                inverse: true,
                max: 10,
                axisLabel: {
                    show: true,
                    fontSize: 14,
                    formatter: (value) => {
                        console.log('value',value);
                        return [...new Set(demoFlagsSecond)]
                    },
                    rich: {
                        flag: {
                            fontSize: 25,
                            padding: 5
                        }
                    }
                },
                animationDuration: 300,
                animationDurationUpdate: 300
            },
            series: [
                {
                    realtimeSort: true,
                    seriesLayoutBy: 'column',
                    type: 'bar',
                    itemStyle: {
                        color: generateColor()
                    },
                    encode: {
                        x: dimension,
                        y: 3
                    },
                    label: {
                        show: true,
                        precision: 1,
                        position: 'right',
                        valueAnimation: true,
                        fontFamily: 'monospace'
                    }
                }
            ],
            animationDuration: 0,
            animationDurationUpdate: updateFrequency,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear',
            graphic: {
                elements: [
                    {
                        type: 'text',
                        right: 160,
                        bottom: 60,
                        style: {
                            text: startYear,
                            font: 'bolder 80px monospace',
                            fill: 'rgba(100, 100, 100, 0.25)'
                        },
                        z: 100
                    }
                ]
            }
        };

        setOption(option);

        for (let i = 0; i < demoFlags.length - 1; ++i) {
            (function (i) {
                setTimeout(() => {
                    console.log('demoFlags[i + 1]', demoFlags[i + 1])
                    updateYear(demoFlags[i + 1]);
                }, (i - 0) * updateFrequency);
            })(i);
        }

        function updateYear(year) {
            let data = apiData.filter((d) => d[comparing_by[0]] === year);
            // data = data.map(item => item['all_sum']);
            console.log('updateYear',data);
            const newOption = {
                ...option,
                series: [{
                    ...option.series[0],
                    data: data,
                }],
                graphic: {
                    elements: [{
                        ...option.graphic.elements[0],
                        style: {
                            ...option.graphic.elements[0].style,
                            text: year
                        }
                    }]
                }
            };
            setOption(newOption);
        }

    }, []);

    return <ReactECharts option={option} style={{height: '500px', width: '100%'}}/>;
};

export default CustomBarRaceChart;
