import {takeEvery, put, call} from 'redux-saga/effects';
import {makeQuery} from "../../../apis";
import {setChartQuery} from "../../../slices/chart/chart";
import {SMETA_EXECUTE_TAB} from "../../../types/accountState/smetaExecuteTab/smetaExecuteTab";
import {
    setSmetaExecuteTabData,
    setSmetaExecuteTabLoading,
    setSmetaExecuteTabError
} from "../../../slices/accountState/smetaExecuteTab/smetaExecuteTab";

export function* sendSmetaExecuteTabSaga({dataSmetaExecuteTab, url}) {
    try {
        yield put(setSmetaExecuteTabLoading(true));

        const result = yield call(makeQuery, dataSmetaExecuteTab, url);
        yield put(setSmetaExecuteTabData(result));
        yield put(setChartQuery(dataSmetaExecuteTab));
        yield put(setSmetaExecuteTabLoading(false));
        yield put(setSmetaExecuteTabError({}));
    } catch (e) {
        yield put(setSmetaExecuteTabError(e.response.data.error));
        yield put(setSmetaExecuteTabLoading(false));
    }
}


export function* watchSmetaExecuteTab() {
    yield takeEvery(SMETA_EXECUTE_TAB, sendSmetaExecuteTabSaga);
}