import React, { useEffect, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import styled from 'styled-components';
import { generateColor } from '../index';
import './bubble.css';

const StyledSpinContainer = styled.div`
  margin: 20px 0;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const CustomPieChart = ({ comparing_columns, comparing_by, data }) => {
    const convertToNumber = (value) => {
        if (typeof value === 'string') {
            const numberValue = parseFloat(value.replace(/,/g, ''));
            return isNaN(numberValue) ? value : numberValue;
        }
        return value;
    };

    const convertedData = useMemo(() => {
        return data.map((item) => {
            const convertedItem = { ...item };
            comparing_columns.forEach((column) => {
                if (convertedItem.hasOwnProperty(column)) {
                    convertedItem[column] = convertToNumber(convertedItem[column]);
                }
            });
            return convertedItem;
        });
    }, [data, comparing_columns]);

    const newFormat = useMemo(() => {
        return convertedData.map((item) => {
            let main = '';
            for (let itemKey in item) {
                if (comparing_by.includes(itemKey)) {
                    if (main === '') {
                        main += itemKey + ': ' + item[itemKey];
                    } else {
                        main += ' , ' + itemKey + ': ' + item[itemKey];
                    }
                }
            }
            return { main, ...item };
        });
    }, [convertedData, comparing_by]);

    const [overAllData, setOverAllData] = useState([]);

    useEffect(() => {
        let sa = newFormat.map((item) => {
            return { name: item['main'], value: item[`${comparing_columns[0]}`] };
        });
        setOverAllData(sa);
    }, [newFormat, comparing_columns, data]);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const label = `${(percent * 100).toFixed(0)}%`;
        const lines = label.split(' - ');
        const lineHeight = 16;
        const textAnchor = x > cx ? 'start' : 'end';
        const labelX = x > cx ? x + 10 : x - 10;
        return (
            <text x={labelX} y={y} fill="white" textAnchor={textAnchor} dominantBaseline="middle">
                {lines.map((line, i) => (
                    <tspan key={`label-line-${i}`} x={labelX} dy={i === 0 ? 0 : lineHeight}>
                        {line}
                    </tspan>
                ))}
            </text>
        );
    };

    return (
        <PieChart width={window.innerWidth / 100 * 85} height={window.innerHeight / 100 * 85}>
            <Pie
                data={overAllData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={200}
                fill="#8884d8"
                dataKey="value"
            >
                {overAllData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={generateColor()} />
                ))}
            </Pie>
            <Tooltip />
        </PieChart>
    );
};

export default CustomPieChart;
