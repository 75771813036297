import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalRowCount: 0,
    totalItems: 0
};
const bankAccExpensePeriod = createSlice({
    name: 'bankAccExpensePeriod',
    initialState,
    reducers: {
        setBankAccExpensePeriodData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setBankAccExpensePeriodLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setBankAccExpensePeriodError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        setBankAccExpensePeriodRowCount: (state, action) => {

            state.totalRowCount = action.payload;
            return state;
        }
    }
});
export const {setBankAccExpensePeriodData,setBankAccExpensePeriodLoading,setBankAccExpensePeriodError,setBankAccExpensePeriodRowCount} = bankAccExpensePeriod.actions;
export default bankAccExpensePeriod.reducer;
