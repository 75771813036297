import React, {useContext, useEffect, useState} from 'react';
import {Button, Modal, Form, Input, message} from 'antd';
import {ChartQueryContext} from "../../../context/chartQueryContext/ChartQueryContextProvider";
import {useFilter} from "../../../context/FilterContext";
import {CHART_SAVE, CHART_SAVE_CLEAR} from "../../../store/types/chart/chart_save";
import {useDispatch, useSelector} from "react-redux";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const validateMessages = {
    required: '${label}ни киритиш мажбурий!'
};

const QuerySaveForm = ({form}) => {
    const data = useSelector((state) => state.chartSave.data);
    const loading = useSelector((state) => state.chartSave.loading);
    const error = useSelector((state) => state.chartSave.error);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {chartQuery, filterKey, menuId} = useContext(ChartQueryContext);
    const {filterValues} = useFilter();
    const filterData = filterValues[filterKey] || [];
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            dispatch({type: CHART_SAVE_CLEAR});
            setIsModalOpen(false);
            messageApi.open({
                type: 'success',
                content: 'Запрос успешно сохранен!',
            });
        }
    }, [data]);
    useEffect(() => {
        if (error && Object.keys(error).length > 0) {
            dispatch({type: CHART_SAVE_CLEAR});
            messageApi.open({
                type: 'error',
                content: 'Произошла ошибка! Пожалуйста, попробуйте еще раз!',
            });
        }
    }, [error]);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = (values) => {
        let formFields = form.getFieldsValue();
        const comparing_by = formFields.comparing_by_columns.map(column => column.comparing_by);
        const sorted_fields = chartQuery.query.fields.map((field) => {
            const comparingColumn = formFields.comparing_columns.find((col) => col.comparing_column === field.column);
            if (comparing_by.includes(field.column)) {
                if (field.format && field.type) {
                    return {
                        column: field.column,
                        format: field.format,
                        type: field.type,
                    }
                }
                return {
                    column: field.column,
                };
            } else if (comparingColumn) {
                return {
                    column: field.column,
                    function: {
                        type: comparingColumn.comparing_column_type
                    }
                };
            }

            return null;
        }).filter((field) => field !== null);
        const orderBy = formFields.comparing_by_columns.map((item) => {
            return {column: item.comparing_by, direction: item.comparing_by_type};
        });
        const dataChart = {
            query: {
                id: chartQuery.query.id || chartQuery.query.ID,
                source: chartQuery.query.source,
                fields: chartQuery.query.fields,
                filters: filterData,
                groupBy: comparing_by,
                orderBy
            }
        };
        const data = {
            name: values?.query?.name,
            menuId,
            queryJson: dataChart,
            diagramJson: formFields,
            details: values?.query?.details
        };
        dispatch({type: CHART_SAVE, data, url: '/diagram/save'});
    };
    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={showModal}>
                Сохранить запрос
            </Button>
            <Modal title="Детали запроса" open={isModalOpen} onCancel={handleCancel} footer={null}>
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                    validateMessages={validateMessages}
                >
                    <Form.Item
                        name={['query', 'name']}
                        label="Номи"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['query', 'details']} label="Детали" rules={[
                        {
                            required: true,
                        },
                    ]}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            ...layout.wrapperCol,
                            offset: 8,
                        }}
                    >
                        <Button type="primary" htmlType="submit" disabled={loading}>
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default QuerySaveForm;