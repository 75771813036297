import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import axios from '../api/axios';

const QUERY_URL = '/api/public/query';

const ExpExceljs = async (query, auth, queryColumns) => {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        Authorization: `Bearer ${auth.token}`,
        withCredentials: true,
    };

    const { data: dataTable } = await axios.post(QUERY_URL, query, {
        headers: headers,
        withCredentials: false,
    });

    dataTable.forEach((obj) => {
        for (const key in obj) {
            obj[key] = obj[key] === null ? '' : obj[key];
        }
    });

    let dataExcel = dataTable;

    const tableData = dataTable;
    const columnData = queryColumns;

    if (columnData && columnData.length !== 0) {
        const dataIndexToTitleMap = columnData.reduce((acc, column) => {
            acc[column.dataIndex] = column.title;
            return acc;
        }, {});

        // Replace the response object keys with titles
        const updatedResponse = tableData.map((item) => {
            const updatedItem = {};
            for (const key in item) {
                if (dataIndexToTitleMap[key]) {
                    updatedItem[dataIndexToTitleMap[key]] = item[key];
                } else {
                    updatedItem[key] = item[key];
                }
            }
            return updatedItem;
        });

        dataExcel = updatedResponse;
    }

    console.log(dataExcel,'dateExcel')

    /* Convert tableData to worksheet */
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1', {
        properties: { tabColor: { argb: 'FF00FF00' } },
        views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }],
    });

    const headerFill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EEE4A0' }, // Yellow color
    };

    worksheet.columns = Object.keys(dataExcel[0]).map((key) => ({
        header: key,
        key: key,
        width: 30,
    }));

    const headerRow = worksheet.getRow(1)
    headerRow.height = 20
    headerRow.alignment = { vertical: "middle" }
    headerRow.eachCell((cell) => {
        cell.style = {
            fill: headerFill,
            font: { bold: true },
        };
    });


    worksheet.addRows(dataExcel);

    // Add a different background color to even rows
    const evenRowFill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EEEDE5' }, // Light gray color
    };

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        if (rowNumber % 2 === 0) {
            row.eachCell((cell) => {
                cell.style = { fill: evenRowFill };
            });
        }
    });

    worksheet.columns.forEach(column => {
        //column.width = column.header.length + 15;
        column.width = 30;
        column.alignment = { horizontal: 'center' };
    });

    const rightAlignmentStyle = {
        alignment: { horizontal: 'right' },
        numFmt: '0,000,000,000,000.00',
    };

    for (let col = 1; col <= worksheet.columns.length; col++) {
        for (let row = 1; row <= worksheet.rowCount; row++) {
            const cell = worksheet.getCell(row, col);
            const cellValue = cell.value;

            const formatRegExp = /^(\d{1,3},)?(\d{3},)*\d{1,3}\.\d{2}$/;

            if (cellValue && formatRegExp.test(cellValue.toString())) {
                cell.style = {
                    ...cell.style,
                    ...rightAlignmentStyle,
                };
            }
        }
    }

    /* Save the file */
    const excelBlob = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'tableData.xlsx');
};

export default ExpExceljs