import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: {},
    totalItems: 0
};
const smetaExecuteTab = createSlice({
    name: 'smetaExecuteTab',
    initialState,
    reducers: {
        setSmetaExecuteTabData: (state, action) => {
            state.totalItems = action.payload.length;
            state.data = action.payload;
            return state;
        },
        setSmetaExecuteTabLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setSmetaExecuteTabError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});
export const {setSmetaExecuteTabData,setSmetaExecuteTabLoading,setSmetaExecuteTabError} = smetaExecuteTab.actions;
export default smetaExecuteTab.reducer;
